import React from 'react';
import bdi_logo_normal from 'assets/img/banner/bdi_logo_normal.png';
import DigitalFund from 'assets/img/banner/digital_fund.png';
import LogoDEPA from 'assets/img/banner/LogoDEPA.png';
import MDESLogo from 'assets/img/banner/MDESLogo.png';
import MOPHLogo from 'assets/img/banner/MOPHLogo.png';
import NTLogo from 'assets/img/banner/NTLogo.png';
import TMCLogo from 'assets/img/banner/TMCLogo.png';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const PREFIX = 'ConsoleFooter';

const classes = {
  root: `${PREFIX}-root`,
  footer: `${PREFIX}-footer`,
  emphasis: `${PREFIX}-emphasis`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    marginTop: 'auto',
  },

  [`&.${classes.footer}`]: {
    minHeight: '100px',
    padding: '20px 0',
    textAlign: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  [`& .${classes.emphasis}`]: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
}));

const StyledImg = styled('img')(({ theme }) => ({
  height: '50px',
  marginLeft: '0.5em',
  marginRight: '0.5em',
}));

function ConsoleFooter(): JSX.Element {
  return (
    <StyledGrid container className={classes.footer} justifyContent="center">
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: '16px',
          flexWrap: 'wrap',
        }}
      >
        <img src={MDESLogo} alt="MDES logo" height="58px" />
        <StyledImg src={MOPHLogo} alt="MOPH logo" />
        <StyledImg src={DigitalFund} alt="DE Fund logo" />
        <StyledImg src={bdi_logo_normal} alt="bdi logo" />
        <StyledImg src={LogoDEPA} alt="depa logo" />
        <StyledImg src={NTLogo} alt="NT logo" />
        <StyledImg src={TMCLogo} alt="TMC logo" />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          ติดต่อเราได้ที่{' '}
          <a href="mailto:healthlink@bdi.or.th" className={classes.emphasis}>
            healthlink@bdi.or.th{' '}
          </a>
          หรือโทร <span className={classes.emphasis}>02-026-2333 </span>
          ต่อ <span className={classes.emphasis}>3456</span>
        </Typography>
      </Grid>
    </StyledGrid>
  );
}

export default ConsoleFooter;
