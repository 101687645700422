import React from 'react';
import DoctorCard from 'components/Card/DoctorCard';
import Contact from 'components/DoctorManagementTabs/Contact';
import Evaluation from 'components/DoctorManagementTabs/Evaluation';
import Manual from 'components/DoctorManagementTabs/Manual';
import Notice from 'components/DoctorManagementTabs/Notice';
import Profile from 'components/DoctorManagementTabs/Profile';
import TabPanel from 'components/Tab/TabPanel';
import { FaBell, FaEnvelope, FaUserMd } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import { Card, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

const PREFIX = 'DoctorManagement';

const classes = {
  root: `${PREFIX}-root`,
  bodyContainer: `${PREFIX}-bodyContainer`,
  tabContainer: `${PREFIX}-tabContainer`,
  tabButton: `${PREFIX}-tabButton`,
  tabContentCard: `${PREFIX}-tabContentCard`,
  icon: `${PREFIX}-icon`,
  iconAndText: `${PREFIX}-iconAndText`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: '#F2F6F5',
    marginTop: '10px',
  },

  [`& .${classes.bodyContainer}`]: {
    margin: theme.spacing(2),
    marginTop: 0,
    paddingTop: theme.spacing(4),
  },

  [`& .${classes.tabContainer}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
  },

  [`& .${classes.tabButton}`]: {
    alignContent: 'center',
    textAlign: 'center',
    justifyContent: 'right',
  },

  [`& .${classes.tabContentCard}`]: {
    boxShadow: '1px 0px 1px 0px #7fb7a452 inset',
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.iconAndText}`]: {
    margin: 'auto',
    width: '150px',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'left',
  },
}));

interface ContainerProps {}

function DoctorManagement(): JSX.Element {
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState<string>('profile');

  const handleTabChange = (e: React.ChangeEvent<unknown>, newValue: string) => {
    setActiveTab(newValue);
  };

  React.useEffect(() => {
    if (location?.state?.targetTab) {
      setActiveTab(location.state.targetTab);
    }
  }, [location]);

  return (
    <StyledGrid container className={classes.root}>
      <Grid item xs={12}>
        <DoctorCard data={null} />
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0} className={classes.bodyContainer}>
          <Grid container>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <Container className={classes.tabContainer}>
                <Tabs
                  orientation="vertical"
                  variant="standard"
                  value={activeTab}
                  onChange={handleTabChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  TabIndicatorProps={{
                    style: {
                      width: '100%',
                      backgroundColor: '#73ffd71a',
                    },
                  }}
                >
                  <Tab
                    label={
                      <div className={classes.iconAndText}>
                        <FaUserMd className={classes.icon} />
                        <Typography variant="h6">โปรไฟล์</Typography>
                      </div>
                    }
                    value="profile"
                  />
                  <Tab
                    label={
                      <div className={classes.iconAndText}>
                        <FaBell className={classes.icon} />
                        <Typography variant="h6">แจ้งเตือน</Typography>
                      </div>
                    }
                    value="notice"
                  />
                  {/* temporary disabled */}

                  {/* <Tab
                    label={
                      <div className={classes.iconAndText}>
                        <FaEnvelope className={classes.icon} />
                        <Typography variant="h6">ติดต่อระบบฯ</Typography>
                      </div>
                    }
                    value="contact"
                  />
                  {/* <Tab
              label={
                <div className={classes.iconAndText}>
                  <FaAtlas className={classes.icon} />
                  <Typography variant="h6">ดาวน์โหลดคู่มือ</Typography>
                </div>
              }
              value={"manual"}
            />
            <Tab
              label={
                <div className={classes.iconAndText}>
                  <FaFileContract className={classes.icon} />
                  <Typography variant="h6">ทำแบบประเมิน</Typography>
                </div>
              }
              value={"evaluation"}
            /> */}
                </Tabs>
              </Container>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={10}>
              <TabPanel value={activeTab} index="profile" className={classes.tabContentCard}>
                <Profile />
              </TabPanel>
              <TabPanel value={activeTab} index="notice" className={classes.tabContentCard}>
                <Notice />
              </TabPanel>
              <TabPanel value={activeTab} index="contact" className={classes.tabContentCard}>
                <Contact />
              </TabPanel>
              <TabPanel value={activeTab} index="manual" className={classes.tabContentCard}>
                <Manual />
              </TabPanel>
              <TabPanel value={activeTab} index="evaluation" className={classes.tabContentCard}>
                <Evaluation />
              </TabPanel>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </StyledGrid>
  );
}

export default DoctorManagement;
