import type { RootState } from 'global_store/store';
import { ISearchResult } from 'interface/logging';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HospitalState {
  searchKeyword: string;
  searchResult: ISearchResult | null;
}

const initialState: HospitalState = {
  searchKeyword: '',
  searchResult: null,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchKeyword: (state, action: PayloadAction<string>) => {
      state.searchKeyword = action.payload;
    },
    setSearchResult: (state, action: PayloadAction<ISearchResult | null>) => {
      state.searchResult = action.payload;
    },
  },
});

export const { setSearchKeyword, setSearchResult } = searchSlice.actions;

export const selectSearchKeyword = (state: RootState) => state.search.searchKeyword;
export const selectSearchResult = (state: RootState) => state.search.searchResult;

export default searchSlice.reducer;
