// import { styled } from '@mui/material/styles';
import { MessageState } from 'global_store/features/message/messageSlice';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import Dialog from '@mui/material/Dialog';
// import Container from '@mui/material/Container';
// import GenericButton from 'components/Button/GenericButton';
// import { Box } from '@mui/material';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';

import BaseDialog from './Base/BaseDialog';

// const PREFIX = 'SuccessMessageDialog';

// const classes = {
//   headerButton: `${PREFIX}-headerButton`,
//   buttonArrow: `${PREFIX}-buttonArrow`,
//   leftContent: `${PREFIX}-leftContent`,
//   rightContent: `${PREFIX}-rightContent`,
//   menuItem: `${PREFIX}-menuItem`,
//   dialog: `${PREFIX}-dialog`,
//   dialogContent: `${PREFIX}-dialogContent`,
//   dialogButton: `${PREFIX}-dialogButton`,
//   centerAllContent: `${PREFIX}-centerAllContent`,
// };

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   [`& .${classes.headerButton}`]: {
//     border: '2px solid #CBCBCB',
//     width: '100%',
//     margin: 'auto',
//   },

//   [`& .${classes.buttonArrow}`]: {
//     color: '#59DCAF',
//   },

//   [`& .${classes.leftContent}`]: {
//     justifyContent: 'left',
//     alignContent: 'center',
//     display: 'flex',
//   },

//   [`& .${classes.rightContent}`]: {
//     justifyContent: 'right',
//     alignContent: 'center',
//     display: 'flex',
//   },

//   [`& .${classes.menuItem}`]: {
//     '&:hover': {
//       backgroundColor: theme.palette.secondary.main,
//     },
//   },

//   [`& .${classes.dialog}`]: {
//     borderRadius: '16px',
//     zIndex: 2,
//   },

//   [`& .${classes.dialogContent}`]: {
//     padding: theme.spacing(4),
//   },

//   [`& .${classes.dialogButton}`]: {
//     justifyContent: 'center',
//     maxWidth: '50%',
//     marginTop: '16px',
//   },

//   [`& .${classes.centerAllContent}`]: {
//     justifyContent: 'center',
//     alignContent: 'center',
//     textAlign: 'center',
//   },
// }));

interface SuccessMessageDialogProps {
  open: boolean;
  onClose: () => void;
  message: MessageState['successMessage'];
}

function SuccessMessageDialog({ open, onClose, message }: SuccessMessageDialogProps): JSX.Element {
  const theme = useTheme();

  const handleClose = async () => {
    onClose();
  };

  // const renderMessage = (message: string) => {
  //   const lines = message.split('\n');
  //   return lines.map((line, index) => {
  //     return (
  //       <Typography
  //         variant="h2"
  //         key={index}
  //         sx={{
  //           justifyContent: 'center',
  //           alignContent: 'center',
  //           textAlign: 'center',
  //         }}
  //       >
  //         {line}
  //       </Typography>
  //     );
  //   });
  // };

  return (
    <BaseDialog
      open={open}
      maxWidth="sm"
      handleClose={handleClose}
      // title="ดำเนินการสำเร็จ"
      dialogPaperSx={{
        zIndex: 2,
      }}
      withOkButton
      disableCloseOnClickBackdrop
    >
      <Stack justifyContent="center" gap="14px">
        <CheckCircleIcon sx={{ color: 'secondary.main', margin: 'auto', fontSize: '80px' }} />
        {(() => {
          if (!message) return null;

          switch (typeof message) {
            case 'string':
              return (
                <Typography
                  variant="h2"
                  sx={{
                    justifyContent: 'center',
                    alignContent: 'center',
                    textAlign: 'center',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {message}
                </Typography>
              );

            case 'object': {
              const { title = '', detail = '', subDetail = '' } = message;
              return (
                <>
                  <Typography
                    variant="h2"
                    sx={{
                      justifyContent: 'center',
                      alignContent: 'center',
                      textAlign: 'center',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {title}
                  </Typography>

                  <Stack justifyContent="center" alignItems="center">
                    <span
                      style={{
                        color: theme.palette.text.primary,
                        fontSize: '24px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {detail}
                    </span>
                    <span
                      style={{
                        color: theme.palette.text.primary,
                        fontSize: '16px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {subDetail}
                    </span>
                  </Stack>
                </>
              );
            }

            default:
              return null;
          }
        })()}
      </Stack>
    </BaseDialog>
  );

  // return (
  //   <StyledDialog open={open} maxWidth="sm" PaperProps={{ className: `${classes.dialog}` }}>
  //     <Container className={classes.dialogContent}>
  //       <Typography variant="h2" color="success.main" className={classes.centerAllContent}>
  //         ดำเนินการสำเร็จ
  //       </Typography>
  //       {renderMessage(message)}
  //       <Container className={classes.dialogButton}>
  //         <GenericButton onClick={handleClose} fullWidth size="small">
  //           ตกลง
  //         </GenericButton>
  //       </Container>
  //     </Container>
  //   </StyledDialog>
  // );
}

export default SuccessMessageDialog;
