import type { RootState } from 'global_store/store';
import {
  IAllergyIntoleranceEntry,
  ICompositionRecord,
  IImmunizationEntry,
} from 'interface/fhir.resource';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface overviewState {
  compositionList: ICompositionRecord[];
  allergyList: IAllergyIntoleranceEntry[];
  immunizationList: IImmunizationEntry[];
  totalAllergies: number;
  totalImmunizations: number;
}

const initialState: overviewState = {
  compositionList: [],
  allergyList: [],
  immunizationList: [],
  totalAllergies: 0,
  totalImmunizations: 0,
};

export const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    clearOverviewSlice: (state) => {
      // Reset state to initial state or any other desired state
      Object.assign(state, initialState);
    },
    setCompositionList: (state, action: PayloadAction<any | null>) => {
      state.compositionList = action.payload;
    },
    setAllergyList: (state, action: PayloadAction<any | null>) => {
      state.allergyList = action.payload;
    },
    setImmunizationList: (state, action: PayloadAction<any | null>) => {
      state.immunizationList = action.payload;
    },
    setTotalAllergies: (state, action: PayloadAction<any | null>) => {
      state.totalAllergies = action.payload;
    },
    setTotalImmunizations: (state, action: PayloadAction<any | null>) => {
      state.totalImmunizations = action.payload;
    },
  },
});

export const {
  clearOverviewSlice,
  setCompositionList,
  setAllergyList,
  setImmunizationList,
  setTotalAllergies,
  setTotalImmunizations,
} = overviewSlice.actions;

export const selectCompositionList = (state: RootState) => state.overview.compositionList;
export const selectAllergyList = (state: RootState) => state.overview.allergyList;
export const selectImmunizationList = (state: RootState) => state.overview.immunizationList;
export const selectTotalAllergies = (state: RootState) => state.overview.totalAllergies;
export const selectTotalImmunizations = (state: RootState) => state.overview.totalImmunizations;

export default overviewSlice.reducer;
