import React from 'react';
import GenericIcon from 'assets/img/generic_icon_03.png';
import GenericButton from 'components/Button/GenericButton';
import { selectUser } from 'global_store/features/authen/authenSlice';
import { useAppSelector } from 'global_store/hooks';
import { ITermElement } from 'interface';
import { Controller, useForm } from 'react-hook-form';

import { FormControlLabel, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import BaseDialog from './Base/BaseDialog';

// const PREFIX = 'ConfirmationDialog';

// const classes = {
//   headerButton: `${PREFIX}-headerButton`,
//   buttonArrow: `${PREFIX}-buttonArrow`,
//   leftContent: `${PREFIX}-leftContent`,
//   rightContent: `${PREFIX}-rightContent`,
//   menuItem: `${PREFIX}-menuItem`,
//   dialog: `${PREFIX}-dialog`,
//   dialogContent: `${PREFIX}-dialogContent`,
//   dialogButton: `${PREFIX}-dialogButton`,
//   centerAllContent: `${PREFIX}-centerAllContent`,
//   inputStyle: `${PREFIX}-inputStyle`,
// };

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   [`& .${classes.headerButton}`]: {
//     border: '2px solid #CBCBCB',
//     width: '100%',
//     margin: 'auto',
//   },

//   [`& .${classes.buttonArrow}`]: {
//     color: '#59DCAF',
//   },

//   [`& .${classes.leftContent}`]: {
//     justifyContent: 'left',
//     alignContent: 'center',
//     display: 'flex',
//   },

//   [`& .${classes.rightContent}`]: {
//     justifyContent: 'right',
//     alignContent: 'center',
//     display: 'flex',
//   },

//   [`& .${classes.menuItem}`]: {
//     '&:hover': {
//       backgroundColor: theme.palette.secondary.main,
//     },
//   },

//   [`& .${classes.dialog}`]: {
//     borderRadius: '16px',
//   },

//   [`& .${classes.dialogContent}`]: {
//     padding: theme.spacing(4),
//   },

//   [`& .${classes.dialogButton}`]: {
//     display: 'flex',
//     justifyContent: 'center',
//     maxWidth: '50%',
//     marginTop: '16px',
//   },

//   [`& .${classes.centerAllContent}`]: {
//     justifyContent: 'center',
//     alignContent: 'center',
//     textAlign: 'center',
//   },
// }));
interface TermsDialogProps {
  open: boolean;
  onClose?: () => void;
  termElements: ITermElement[];
  handleCancel: any;
  handleConfirm: any;
}

function TermsDialog({
  open,
  onClose,
  termElements,
  handleCancel,
  handleConfirm,
}: TermsDialogProps) {
  const user = useAppSelector(selectUser);
  const { control, watch, getValues, setValue } = useForm<any>({
    mode: 'onChange',
  });
  const handleClose = async () => {
    if (onClose) {
      onClose();
    }
  };

  const parseContentTemplate = (element: ITermElement) => {
    const parsedTokens = element.content
      .split('{{')
      .map((token) => {
        const subtokens = token.split('}}');
        if (subtokens.length > 1) {
          return [
            {
              type: 'variable',
              content: `${subtokens[0]}`,
            },
            {
              type: 'plaintext',
              content: subtokens[1],
            },
          ];
        }
        return [
          {
            type: 'plaintext',
            content: subtokens[0],
          },
        ];
      })
      .flat();
    const renderTokens = parsedTokens.map((token, index) => {
      if (token.type === 'plaintext') {
        return token.content;
      }
      const replaceDirective = element.replace_keys[token.content];
      if (replaceDirective.src === 'content') {
        if (replaceDirective.type === 'link') {
          return (
            <Link
              href={replaceDirective.url}
              key={index}
              target={replaceDirective.target ?? '_blank'}
              rel={replaceDirective.rel ?? 'noopener noreferrer'}
            >
              {replaceDirective.display}
            </Link>
          );
        }
      } else if (replaceDirective.src === 'variable') {
        if (replaceDirective.display === 'doctor_fullname') {
          return `${user?.firstname} ${user?.lastname}`;
        }
        if (replaceDirective.display === 'hosadmin_fullname') {
          return `${user?.firstname} ${user?.lastname}`;
        }
        return `Unknown Variable ${replaceDirective.display}`;
      }
      return JSON.stringify(replaceDirective);
    });
    return renderTokens;
  };

  const termsAndAgreementElement = (element: ITermElement, index: number) => {
    let currentValue;
    switch (element.type) {
      case 'header':
        return (
          <Typography variant="body2" textAlign="center" key={index}>
            {/* {element.content} */}
            {parseContentTemplate(element)}
          </Typography>
        );
      case 'paragraph':
        return (
          <Typography variant="body2" textAlign="center" key={index}>
            {/* {element.content} */}
            {parseContentTemplate(element)}
          </Typography>
        );
      case 'checkbox':
        currentValue = getValues(`${index}`);
        if (currentValue === undefined) {
          setValue(`${index}`, false);
        }
        return (
          <Controller
            key={index}
            name={`${index}`}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <FormControlLabel
                  sx={{ marginTop: '18px' }}
                  control={
                    <div style={{ padding: '0 18px' }}>
                      <Checkbox color="primary" checked={value} onChange={onChange} />
                    </div>
                  }
                  label={<div style={{ lineHeight: '26px' }}>{parseContentTemplate(element)}</div>}
                />
              );
            }}
          />
        );
      default:
        return null;
    }
  };

  const renderTermsAndAgreement = (terms: ITermElement[]) => {
    return (
      <div>
        {terms.map((element, index) => {
          return termsAndAgreementElement(element, index);
        })}
      </div>
    );
  };

  const allBoxChecked = () => {
    const values = Object.entries(watch());
    const keyCount = values.length;
    const checkedValueCount = values.filter((record: any) => record[1]).length;
    return keyCount === checkedValueCount;
  };

  return (
    <BaseDialog open={open} handleClose={handleClose} maxWidth="lg">
      <img
        src={GenericIcon}
        alt="Generic Icon"
        style={{ display: 'block', margin: '0 auto', paddingBottom: '14px' }}
      />
      {renderTermsAndAgreement(termElements)}
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '16px',
          flexDirection: 'column',
        }}
      >
        <Grid container justifyContent="center" mt={2}>
          <Grid item xs={4}>
            <Button onClick={handleCancel} fullWidth>
              ยกเลิก
            </Button>
          </Grid>
          <Grid item xs={4}>
            <GenericButton
              onClick={handleConfirm}
              fullWidth
              size="small"
              disabled={!allBoxChecked()}
            >
              ตกลง
            </GenericButton>
          </Grid>
        </Grid>
      </Container>
    </BaseDialog>
  );

  // return (
  //   <StyledDialog
  //     open={open}
  //     onClose={handleClose}
  //     maxWidth="sm"
  //     PaperProps={{ className: `${classes.dialog}` }}
  //   >
  //     <Container className={classes.dialogContent}>
  //       {renderTermsAndAgreement(termElements)}
  //       <Container className={classes.dialogButton}>
  //         <Button onClick={handleCancel} variant="outlined" color="info">
  //           ยกเลิก
  //         </Button>
  //         <Button
  //           onClick={handleConfirm}
  //           variant="contained"
  //           color="error"
  //           disabled={!allBoxChecked()}
  //         >
  //           ตกลง
  //         </Button>
  //       </Container>
  //     </Container>
  //   </StyledDialog>
  // );
}

export default TermsDialog;
