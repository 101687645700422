import { addYears, differenceInYears, format } from 'date-fns';
import { IConsentRequestLogSearchResult, ISearchHistory, IUsageLog } from 'interface/logging';
import { IFormattedError } from 'interface/service';
import { getDefaultRequestHeaders } from 'utils/authen';

import api from './api';

export const getPatientViewHistory = async ({
  page,
  pageSize,
}: {
  page: number;
  pageSize: number;
}): Promise<{ success: true; data: ISearchHistory; error: null } | IFormattedError> => {
  try {
    const res = await api.get('/api/logging/queried-patient-list/by-doctor', {
      headers: await getDefaultRequestHeaders(true),
      params: {
        page,
        size: pageSize,
      },
    });
    const currentDate = new Date();
    return {
      success: true,
      data: {
        total: res.data.list_data.length,
        currentPage: res.data.page,
        pageSize: res.data.size,
        records: res.data.list_data.map((record: any) => {
          const patientDetail = record.detail;
          const parsedDoB = new Date(patientDetail.patient_dob);
          const age = differenceInYears(
            currentDate,
            parsedDoB.getFullYear() > 2100 ? addYears(parsedDoB, -543) : parsedDoB,
          );
          return {
            cid: patientDetail.patient_cid,
            gender: patientDetail.patient_gender,
            title: patientDetail.patient_title,
            firstname: patientDetail.patient_firstname,
            lastname: patientDetail.patient_lastname,
            age,
            birthdate: patientDetail.patient_dob,
            is_active: patientDetail.patient_is_active,
          };
        }),
      },
      error: null,
    };
  } catch (err: any) {
    console.log(err.response);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const getDoctorUsageLogByMdpAdmin = async ({
  page,
  pageSize,
  doctorId,
  date,
}: {
  page: number;
  pageSize: number;
  doctorId?: string;
  date?: Date | null;
}): Promise<
  | {
      success: true;
      data: IUsageLog;
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.get('/api/logging/doctor-usage/by-mdp-admin', {
      headers: await getDefaultRequestHeaders(true),
      params: {
        page,
        size: pageSize,
        doctor_username: doctorId,
        since_date: date ? format(date, 'yyyy-MM-dd HH:mm:ss') : undefined,
      },
    });
    return {
      success: true,
      data: {
        total: res.data.total,
        currentPage: res.data.page,
        pageSize: res.data.size,
        records: res.data.list_data,
      },
      error: null,
    };
  } catch (err: any) {
    console.log(err.response);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const getConsentRequestLogByMdpAdmin = async ({
  page,
  pageSize,
  doctorUsername,
  cid,
}: {
  page: number;
  pageSize: number;
  doctorUsername?: string;
  cid?: string;
}): Promise<
  | {
      success: true;
      data: IConsentRequestLogSearchResult;
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.post(
      '/api/logging/allowed-consent-out-by-case/doctor-active-mdp/get',
      {
        doctor_username: doctorUsername || undefined,
        cid: cid || undefined,
      },
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          size: pageSize,
          filter_by_action: 'all',
        },
      },
    );
    return {
      success: true,
      data: {
        total: res.data.total,
        currentPage: res.data.page,
        pageSize: res.data.size,
        records: res.data.list_data,
      },
      error: null,
    };
  } catch (err: any) {
    console.log(err.response);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const getFHIRAccessLogByMdpAdmin = async ({
  page,
  pageSize,
  doctorUsername,
  doctorMdp9id,
  sinceDate,
  cid,
  entryType = 'composition',
}: {
  page: number;
  pageSize: number;
  doctorUsername?: string;
  doctorMdp9id?: string;
  sinceDate?: Date | null;
  cid?: string;
  entryType?: string;
}): Promise<
  | {
      success: true;
      data: IUsageLog;
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.post(
      '/api/logging/out/fhir-data-access/by-mdp-admin',
      {
        cid: cid || undefined,
      },
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          size: pageSize,
          doctor_username: doctorUsername || undefined,
          doctor_mdp9_id: doctorMdp9id || undefined,
          since_date: sinceDate ? format(sinceDate, 'yyyy-MM-dd HH:mm:ss') : undefined,
          entry_type: entryType,
        },
      },
    );
    return {
      success: true,
      data: {
        total: res.data.total,
        currentPage: res.data.page,
        pageSize: res.data.size,
        records: res.data.list_data,
      },
      error: null,
    };
  } catch (err: any) {
    console.log(err.response);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const getConsentRequestLogBySystemAdmin = async ({
  page,
  pageSize,
  mdp9id,
  doctorUsername,
  cid,
}: {
  page: number;
  pageSize: number;
  mdp9id: string;
  doctorUsername?: string;
  cid?: string;
}): Promise<
  | {
      success: true;
      data: IConsentRequestLogSearchResult;
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.post(
      `/api/logging/allowed-consent-out-by-case/doctor-active-mdp/get/by-admin/${mdp9id}`,
      {
        doctor_username: doctorUsername || undefined,
        cid: cid || undefined,
      },
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          size: pageSize,
        },
      },
    );
    return {
      success: true,
      data: {
        total: res.data.total,
        currentPage: res.data.page,
        pageSize: res.data.size,
        records: res.data.list_data,
      },
      error: null,
    };
  } catch (err: any) {
    console.log(err.response);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const getFHIRAccessLogBySystemAdmin = async ({
  page,
  pageSize,
  doctorUsername,
  patientMdp9idList,
  doctorMdp9id,
  sinceDate,
  cid,
}: {
  page: number;
  pageSize: number;
  doctorUsername?: string;
  patientMdp9idList?: string[];
  doctorMdp9id?: string;
  sinceDate?: Date | null;
  cid?: string;
}): Promise<
  | {
      success: true;
      data: IUsageLog;
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.post(
      `/api/logging/out/fhir-data-access/by-admin${
        patientMdp9idList && patientMdp9idList.length > 0
          ? `?${patientMdp9idList.map((mdp9id) => `patient_mdp9_id=${mdp9id}`).join('&')}`
          : ''
      }`,
      {
        cid: cid || undefined,
      },
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          size: pageSize,
          doctor_username: doctorUsername || undefined,
          doctor_mdp9_id: doctorMdp9id || undefined,
          since_date: sinceDate ? format(sinceDate, 'yyyy-MM-dd HH:mm:ss') : undefined,
        },
      },
    );
    return {
      success: true,
      data: {
        total: res.data.total,
        currentPage: res.data.page,
        pageSize: res.data.size,
        records: res.data.list_data,
      },
      error: null,
    };
  } catch (err: any) {
    console.log(err.response);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};
