import React from 'react';
import bdi_logo_normal from 'assets/img/banner/bdi_logo_normal.png';
import DigitalFund from 'assets/img/banner/digital_fund.png';
import LogoDEPA from 'assets/img/banner/LogoDEPA.png';
import MDESLogo from 'assets/img/banner/MDESLogo.png';
import MOPHLogo from 'assets/img/banner/MOPHLogo.png';
import NTLogo from 'assets/img/banner/NTLogo.png';
import TMCLogo from 'assets/img/banner/TMCLogo.png';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const PREFIX = 'PageFooter';

const classes = {
  root: `${PREFIX}-root`,
  footer: `${PREFIX}-footer`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    marginTop: 'auto',
  },

  [`&.${classes.footer}`]: {
    background: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    minHeight: '100px',
    padding: '20px 0',
    textAlign: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

const StyledImg = styled('img')(({ theme }) => ({
  height: '50px',
  marginLeft: '0.5em',
  marginRight: '0.5em',
}));

function PageFooter(): JSX.Element {
  return (
    <StyledGrid container className={classes.footer} justifyContent="center">
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={MDESLogo} alt="MDES logo" height="58px" />
        <StyledImg src={MOPHLogo} alt="MOPH logo" />
        <StyledImg src={DigitalFund} alt="DE Fund logo" />
        <StyledImg src={bdi_logo_normal} alt="bdi logo" />
        <StyledImg src={LogoDEPA} alt="depa logo" />
        <StyledImg src={NTLogo} alt="NT logo" />
        <StyledImg src={TMCLogo} alt="TMC logo" />
      </Grid>
    </StyledGrid>
  );
}

export default PageFooter;
