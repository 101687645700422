import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      overview: 'Overview',
      allergy: 'Allergy',
      vaccine: 'Vaccine',
      diagnosis: 'Diagnosis',
      procedure: 'Procedure',
      medication: 'Medication',
      lab: 'Lab',
      laboratory: 'Laboratory',
      visit: 'Visit',
      vitalSigns: 'Vital Signs',
    },
  },
  th: {
    translation: {
      overview: 'ภาพรวม',
      allergy: 'อาการแพ้',
      vaccine: 'รับวัคซีน',
      diagnosis: 'โรควินิจฉัย',
      procedure: 'ประวัติหัตถการ',
      medication: 'การจ่ายยา',
      lab: 'รายงานผลตรวจ',
      laboratory: 'ผลตรวจทางห้องปฏิบัติการ',
      visit: 'พบแพทย์',
      vitalSigns: 'ผลตรวจสัญญาณชีพ',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
