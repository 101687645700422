import GenericButton from 'components/Button/GenericButton';
import { DoctorEvaluationFormURI, MDPAdminEvaluationFormURI } from 'config';
import { selectRoles } from 'global_store/features/authen/authenSlice';
import { useAppSelector } from 'global_store/hooks';
import { useNavigate } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, List, ListItem, Stack } from '@mui/material';
// import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
// import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import BaseDialog from './Base/BaseDialog';

// const PREFIX = 'EvaluationDialog';

// const classes = {
//   headerButton: `${PREFIX}-headerButton`,
//   buttonArrow: `${PREFIX}-buttonArrow`,
//   leftContent: `${PREFIX}-leftContent`,
//   rightContent: `${PREFIX}-rightContent`,
//   menuItem: `${PREFIX}-menuItem`,
//   dialog: `${PREFIX}-dialog`,
//   dialogContent: `${PREFIX}-dialogContent`,
//   dialogButton: `${PREFIX}-dialogButton`,
//   centerAllContent: `${PREFIX}-centerAllContent`,
// };

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   [`& .${classes.headerButton}`]: {
//     border: '2px solid #CBCBCB',
//     width: '100%',
//     margin: 'auto',
//   },

//   [`& .${classes.buttonArrow}`]: {
//     color: '#59DCAF',
//   },

//   [`& .${classes.leftContent}`]: {
//     justifyContent: 'left',
//     alignContent: 'center',
//     display: 'flex',
//   },

//   [`& .${classes.rightContent}`]: {
//     justifyContent: 'right',
//     alignContent: 'center',
//     display: 'flex',
//   },

//   [`& .${classes.menuItem}`]: {
//     '&:hover': {
//       backgroundColor: theme.palette.secondary.main,
//     },
//   },

//   [`& .${classes.dialog}`]: {
//     borderRadius: '16px',
//   },

//   [`& .${classes.dialogContent}`]: {
//     padding: theme.spacing(4),
//   },

//   [`& .${classes.dialogButton}`]: {
//     justifyContent: 'center',
//     maxWidth: '50%',
//     marginTop: '16px',
//   },

//   [`& .${classes.centerAllContent}`]: {
//     justifyContent: 'center',
//     alignContent: 'center',
//     textAlign: 'center',
//   },
// }));

interface EvaluationDialogProps {
  open: boolean;
  onClose: () => void;
}

const sxCenterAllContent = {
  justifyContent: 'center',
  alignContent: 'center',
  textAlign: 'center',
};

function EvaluationDialog({ open, onClose }: EvaluationDialogProps): JSX.Element {
  const navigate = useNavigate();
  const roles = useAppSelector(selectRoles);

  const handleEvaluate = () => {
    let url = DoctorEvaluationFormURI;

    if (roles && roles.includes('MDP_ADMIN')) {
      url = MDPAdminEvaluationFormURI;
    }

    window.location.href = url;
    onClose();
  };

  const handleClose = () => {
    onClose();
    navigate('/page/login');
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      maxWidth="xs"
      fullWidth
      disableCloseOnClickBackdrop
    >
      <Stack sx={{ margin: 'auto', width: 'fit-content' }}>
        <CheckCircleIcon sx={{ fontSize: '68px' }} fontSize="large" color="secondary" />
      </Stack>
      <Typography
        variant="body2"
        sx={{
          ...sxCenterAllContent,
          whiteSpace: 'pre-wrap',
          marginY: '20px',
          lineHeight: '2.25rem',
          fontSize: '24px',
        }}
      >
        ขอบคุณที่ใช้ระบบฯ
      </Typography>
      <Typography
        variant="body2"
        sx={{
          ...sxCenterAllContent,
          whiteSpace: 'pre-wrap',
          maxWidth: '320px',
          margin: 'auto',
          textAlign: 'center',
          fontWeight: '400',
        }}
      >
        ท่านสามารถทำแบบประเมินแสดงความคิดเห็น และข้อเสนอแนะ เพื่อนำไปพัฒนาระบบให้ดียิ่งขึ้น
      </Typography>
      <Container
        maxWidth={false}
        sx={{
          justifyContent: 'center',
          maxWidth: '80%',
        }}
      >
        <List>
          <ListItem sx={sxCenterAllContent}>
            <GenericButton onClick={handleEvaluate} fullWidth size="small">
              ทำแบบประเมิน
            </GenericButton>
          </ListItem>
          <ListItem sx={sxCenterAllContent}>
            <Button variant="text" onClick={handleClose} fullWidth sx={{ color: 'GrayText' }}>
              ไม่ ขอบคุณ
            </Button>
          </ListItem>
        </List>
      </Container>
    </BaseDialog>
  );

  // return (
  //   <StyledDialog open={open} maxWidth="sm" PaperProps={{ className: `${classes.dialog}` }}>
  //     <Container className={classes.dialogContent}>
  //       <Typography variant="h2" color="success.main" className={classes.centerAllContent}>
  //         ขอบคุณที่ใช้ระบบฯ
  //       </Typography>
  //       <Typography variant="body2" className={classes.centerAllContent}>
  //         ความคิดเห็นของคุณมีค่ากับเรากรุณาทำแบบประเมินเพื่อช่วยให้เราพัฒนาระบบฯให้ดียิ่งขึ้น
  //       </Typography>
  //       <Container className={classes.dialogButton}>
  //         <List>
  //           <ListItem className={classes.centerAllContent}>
  //             <GenericButton onClick={handleEvaluate} fullWidth size="small">
  //               ทำแบบประเมิน
  //             </GenericButton>
  //           </ListItem>
  //           <ListItem className={classes.centerAllContent}>
  //             <Button variant="text" onClick={handleClose} sx={{ color: 'GrayText' }}>
  //               ไม่ ขอบคุณ
  //             </Button>
  //           </ListItem>
  //         </List>
  //       </Container>
  //     </Container>
  //   </StyledDialog>
  // );
}

export default EvaluationDialog;
