import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

interface SimplePageLayoutProps {
  children?: React.ReactNode;
}
function SimplePageLayout({ children }: SimplePageLayoutProps): JSX.Element {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Outlet />
    </Suspense>
  );
}

export default SimplePageLayout;
