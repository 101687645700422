import React from 'react';
import { INoticeRecord } from 'interface/doctor.management';
import { FaChevronDown } from 'react-icons/fa';
import { displayDate } from 'utils';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PREFIX = 'NoticeAccordian';

const classes = {
  root: `${PREFIX}-root`,
  greyText: `${PREFIX}-greyText`,
  accordionSummary: `${PREFIX}-accordionSummary`,
  accordionDetails: `${PREFIX}-accordionDetails`,
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },

  [`& .${classes.greyText}`]: {
    color: '#9B9B9B',
  },

  [`& .${classes.accordionSummary}`]: {
    color: 'rgba(0, 0, 0, 0.54)',
    backgroundColor: '#F1FFFB',
    boxShadow: '0px -1px 1px 0px #7fb7a452 inset',
  },

  [`& .${classes.accordionDetails}`]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

interface NoticeAccordianProps {
  record: INoticeRecord;
}

function NoticeAccordian({ record }: NoticeAccordianProps): JSX.Element {
  return (
    <StyledAccordion className={classes.root} defaultExpanded>
      <AccordionSummary expandIcon={<FaChevronDown />} className={classes.accordionSummary}>
        <Grid container spacing={2}>
          <Grid item xs={3} md={2}>
            <Typography variant="body2">
              {displayDate(new Date(record.date), 'dd/MM/yyyy')}
            </Typography>
          </Grid>
          <Grid item xs={7} md={8}>
            <Typography variant="body2">{record.title}</Typography>
          </Grid>
          {/* <Grid item xs={2}>
            <Typography variant="body2">{record.status}</Typography>
          </Grid> */}
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Typography
          variant="body1"
          component="div"
          dangerouslySetInnerHTML={{ __html: record.data }}
        />
      </AccordionDetails>
    </StyledAccordion>
  );
}

export default NoticeAccordian;
