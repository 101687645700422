import React from 'react';
import { ISelectOption } from 'interface';
import { Control, Controller } from 'react-hook-form';

import { SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export interface GenericInputProps {
  variant:
    | 'generic'
    // | "datepicker"
    | 'select'
    | 'textarea'
    // | "checkbox"
    // | "multi-select"
    | 'label'
    | 'inputWithAdornment'
    | 'switch'
    | 'readOnly';
  // | "radio";
  muiVariant?: 'filled' | 'outlined' | 'standard';
  control: Control<any>;
  name: string;
  label?: string;
  optionList?: any[];
  type?: string;
  defaultValue: any;
  rules: any;
  disabled?: boolean;
  required?: boolean;
  noStyle?: boolean;
  placeholder?: string;
  additionalOnChangeFn?: any;
  inputProps?: any;
  className?: string;
  endAdornment?: any;
  sxProps?: SxProps;
}

function GenericInput({
  variant,
  muiVariant,
  control,
  name,
  label,
  optionList,
  type,
  defaultValue,
  rules,
  disabled,
  required,
  noStyle = false,
  placeholder,
  additionalOnChangeFn,
  inputProps,
  className,
  endAdornment,
  sxProps,
}: GenericInputProps): JSX.Element {
  const optionListValidated: ISelectOption[] = optionList || [];

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        switch (variant) {
          case 'select':
            return (
              <>
                <Autocomplete
                  id={name}
                  options={optionListValidated}
                  style={noStyle ? { minWidth: '160px' } : { width: '100%' }}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                    if (additionalOnChangeFn) {
                      additionalOnChangeFn();
                    }
                  }}
                  isOptionEqualToValue={(option, value) => value && option.value === value.value}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField placeholder={label} {...params} />}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={`${option.label}-${option.value}`}>
                        {option.label}
                      </li>
                    );
                  }}
                  disabled={disabled}
                />
                {error ? (
                  <Typography variant="subtitle2" color="error">
                    {error.message}
                  </Typography>
                ) : (
                  ''
                )}
              </>
            );
          case 'textarea':
            return (
              <TextField
                id={name}
                label={label}
                type={type}
                variant={muiVariant || 'outlined'}
                margin="dense"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                placeholder={placeholder || label}
                disabled={disabled}
                inputProps={inputProps}
                className={className}
                sx={sxProps}
                multiline
                rows={3}
              />
            );
          case 'inputWithAdornment':
            return (
              <TextField
                id={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                variant="standard"
                InputProps={{
                  // disableUnderline: true,
                  endAdornment,
                }}
                className={className}
                sx={sxProps}
              />
            );
          case 'switch':
            return (
              <Switch
                id={name}
                checked={value}
                onChange={onChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            );
          default:
            return (
              <TextField
                id={name}
                label={label}
                type={type}
                variant={muiVariant || 'outlined'}
                margin="normal"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                placeholder={placeholder || label}
                disabled={disabled}
                inputProps={inputProps}
                className={className}
                sx={sxProps}
                InputLabelProps={{ shrink: true }}
              />
            );
        }
      }}
      rules={rules}
    />
  );
}

export default GenericInput;
