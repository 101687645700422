import { isAfter } from 'date-fns';
import type { RootState } from 'global_store/store';
import { store } from 'global_store/store';
import {
  IFHIRDataInfo,
  IFHIRDataInfoHospitals,
  IFhirDataInfoResourceCount,
  IHospital,
  IHospitalConsent,
  ISplittedHospitalConsentListIds,
} from 'interface';
import { getHospitalListWithData } from 'services/patient';
import { filterTrueConsented } from 'utils/consentOrVerifyEmail';
import { splitHospitalConsentList } from 'utils/hospitalConsentList';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HospitalState {
  hospitalConsentList: IHospitalConsent[] | null;
  hospitalWithDataList: IFHIRDataInfoHospitals | null;
  selectedHospital: IHospital | null;
  fhirDataInfoResourceCount: IFhirDataInfoResourceCount | null;
}

const initialState: HospitalState = {
  hospitalConsentList: null,
  hospitalWithDataList: null,
  selectedHospital: null,
  fhirDataInfoResourceCount: null,
};

export const hospitalSlice = createSlice({
  name: 'hospital',
  initialState,
  reducers: {
    setHospitalConsentList: (state, action: PayloadAction<IHospitalConsent[] | null>) => {
      state.hospitalConsentList = action.payload;
    },
    setHospitalWithDataList: (state, action: PayloadAction<IFHIRDataInfoHospitals | null>) => {
      state.hospitalWithDataList = action.payload;
    },
    setSelectedHospital: (state, action: PayloadAction<IHospital | null>) => {
      state.selectedHospital = action.payload;
    },
    setFhirDataInfoResourceCount: (
      state,
      action: PayloadAction<HospitalState['fhirDataInfoResourceCount']>,
    ) => {
      state.fhirDataInfoResourceCount = action.payload;
    },
  },
});

export const {
  setHospitalConsentList,
  setHospitalWithDataList,
  setSelectedHospital,
  setFhirDataInfoResourceCount,
} = hospitalSlice.actions;

export const selectHospitalConsentList = (state: RootState) => state.hospital.hospitalConsentList;

export const selectHospitalLookup = createSelector(
  selectHospitalConsentList,
  (mdps): { [mdp9Id: string]: IHospitalConsent } =>
    mdps?.reduce((acc, mdp) => ({ ...acc, [mdp.mdp9_id]: mdp }), {}) ?? {},
);
export const selectHospitalConsentListIds = createSelector(selectHospitalConsentList, (mdps) =>
  mdps?.map((mdp) => mdp.mdp9_id),
);
export const selectSplittedHospitalConsentList = createSelector(
  selectHospitalConsentList,
  (consentList) => splitHospitalConsentList(consentList),
);
export const selectSplittedHospitalConsentListIds = createSelector(
  selectSplittedHospitalConsentList,
  (splittedHospitalConsentList): ISplittedHospitalConsentListIds =>
    Object.entries(splittedHospitalConsentList).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value?.map((mdp) => mdp.mdp9_id) ?? [],
      }),
      {} as ISplittedHospitalConsentListIds,
    ),
);

export const selectHospitalWithDataList = (state: RootState) => state.hospital.hospitalWithDataList;
export const selectSelectedHospital = (state: RootState) => state.hospital.selectedHospital;
export const selectSelectedHospitalIsExchanger = createSelector(selectSelectedHospital, (mdp) =>
  Boolean(mdp?.exchanger),
);

export const selectConsentedOrByCaseHospitals = createSelector(selectHospitalConsentList, (mdps) =>
  mdps?.filter((mdp) => mdp.consent_status === 'CONSENTED' || mdp.consent_type === 'case'),
);
export const selectConsentedOrByCaseHospitalIds = createSelector(
  selectConsentedOrByCaseHospitals,
  (consentedOrByCase) => {
    return consentedOrByCase?.map((mdp) => mdp.mdp9_id) ?? [];
  },
);
export const selectSplittedConsentedOrByCaseList = createSelector(
  selectConsentedOrByCaseHospitals,
  (mdps) => splitHospitalConsentList(mdps),
);
export const selectSplittedConsentedOrByCaseIds = createSelector(
  selectSplittedConsentedOrByCaseList,
  (splittedHospitalConsentList) =>
    Object.entries(splittedHospitalConsentList).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value?.map((mdp) => mdp.mdp9_id) ?? [],
      }),
      {} as ISplittedHospitalConsentListIds,
    ),
);
export const selectTrueConsentedHospitals = createSelector(selectHospitalConsentList, (mdps) =>
  filterTrueConsented(mdps),
);
export const selectTrueConsentedHospitalIds = createSelector(
  selectTrueConsentedHospitals,
  (mdps) => mdps?.map((mdp) => mdp.mdp9_id) ?? [],
);

export const selectConsentedOrByCaseWithDataHospitals = createSelector(
  selectConsentedOrByCaseHospitals,
  selectHospitalWithDataList,
  (consented, withData) => {
    // console.debug('🐷 | Overview ', { consented, withData });
    // const consentedIds =  consented?.map( mdp => mdp.mdp9_id) ?? []
    const withDataIds = withData?.map((mdp) => mdp.mdp9_id) ?? [];
    return consented?.filter((mdp) => withDataIds.includes(mdp.mdp9_id)) ?? null;
  },
);

export const selectTrueConsentedWithDataHospitals = createSelector(
  selectTrueConsentedHospitals,
  selectHospitalWithDataList,
  (consented, withData) => {
    // console.debug('🐷 | Overview ', { consented, withData });
    // const consentedIds =  consented?.map( mdp => mdp.mdp9_id) ?? []
    const withDataIds = withData?.map((mdp) => mdp.mdp9_id) ?? [];
    return consented?.filter((mdp) => withDataIds.includes(mdp.mdp9_id)) ?? null;
  },
);
export const selectConsentedOrByCaseWithDataHospitalIds = createSelector(
  selectConsentedOrByCaseWithDataHospitals,
  (consentedWithData) => {
    return consentedWithData?.map((mdp) => mdp.mdp9_id) ?? [];
  },
);
export const selectTrueConsentedWithDataHospitalIds = createSelector(
  selectTrueConsentedWithDataHospitals,
  (consentedWithData) => {
    return consentedWithData?.map((mdp) => mdp.mdp9_id) ?? [];
  },
);

export const selectByCaseNotConsentedHospitals = createSelector(
  selectHospitalConsentList,
  (mdps) => {
    return (
      mdps?.filter((mdp) => mdp.consent_type === 'case' && mdp.consent_status !== 'CONSENTED') ?? []
    );
  },
);
export const selectByCaseNotConsentedHospitalIds = createSelector(
  selectByCaseNotConsentedHospitals,
  (mdps) => {
    return mdps.map((mdp) => mdp.mdp9_id);
  },
);

export const selectUnconsentedByCaseMdpsWithData = createSelector(
  selectByCaseNotConsentedHospitalIds,
  selectHospitalWithDataList,
  (unconsentedIds, withData): IFHIRDataInfoHospitals => {
    const res = withData?.filter((mdp) => unconsentedIds.includes(mdp.mdp9_id)) ?? [];
    return res;
  },
);

// vvv used in develop but adjusted the calculation to use selectHospitalLookup as base
export const selectMdpIdToNameMap = createSelector(
  selectHospitalLookup,
  (lookup): { [mdp9Id: string]: string } =>
    !lookup
      ? {}
      : Object.entries(lookup).reduce(
          (acc, [mdp9_id, mdp]) => ({ ...acc, [mdp9_id]: mdp.mdp_name }),
          {},
        ),
);

export const fetchHospitalWithData = async (
  cid: string,
  mdp9Ids: string[],
  filterOnlyMdp9IdsToRequest = false,
) => {
  console.debug(`🏃 running fetchHospitalWithData`);
  const {
    success,
    data: rawData,
    error,
  } = await getHospitalListWithData(cid || '', mdp9Ids, filterOnlyMdp9IdsToRequest);
  const data = (rawData as IFHIRDataInfo)?.list_data;
  console.debug(`🏃 running fetchHospitalWithData | result |`, data);
  if (data) {
    store.dispatch(setHospitalWithDataList(data));
  }
};
export default hospitalSlice.reducer;
