import { addYears, compareDesc, format } from 'date-fns';
import { ICategoryParam, IHospital } from 'interface';
import { ICompositionRecord } from 'interface/fhir.resource';

export const displayDate = (date: Date | string, template: string): string => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  if (parsedDate.getFullYear() > 2100) {
    return format(addYears(parsedDate, -543), template);
  }
  return format(parsedDate, template);
};

export const getHospitalName = (mdp9id: string, hospitalList: IHospital[]): string => {
  for (const hospital of hospitalList) {
    if (mdp9id === hospital.mdp9_id) {
      return hospital.mdp_name;
    }
  }
  return '';
};

export const sortCompositionRecords = (
  compositionList: ICompositionRecord[],
): ICompositionRecord[] => {
  return compositionList.sort((a, b) =>
    compareDesc(
      new Date(
        a.composition.resource.event && a.composition.resource.event.length > 0
          ? a.composition.resource.event[0].period.start
          : a.composition.resource.date,
      ),
      new Date(
        b.composition.resource.event && b.composition.resource.event.length > 0
          ? b.composition.resource.event[0].period.start
          : b.composition.resource.date,
      ),
    ),
  );
};

export const formatCid = (inputString: string) => {
  const re = /(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/;
  if (/^\d{13}$/.test(inputString)) {
    return inputString.replace(re, '$1-$2-$3-$4-$5');
  }
  return inputString;
};

export const parseDateString = (dateString: string) => {
  const rawTime = new Date(dateString);
  const utcLikeTime = new Date(
    rawTime.getUTCFullYear(),
    rawTime.getUTCMonth(),
    rawTime.getUTCDate(),
    rawTime.getUTCHours(),
    rawTime.getUTCMinutes(),
  );
  return `${format(utcLikeTime, 'yyyy-MM-dd, HH:mm')}`;
};

export const isNullOrUndefined = (val: any) => {
  return val === null || val === undefined;
};

export const areArraysEqual = (a: (string | number)[], b: (string | number)[]) => {
  return a.length === b.length && a.every((id) => b.includes(id));
};

export function stripHTML(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export function timeout(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function chunkData(data, maxChunkSize) {
  const dataArray = [];
  let index = 0;

  while (index < data.length || index < data.length) {
    const chunkedData = data.slice(index, index + maxChunkSize);
    dataArray.push(chunkedData);
    index += maxChunkSize;
  }

  return dataArray;
}
