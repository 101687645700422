// import Dialog from '@mui/material/Dialog';
// import Container from '@mui/material/Container';
// import GenericButton from 'components/Button/GenericButton';
import { requestLogout } from 'services/authen';

import ErrorRoundedIcon from '@mui/icons-material/Error';
// import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BaseDialog from './Base/BaseDialog';
import DialogFormButtons from './Base/DialogFormButtons';

// const PREFIX = 'ErrorLogoutDialog';

// const classes = {
//   headerButton: `${PREFIX}-headerButton`,
//   buttonArrow: `${PREFIX}-buttonArrow`,
//   leftContent: `${PREFIX}-leftContent`,
//   rightContent: `${PREFIX}-rightContent`,
//   menuItem: `${PREFIX}-menuItem`,
//   dialog: `${PREFIX}-dialog`,
//   dialogContent: `${PREFIX}-dialogContent`,
//   dialogButton: `${PREFIX}-dialogButton`,
//   centerAllContent: `${PREFIX}-centerAllContent`,
// };

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   [`& .${classes.headerButton}`]: {
//     border: '2px solid #CBCBCB',
//     width: '100%',
//     margin: 'auto',
//   },

//   [`& .${classes.buttonArrow}`]: {
//     color: '#59DCAF',
//   },

//   [`& .${classes.leftContent}`]: {
//     justifyContent: 'left',
//     alignContent: 'center',
//     display: 'flex',
//   },

//   [`& .${classes.rightContent}`]: {
//     justifyContent: 'right',
//     alignContent: 'center',
//     display: 'flex',
//   },

//   [`& .${classes.menuItem}`]: {
//     '&:hover': {
//       backgroundColor: theme.palette.secondary.main,
//     },
//   },

//   [`& .${classes.dialog}`]: {
//     borderRadius: '16px',
//   },

//   [`& .${classes.dialogContent}`]: {
//     padding: theme.spacing(4),
//   },

//   [`& .${classes.dialogButton}`]: {
//     justifyContent: 'center',
//     maxWidth: '50%',
//     marginTop: '16px',
//   },

//   [`& .${classes.centerAllContent}`]: {
//     justifyContent: 'center',
//     alignContent: 'center',
//     textAlign: 'center',
//   },
// }));

interface ConfirmLogoutDialogProps {
  open: boolean;
  closeDialog: () => void;
  onConfirm: () => void;
}

function ConfirmLogoutDialog({
  open,
  closeDialog,
  onConfirm,
}: ConfirmLogoutDialogProps): JSX.Element {
  const handleClose = async () => {
    // await requestLogout();
    closeDialog();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onConfirm();
  };

  return (
    <BaseDialog open={open} handleClose={handleClose} maxWidth="sm" disableCloseOnClickBackdrop>
      <form onSubmit={handleSubmit}>
        <Stack sx={{ margin: 'auto', width: 'fit-content' }}>
          <ErrorRoundedIcon sx={{ fontSize: '68px' }} fontSize="large" color="warning" />
        </Stack>
        <Stack paddingX="30px">
          <Typography
            variant="overline"
            color="text.primary"
            fontWeight="600"
            sx={{
              textAlign: 'center',
              lineHeight: '2.25rem',
              marginTop: '20px',
            }}
            component="div"
          >
            คุณยืนยัน
          </Typography>
          <Typography
            variant="overline"
            color="text.primary"
            fontWeight="600"
            sx={{
              textAlign: 'center',
              lineHeight: '2.25rem',
            }}
            component="div"
          >
            ที่จะออกจากระบบฯ หรือไม่
          </Typography>
        </Stack>
        <DialogFormButtons handleClose={handleClose} />
      </form>
    </BaseDialog>
  );
}

export default ConfirmLogoutDialog;
