import React from 'react';
import { IPageProperties, IRoute } from 'interface';
import { IAccessTokenPayload } from 'interface/authen';
import { Route } from 'react-router-dom';

export const hasRole = (pageRoles: string[], userRoles: string[] | null): boolean => {
  if (!userRoles) {
    return false;
  }
  for (const role of userRoles) {
    if (pageRoles.includes(role)) {
      return true;
    }
  }
  return false;
};

export const renderRoutes = (
  routes: IRoute[],
  userRoles: string[] | null,
): any | null | (typeof Route)[] => {
  const mappedRoutes = routes.map((prop, key) => {
    if (prop.roles && !hasRole(prop.roles, userRoles)) return null;
    if (prop.views?.length) {
      const viewsRoutes = prop.views.map((v) => ({
        roles: prop.roles,
        ...v /* if view has roles, override */,
      }));
      return (
        <Route path={prop.path} element={prop.component} key={prop.path}>
          {renderRoutes(viewsRoutes, userRoles)}
          {prop?.indexRoute && <Route index element={prop.indexRoute} />}
          {prop?.defaultRoute && <Route path="*" element={prop.defaultRoute} />}
        </Route>
      );
    }
    return <Route path={prop.path} element={prop.component} key={prop.path} />;
  });
  return mappedRoutes.filter((route) => route);
};

export const canRolesAccessCurrentPage = (
  routes: IRoute[],
  locationPathname: string,
  roles: IAccessTokenPayload['roles'],
): boolean => {
  if (!roles) return false;
  const pageRoles = getPageRoles(routes, locationPathname);
  if (!pageRoles) return true;
  return roles.some((role) => pageRoles.includes(role));
};

// NOTE: use roles from previous level if not exist
export const getPageRoles = (
  routes: IRoute[],
  locationPathname: string,
  pathLevel = 0,
  prevRoles = undefined,
): IRoute['roles'] | null | undefined => {
  for (const route of routes) {
    const pathArr = locationPathname.replace(/^\//, '').split('/');
    if (!pathArr[pathLevel]) return null;
    if (pathArr[pathLevel] === route.path) {
      if (route.views?.length) {
        return getPageRoles(route.views, locationPathname, pathLevel + 1, route.roles);
      }
      return route.roles ?? prevRoles ?? null;
    }
  }
  return null;
};

// NOTE: DOES NOT use value from previous level if not exist
export const getPageValue = (
  routes: IRoute[],
  locationPathname: string,
  atKey: keyof IRoute,
  pathLevel = 0,
): any | null | undefined => {
  for (const route of routes) {
    const pathArr = locationPathname.replace(/^\//, '').split('/');
    if (!pathArr[pathLevel]) return null;
    if (pathArr[pathLevel] === route.path) {
      if (route.views?.length) {
        return getPageValue(route.views, locationPathname, atKey, pathLevel + 1);
      }
      return route[atKey];
    }
  }
  return null;
};

export const getPageProperties = (
  routes: IRoute[],
  locationPathname: string,
  pathLevel = 0,
): IPageProperties | null | undefined => {
  return getPageValue(routes, locationPathname, 'properties', pathLevel);
};

export const getPageSkipInitializeInApp = (routes: IRoute[], locationPathname: string): boolean => {
  return getPageValue(routes, locationPathname, 'skipInitializeInApp') ?? false;
};
