export const sampleChronicDisease = {
  resourceType: 'Bundle',
  id: '10e3bea6-ca1a-47ac-848b-0fa4977e361f',
  type: 'searchset',
  total: 3,
  entry: [
    {
      fullUrl:
        'https://h000000000.healthlink.go.th/fhir/Condition/000000000-1101402036661-IPD-1-condition-1',
      resource: {
        resourceType: 'Condition',
        id: '000000000-1101402036661-IPD-1-condition-1',
        category: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/condition-category',
                code: 'problem-list-item',
                display: 'Problem List Item',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://thcc.or.th/ICD-10TM/',
              code: 'N185',
              display: 'Diabetes',
            },
          ],
        },
        // ...
        // other data elements
        // ...
      },
      search: {
        mode: 'match',
      },
    },
    {
      fullUrl:
        'https://h000000000.healthlink.go.th/fhir/Condition/000000000-3471200360401-OPD-1-condition-1',
      resource: {
        resourceType: 'Condition',
        id: '000000000-3471200360401-OPD-1-condition-1',
        category: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/condition-category',
                code: 'problem-list-item',
                display: 'Problem List Item',
              },
            ],
          },
        ],
        code: {
          text: 'Hypertensio',
        },
        // ...
        // other data elements
        // ...
      },
      search: {
        mode: 'match',
      },
    },
    {
      fullUrl:
        'https://h000000000.healthlink.go.th/fhir/Condition/000000000-0000000000003-VN1234567890123-Con-icd10val',
      resource: {
        resourceType: 'Condition',
        id: '000000000-0000000000003-VN1234567890123-Con-icd10val',
        category: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/condition-category',
                code: 'problem-list-item',
                display: 'Problem List Item',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://thcc.or.th/ICD-10TM/',
              code: 'icd10val',
              display: 'icd10name',
            },
          ],
          text: 'Heart Disease',
        },
        // ...
        // other data elements
        // ...
      },
      search: {
        mode: 'match',
      },
    },
    {
      fullUrl:
        'https://h000000000.healthlink.go.th/fhir/Condition/000000000-0000000000003-VN1234567890123-Con-icd10val',
      resource: {
        resourceType: 'Condition',
        id: '000000000-0000000000003-VN1234567890123-Con-icd10val',
        category: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/condition-category',
                code: 'problem-list-item',
                display: 'Problem List Item',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              system: 'http://thcc.or.th/ICD-10TM/',
              code: 'icd10val',
              display: 'icd10name',
            },
          ],
          text: 'Heart Disease',
        },
        // ...
        // other data elements
        // ...
      },
      search: {
        mode: 'match',
      },
    },
  ],
};
