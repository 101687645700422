import {
  IHospitalConsent,
  ISplittedHospitalConsentList,
  ISplittedHospitalConsentListIds,
} from 'interface';

export const defaultSplit: ISplittedHospitalConsentList = {
  required: null,
  notRequired: null,
  fromClientRequired: null,
};

export const defaultSplitIds: ISplittedHospitalConsentListIds = {
  required: [],
  notRequired: [],
  fromClientRequired: [],
};
export const splitHospitalConsentList = (
  hospitalConsentList: IHospitalConsent[] | null,
): ISplittedHospitalConsentList => {
  if (!hospitalConsentList) return defaultSplit;
  const ret = hospitalConsentList.reduce((acc, mdp) => {
    // TODO: refactor -> extract to utils function
    let key = 'notRequired';
    if (mdp.fhir_data_from_server_required) {
      key = 'required';
    } else if (mdp.fhir_data_from_client_required) {
      key = 'fromClientRequired';
    }
    return { ...acc, [key]: (acc[key] ?? []).concat([mdp]) };
  }, defaultSplit);
  console.debug('🚀 | ret | ret:', ret);
  // // mock for now (borrow 1 for fromClientRequired from required)
  // const borrow = ret.required.slice(ret.required.length - 1, ret.required.length);
  // console.debug('🚀 |  borrow', borrow);
  // ret.required = ret.required.slice(0, ret.required.length - 1);
  // ret.fromClientRequired = borrow;
  return ret;
};

export const combineSplittedHospitalConsentList = (
  splittedHospitalConsentList: ISplittedHospitalConsentList | null,
): IHospitalConsent[] | null => {
  if (!splittedHospitalConsentList) return null;
  return splittedHospitalConsentList.notRequired
    .concat(splittedHospitalConsentList.required)
    .concat(splittedHospitalConsentList.fromClientRequired);
};
