import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingBackdropProps {
  open: boolean;
  handleClose?: () => void;
}

function LoadingBackdrop({ open, handleClose }: LoadingBackdropProps): JSX.Element {
  return (
    <Backdrop open={open} onClick={handleClose} sx={{ zIndex: 10000 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingBackdrop;
