import type { RootState } from 'global_store/store';
import { IConditionEntry } from 'interface/fhir.resource';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface conditionState {
  conditionExpansion: boolean[];
  conditionList: IConditionEntry[];
  conditionOffset: number;
  expansion: any;
}

const initialState: conditionState = {
  conditionExpansion: [],
  conditionList: [],
  conditionOffset: 0,
  expansion: [],
};

export const conditionSlice = createSlice({
  name: 'condition',
  initialState,
  reducers: {
    clearConditionSlice: (state) => {
      // Reset state to initial state or any other desired state
      Object.assign(state, initialState);
    },
    setConditionExpansion: (state, action: PayloadAction<any | null>) => {
      state.conditionExpansion = action.payload;
    },
    setConditionList: (state, action: PayloadAction<any | null>) => {
      state.conditionList = action.payload;
    },
    setConditionOffset: (state, action: PayloadAction<any | null>) => {
      state.conditionOffset = action.payload;
    },
    setExpansion: (state, action: PayloadAction<any | null>) => {
      state.expansion = action.payload;
    },
  },
});

export const {
  clearConditionSlice,
  setConditionExpansion,
  setConditionList,
  setConditionOffset,
  setExpansion,
} = conditionSlice.actions;

export const selectConditionExpansion = (state: RootState) => state.condition.conditionExpansion;
export const selectConditionList = (state: RootState) => state.condition.conditionList;
export const selectConditionOffset = (state: RootState) => state.condition.conditionOffset;

export default conditionSlice.reducer;
