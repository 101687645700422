import React, { useEffect, useMemo } from 'react';
import ErrorLogoutDialog from 'components/Dialog/ErrorLogoutDialog';
import SessionTimer from 'components/Timer/SessionTimer';
import thLocale from 'date-fns/locale/th';
import {
  selectRoles,
  selectUser,
  setShowSessionExpiredModal,
} from 'global_store/features/authen/authenSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import useWebSocket from 'global_store/hooks/useWebSocket';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import routes from 'routes';
import { getFingerPrint } from 'services/authen';
import { hieTheme } from 'theme';
import { initializeApp } from 'utils/app';
import { getCurrentFingerPrint } from 'utils/fingerprint';
import { InitializeGA4, setUserType } from 'utils/ga4';
import { getPageSkipInitializeInApp, renderRoutes } from 'utils/layout';

import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function App() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const userRoles = useAppSelector(selectRoles);
  const [showLogoutDialog, setShowLogoutDialog] = React.useState<boolean>(false);

  // hooks
  useWebSocket();

  useEffect(() => {
    if (!user) {
      setUserType('guest');
      return;
    }

    if (userRoles.includes('DOCTOR')) {
      setUserType('doctor');
      return;
    }

    // TODO: @sirawit consider isExchanger value from the selectedHospitalIsExchanger (activeMdp) instead because a hospital admin could be an admin of an exchanger and a non-exchanger in the future
    // NOTE: as of release 1.10 HA cannot change their active mdp
    const isExchanger = !!user?.list_mdp?.find((e) => e.exchanger == true);
    const userType = isExchanger ? 'exchanger' : 'non-exchanger';

    setUserType(userType);
  }, [user]);

  const skipInitializeInApp = useMemo(
    () => getPageSkipInitializeInApp(routes, window.location.pathname),
    [window.location.pathname],
  );

  const callInitializeApp = React.useCallback(async () => {
    console.debug('🚀 | callInitializeApp | from App | skipInitializeInApp', skipInitializeInApp);
    if (skipInitializeInApp) return; // skip in app because is handled in its own page
    initializeApp(dispatch, undefined, (accessTokenRes) => {
      console.debug('🚀 | initializeApp | accessTokenRes:', accessTokenRes);
      if (/^\/console|admin/.test(window.location.pathname)) {
        console.debug('🚀 callInitializeApp | setShowSessionExpiredModal true');
        dispatch(setShowSessionExpiredModal(true));
      } else if (accessTokenRes?.data?.detail === 'Your account not link to any medical place.') {
        // Show error message modal with logout button
        setShowLogoutDialog(true);
        // console.log(accessTokenRes.error.detail);
      }
    });
  }, [dispatch]);

  React.useEffect(() => {
    callInitializeApp();
  }, [callInitializeApp]);

  const handleCloseErrorLogoutDialog = () => {
    setShowLogoutDialog(false);
  };

  // -- start call api finger print
  React.useEffect(() => {
    const fingerString = getCurrentFingerPrint();
    getFingerPrint(fingerString);
  }, []);
  // -- end call api finger print

  // remove console. TODO: do with the babelRc remove console plugin instead
  useEffect(() => {
    if (
      process.env.REACT_APP_HOST_ENV === 'production'
      // || process.env.REACT_APP_HOST_ENV === 'staging'
    ) {
      console.debug = () => {};
      console.log = () => {};
      console.warn = () => {};
      console.error = () => {};
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={hieTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={thLocale}>
          <CssBaseline />
          <BrowserRouter>
            <ErrorLogoutDialog open={showLogoutDialog} closeDialog={handleCloseErrorLogoutDialog} />
            <SessionTimer />
            <Routes>
              {renderRoutes(routes, userRoles)}
              <Route index element={<Navigate to="/page/login" replace />} />
            </Routes>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
