import { RootState, store } from 'global_store/store';
import _ from 'lodash';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
/* clean when change patient */

interface ForceUpdateState {
  pendingMdp9Ids: string[];
}

const initialState: ForceUpdateState = {
  pendingMdp9Ids: [],
};

export const forceUpdateSlice = createSlice({
  name: 'forceUpdate',
  initialState,
  reducers: {
    clearForceUpdateSlice: (state) => {
      console.debug('🚀 | clearForceUpdateSlice');
      // Reset state to initial state or any other desired state
      Object.assign(state, initialState);
    },
    clearPendingMdp9Ids: (state) => {
      console.debug('🚀 | clearPendingMdp9Ids');
      state.pendingMdp9Ids = initialState.pendingMdp9Ids;
    },
    addToPendingMdp9Ids: (state, action: PayloadAction<ForceUpdateState['pendingMdp9Ids']>) => {
      const uniqued = _.uniq(state.pendingMdp9Ids.concat(action.payload));
      console.debug('🚀 | addToPendingMdp9Ids | uniqued:', uniqued);
      state.pendingMdp9Ids = uniqued;
    },
  },
});

export const { clearForceUpdateSlice, clearPendingMdp9Ids, addToPendingMdp9Ids } =
  forceUpdateSlice.actions;
export const selectForceUpdatePendingMdp9Ids = (state: RootState) =>
  state.forceUpdate.pendingMdp9Ids;

export default forceUpdateSlice.reducer;
