export const NOTICE_MOCK = [
  {
    id: 'notice-mock-01',
    title: 'แจ้งเตือน: โปรดจัดการรายชื่อแพทย์บนระบบ Health Link',
    date: '31/07/2023',
    data: `<p>เรียน ผู้ดูแลระบบฝั่งสถานพยาบาล (Hospital Administration)</p><p>ตามที่ ท่านได้รับมอบหมายเพื่อเข้าใช้งานระบบ Health Link ในฐานะผู้ดูแลระบบฝั่งสถานพยาบาล (Hospital Administration) โดยเป็นผู้มีอำนาจในการจัดการรายชื่อแพทย์ของสถานพยาบาล รวมถึงตรวจสอบบันทึกการใช้งานของแพทย์ในระบบ Health Link นั้น</p><p>ในการนี้ เพื่อให้การเข้าถึงข้อมูลสุขภาพผู้ป่วยเป็นไปอย่างปลอดภัย จึงขอความร่วมมือจากท่านในการจัดการข้อมูลของแพทย์บนระบบ Health Link ให้เป็นปัจจุบันอย่างสม่ำเสมอ เช่น เพิ่มรายชื่อของแพทย์เข้าสู่ระบบ และลบรายชื่อของแพทย์ที่ไม่ได้ทำงานที่สถานพยาบาลอีกต่อไปแล้วออกจากระบบ โดยท่านสามารถเข้าใช้งานระบบฯได้ผ่านเว็บไซต์ <a href="https://hie.healthlink.go.th/page/login" target="_blank">https://hie.healthlink.go.th/page/login</a></p><p>ขอแสดงความนับถือ</p><p>ทีมงาน Health Link</p>`,
  },
  {
    id: 'notice-mock-02',
    title: 'คู่มือการใช้งานผู้ดูแลระบบฝั่งสถานพยาบาล (Hospital Administration)',
    date: '31/07/2023',
    data: `<p>เรียน ผู้ดูแลระบบฝั่งสถานพยาบาล (Hospital Administration)</p><p>ท่านสามารถศึกษารายละเอียดในการจัดการข้อมูลของแพทย์บนระบบ Health Link ได้จาก <a href="https://drive.google.com/drive/folders/11TrNKB-MKn_KRnwyppzhV8Ww3hE1J4iB" target="_blank">คู่มือการใช้งานผู้ดูแลระบบฝั่งสถานพยาบาล (Hospital Administration)</a></p><p>ทั้งนี้ หากท่านมีข้อสงสัย หรือพบปัญหาในการใช้งานระบบฯ กรุณาติดต่อ 020262333 ต่อ 1310 (เกษวรางค์)</p><p>ขอแสดงความนับถือ</p><p>ทีมงาน Health Link</p>`,
  },
];
