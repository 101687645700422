import React, { Suspense } from 'react';
import ErrorMessageDialog from 'components/Dialog/ErrorMessageDialog';
import PageFooter from 'components/Footer/PageFooter';
import { selectRoles } from 'global_store/features/authen/authenSlice';
import {
  selectErrorMessage,
  selectShowErrorDialog,
  setErrorMessage,
  setShowErrorDialog,
} from 'global_store/features/message/messageSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import { Outlet } from 'react-router-dom';

import { styled } from '@mui/material/styles';

const PREFIX = 'PageLayout';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: '#F1FFFB',
  },
}));

function PageLayout(): JSX.Element {
  const dispatch = useAppDispatch();
  const showErrorDialog = useAppSelector(selectShowErrorDialog);
  const errorMessage = useAppSelector(selectErrorMessage);
  const userRoles = useAppSelector(selectRoles);

  React.useEffect(() => {
    document.body.style.overflow = 'unset';
  });

  const handleCloseErrorDialog = () => {
    dispatch(setShowErrorDialog(false));
    dispatch(setErrorMessage(null));
  };

  return (
    <Root className={classes.root}>
      <ErrorMessageDialog
        open={showErrorDialog}
        onClose={handleCloseErrorDialog}
        errorMessage={errorMessage}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
        <PageFooter />
      </Suspense>
    </Root>
  );
}

export default PageLayout;
