import type { RootState } from 'global_store/store';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ModalName {
  CONSENT_OUT_RESULT = 'ConsentOutResult',
  RECEIVED_NEW_DATA = 'ReceivedNewData',
  SELECT_MDPS_TO_FORCE_UPDATE = 'SelectMdpsToForceUpdate',
}

interface IGlobalModalState {
  openModal: null | string; // modal key to open
}

const initialState: IGlobalModalState = {
  openModal: null,
};

export const globalModalSlice = createSlice({
  name: 'globalModal',
  initialState,
  reducers: {
    setOpenModal: (state, action: PayloadAction<string | null>) => {
      state.openModal = action.payload;
    },
  },
});

export const { setOpenModal } = globalModalSlice.actions;

export const selectOpenModal = (state: RootState) => state.globalModal.openModal;

export const selectIsOpenModalConsentOutResult = createSelector(
  selectOpenModal,
  (openModal) => openModal === ModalName.CONSENT_OUT_RESULT,
);

export const selectIsOpenModalReceivedNewData = createSelector(
  selectOpenModal,
  (openModal) => openModal === ModalName.RECEIVED_NEW_DATA,
);

export const selectIsOpenModalSelectMdpsToForceUpdate = createSelector(
  selectOpenModal,
  (openModal) => openModal === ModalName.SELECT_MDPS_TO_FORCE_UPDATE,
);

export default globalModalSlice.reducer;
