import { RootState, store } from 'global_store/store';
import {
  EachPatientProgressInfoList,
  PatientProgressInfo,
  ProgressInfo,
} from 'interface/forceUpdate';
import { getUpdatedPatientProgressInfo } from 'utils/forceUpdate';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { selectPatientID } from '../patient/patientSlice';

interface IJmgntSliceState {
  patientProgressInfo: PatientProgressInfo; // not sure if want to store progresses or just the current one. or the current one might be an array because there are many jobs
}

const initialState: IJmgntSliceState = {
  patientProgressInfo: {},
};

export const jobMgntSlice = createSlice({
  name: 'jobMgnt',
  initialState,
  reducers: {
    setPatientProgressInfo: (
      state,
      action: PayloadAction<IJmgntSliceState['patientProgressInfo']>,
    ) => {
      state.patientProgressInfo = action.payload;
    },
    mergeNewProgress: (state, action: PayloadAction<ProgressInfo[]>) => {
      const updated = getUpdatedPatientProgressInfo(action.payload, state.patientProgressInfo);
      if (updated) state.patientProgressInfo = updated;
    },
    clearJobMgntSlice: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setPatientProgressInfo, mergeNewProgress, clearJobMgntSlice } = jobMgntSlice.actions;

export const selectJobMgntPatientProgressInfo = (state: RootState) =>
  state.jobMgnt.patientProgressInfo;

export const selectEachPatientProgressInfoList = createSelector(
  selectJobMgntPatientProgressInfo,
  selectPatientID,
  (patientProgressInfo, patientId): undefined | EachPatientProgressInfoList => {
    if (!patientId) return undefined;
    return patientProgressInfo[patientId];
  },
);

export default jobMgntSlice.reducer;
