import { createTheme } from '@mui/material/styles';

// not sure where this module augmentation should be added
// see https://mui.com/material-ui/customization/palette/
declare module '@mui/material/styles' {
  interface Palette {
    disabled: Palette['primary'];
  }
  interface PaletteOptions {
    disabled: PaletteOptions['primary'];
  }
}
export const hieTheme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.MuiPaper-elevation2': {
            boxShadow: '0px 1px 1px 0px #7fb7a452 inset',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '72px',
          '&.Mui-expanded': {
            minHeight: '72px',
          },
        },
        content: {
          margin: '8px 0',
          '&.Mui-expanded': {
            margin: '8px 0',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0 16px 8px 16px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        formControl: {
          '&.Mui-disabled': {
            fontWeight: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
            letterSpacing: 'inherit',
            color: 'inherit',
            '&.MuiInput-underline:before': {
              borderBottom: 0,
            },
          },
        },
        inputMultiline: {
          paddingTop: 'inherit',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          color: '#9B9B9B',
          '&.Mui-disabled': {
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1,
            letterSpacing: '0.01em',
            color: '#9B9B9B',
            transform: 'translate(0, 0)',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#D0FBED',
            '&:hover': {
              backgroundColor: '#B3ECD8',
            },
          },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { className: 'center' },
          style: { textAlign: 'center' },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          padding: '0.25em 1em',
          fontSize: '1.125em',
        },
        sizeSmall: {
          margin: '0.25em',
          padding: '0em 0.25em',
        },
        sizeLarge: {
          fontSize: '1.25em',
          lineHeight: 1.5,
          padding: '0.625em 0.625em',
          borderRadius: '16px',
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  palette: {
    primary: {
      main: '#47BDEB',
      contrastText: '#FAFAFA', // copied from portal. not sure.
    },
    secondary: {
      main: '#22D99B',
      light: '#D0FBED',
      dark: '#B3ECD8', // TODO: double check
    },
    background: {
      default: '#F2F6F5',
      paper: '#FFFFFF',
    },
    disabled: {
      main: '#CBCBCB',
      dark: '#7B7B7B',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FFBF30',
    },
    text: {
      primary: '#193661', // old '#141414'
      secondary: '#7B7B7B',
      disabled: '#CBCBCB',
    },
    error: {
      main: '#F06464',
    },
  },
  typography: {
    fontFamily: 'SukhumvitSet, sans-serif',
    subtitle1: {
      fontWeight: 400,
      fontSize: '2rem',
      lineHeight: 1.5,
      letterSpacing: '0.01em',
      color: '#193661',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.5,
      letterSpacing: '0.01em',
      color: 'white',
    },
    h1: {
      fontWeight: 600,
      fontSize: '2.25rem',
      lineHeight: 1.5,
      letterSpacing: '0.01em',
      color: '#193661',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: 1.5,
      letterSpacing: '0.01em',
      color: '#193661',
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.75rem',
      lineHeight: 1.5,
      letterSpacing: '0.01em',
      color: '#193661',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: 1.5,
      letterSpacing: '0.01em',
      color: '#193661',
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: 1.5,
      letterSpacing: '0.01em',
      color: '#193661',
    },
    h6: {
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: 1.75,
      letterSpacing: '0.01em',
      color: '#193661',
    },
    body1: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.01em',
      color: '#193661',
    },
    body2: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.02em',
      color: '#193661',
      textTransform: 'none',
    },
    caption: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.01em',
      color: '#9B9B9B',
    },
    overline: {
      // TODO: revisit the style of overline variant
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 2.25,
      letterSpacing: '0.01em',
      color: '#9B9B9B',
      textTransform: 'none',
    },
    button: {
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: 2.25,
      textTransform: 'none',
    },
  },
});
