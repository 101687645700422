import type { RootState } from 'global_store/store';
import { IAllergyIntoleranceEntry } from 'interface/fhir.resource';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AllergyState {
  allergyExpansion: boolean[];
  allergyList: IAllergyIntoleranceEntry[];
  allergyOffset: number;
  expansion: boolean[];
}

const initialState: AllergyState = {
  allergyExpansion: [],
  allergyList: [],
  allergyOffset: 0,
  expansion: [],
};

export const allergySlice = createSlice({
  name: 'allergy',
  initialState,
  reducers: {
    clearAllergySlice: (state) => {
      // Reset state to initial state or any other desired state
      Object.assign(state, initialState);
    },
    setAllergyExpansion: (state, action: PayloadAction<any | null>) => {
      state.allergyExpansion = action.payload;
    },
    setAllergyList: (state, action: PayloadAction<any | null>) => {
      state.allergyList = action.payload;
    },
    setAllergyOffset: (state, action: PayloadAction<any | null>) => {
      state.allergyOffset = action.payload;
    },
    // TODO: vvv might merge expansion, periodParam and hospitalParam from all resources into one slice
    setExpansion: (state, action: PayloadAction<AllergyState['expansion']>) => {
      state.expansion = action.payload;
    },
  },
});

export const {
  clearAllergySlice,
  setAllergyExpansion,
  setAllergyList,
  setAllergyOffset,
  setExpansion,
} = allergySlice.actions;

export const selectAllergyExpansion = (state: RootState) => state.allergy.allergyExpansion;
export const selectAllergyList = (state: RootState) => state.allergy.allergyList;
export const selectAllergyOffset = (state: RootState) => state.allergy.allergyOffset;

export default allergySlice.reducer;
