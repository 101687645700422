import type { RootState } from 'global_store/store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MessageState {
  showErrorDialog: boolean;
  errorMessage:
    | string
    | number
    | null
    | {
        code: number;
        text?: string;
        title?: string;
        type?: 'error' | 'warning';
        logoutOnConfirm?: boolean;
      };
  showSuccessDialog: boolean;
  successMessage:
    | {
        title: string;
        detail: string;
        subDetail: string;
      }
    | string;
}

const initialState: MessageState = {
  showErrorDialog: false,
  errorMessage: null,
  showSuccessDialog: false,
  successMessage: '',
};

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setShowErrorDialog: (state, action: PayloadAction<boolean>) => {
      state.showErrorDialog = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<MessageState['errorMessage']>) => {
      state.errorMessage = action.payload;
    },
    setShowSuccessDialog: (state, action: PayloadAction<boolean>) => {
      state.showSuccessDialog = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<MessageState['successMessage']>) => {
      state.successMessage = action.payload;
    },
  },
});

export const { setShowErrorDialog, setErrorMessage, setShowSuccessDialog, setSuccessMessage } =
  messageSlice.actions;

export const selectShowErrorDialog = (state: RootState) => state.message.showErrorDialog;
export const selectErrorMessage = (state: RootState) => state.message.errorMessage;
export const selectShowSuccessDialog = (state: RootState) => state.message.showSuccessDialog;
export const selectSuccessMessage = (state: RootState) => state.message.successMessage;

export default messageSlice.reducer;
