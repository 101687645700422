import React from 'react';
import { IHospitalAdminTabMeta } from 'interface';
import { FaBell, FaUserCog } from 'react-icons/fa';
import {
  MdHome,
  MdMail,
  MdOutlineKeyboardArrowRight,
  MdOutlineNoteAlt,
  MdOutlinePhonelinkSetup,
  MdPerson,
} from 'react-icons/md';

export const enableHAExchangeConfigForAllEnvs = false;
export const BULK_DOCTOR_MANAGEMENT_BATCH_SIZE = 100;

const DoctorActivity = React.lazy(
  () => import('pages/console/HospitalAdminManagement/DoctorActivity'),
);
// const ExchangeZone = React.lazy(() => import('pages/console/HospitalAdminManagement/ExchangeZone'));
const ExchangeZoneConfig = React.lazy(
  () => import('pages/console/HospitalAdminManagement/ExchangeZoneConfig'),
);
const Home = React.lazy(() => import('pages/console/HospitalAdminManagement/Home'));
const Dashboard = React.lazy(() => import('pages/console/HospitalAdminManagement/Dashboard'));
const UserUpdatedLog = React.lazy(
  () => import('pages/console/HospitalAdminManagement/UserUpdatedLog'),
);
const ProfilePage = React.lazy(() => import('pages/console/UserManagement/ProfilePage'));
const Notice = React.lazy(() => import('pages/console/UserManagement/Notice'));
const Contact = React.lazy(() => import('pages/console/UserManagement/Contact'));
const User = React.lazy(() => import('pages/console/HospitalAdminManagement/User'));
const DeviceManagement = React.lazy(
  () => import('pages/console/HospitalAdminManagement/DeviceManagement'),
);
const APIKeyManagement = React.lazy(
  () => import('pages/console/HospitalAdminManagement/APIKeyManagement'),
);
const FHIRDataAccessLog = React.lazy(
  () => import('pages/console/HospitalAdminManagement/FHIRDataAccessLog'),
);
const ConsentRequestLog = React.lazy(
  () => import('pages/console/HospitalAdminManagement/ConsentRequestLog'),
);

export const ROUTE_PREFIX = '/admin/home';
export const USER_ROUTE_PREFIX = '/admin/user';

export const TAB_LIST: IHospitalAdminTabMeta[] = [
  {
    iconComponent: MdHome,
    tabName: 'หน้าหลัก',
    tabKey: 'admin-home',
    tabPanelComponent: Home,
  },
  {
    iconComponent: MdPerson,
    tabName: 'บริหารจัดการรายชื่อแพทย์',
    tabKey: 'manage-doctor',
    tabPanelComponent: User,
  },
  {
    iconComponent: MdOutlineNoteAlt,
    tabName: 'การติดตามดูแลระบบฯ',
    tabKey: 'tracking-and-system',
    tabPanelComponent: Home,
    subMenu: [
      {
        iconComponent: MdOutlineKeyboardArrowRight,
        tabName: 'แดชบอร์ด',
        tabKey: 'dashboard',
        tabPanelComponent: Dashboard,
      },
      {
        iconComponent: MdOutlineKeyboardArrowRight,
        tabName: 'บันทึกการใช้งานระบบฯ\nของแพทย์',
        tabKey: 'doctor-activity',
        tabPanelComponent: DoctorActivity,
      },
      {
        iconComponent: MdOutlineKeyboardArrowRight,
        tabName: 'บันทึกการอัปเดตข้อมูล\nผู้ป่วยรายบุคคล',
        tabKey: 'user-updated-log',
        tabPanelComponent: UserUpdatedLog,
      },
      {
        iconComponent: MdOutlineKeyboardArrowRight,
        tabName: 'บันทึกการเข้าถึงข้อมูล\nผู้ป่วย',
        tabKey: 'user-access-log',
        tabPanelComponent: FHIRDataAccessLog,
      },
      {
        iconComponent: MdOutlineKeyboardArrowRight,
        tabName: 'บันทึกการขอความยินยอม\nรายครั้ง',
        tabKey: 'user-accept-log',
        tabPanelComponent: ConsentRequestLog,
      },
    ],
  },
  {
    iconComponent: MdOutlinePhonelinkSetup,
    tabName: 'การตั้งค่าการใช้งานระบบฯ',
    tabKey: 'setting-system',
    tabPanelComponent: Home,
    subMenu: [
      {
        iconComponent: MdOutlineKeyboardArrowRight,
        tabName: 'จำกัดเครือข่ายการใช้งาน',
        tabKey: 'device',
        tabPanelComponent: DeviceManagement,
      },
      {
        iconComponent: MdOutlineKeyboardArrowRight,
        tabName: 'เปลี่ยน API Key สำหรับใช้\nในการแลกเปลี่ยนข้อมูล',
        tabKey: 'api-key',
        tabPanelComponent: APIKeyManagement,
      },
      {
        iconComponent: MdOutlineKeyboardArrowRight,
        tabName: 'การตั้งค่าดึงข้อมูลจากระบบทดสอบ',
        tabKey: 'exchange-zone-config',
        tabPanelComponent: ExchangeZoneConfig,
      },
    ],
  },
];

export const DOCTOR_USER_TABLE_COLUMNS = [
  {
    Header: 'Medical Certificate Number',
    accessor: 'username',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'First Name',
    accessor: 'firstname',
  },
  {
    Header: 'Last Name',
    accessor: 'lastname',
  },
  {
    Header: 'Employment Type',
    accessor: 'employment_type',
  },
  {
    Header: 'Manage',
    accessor: 'actions',
  },
];

export const USER_TAB = [
  {
    iconComponent: MdHome,
    tabName: 'หน้าหลัก',
    tabKey: 'admin-home',
    path: '/admin/home/admin-home',
    tabPanelComponent: Home,
  },
  {
    iconComponent: FaUserCog,
    tabName: 'จัดการบัญชีผู้ใช้งาน',
    tabKey: 'profile',
    tabPanelComponent: ProfilePage,
  },
  {
    iconComponent: FaBell,
    tabName: 'แจ้งเตือน',
    tabKey: 'notice',
    tabPanelComponent: Notice,
  },
  {
    iconComponent: MdMail,
    tabName: 'ติดต่อระบบฯ',
    tabKey: 'contact',
    tabPanelComponent: Contact,
  },
];

export const DEVICE_TABLE_COLUMNS = [
  {
    Header: 'ลำดับ',
    accessor: 'index',
  },
  {
    Header: 'IP Address',
    accessor: 'ip_address',
  },
  {
    Header: 'แก้ไข',
    accessor: 'edit',
  },
  {
    Header: 'ลบ',
    accessor: 'delete',
  },
];

export const FHIR_DATA_ACCESS_LOG_TABLE_COLUMNS = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
  },
  {
    Header: 'Doctor (Medical Certificate Number)',
    accessor: 'doctor_name',
  },
  {
    Header: 'MDP9ID',
    accessor: 'doctor_mdp9id',
  },
  {
    Header: 'Patient (CID)',
    accessor: 'patient_name',
  },
  {
    Header: 'Entry Type',
    accessor: 'entry_type',
  },
];

export const CONSENT_REQUEST_LOG_TABLE_COLUMNS = [
  {
    Header: 'Patient (CID)',
    accessor: 'patient_name',
  },
  {
    Header: 'Doctor (Medical Number)',
    accessor: 'doctor_name',
  },
  {
    Header: 'Hospital (MDP9ID)',
    accessor: 'mdp_name',
  },
  {
    Header: 'Allowed Date',
    accessor: 'allow_date',
  },
];
