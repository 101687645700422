import { ISelectOption } from 'interface';

export const sessionLength = 1800;
export const accessTokenAge = 900;
export const showSessionTimer = false;

export const useTCVersion = true;

const isDEVMode = process.env.REACT_APP_HOST_ENV === 'development';

export const useSaveBrowserFingerprintApi = false; // change this to true when backend is ready
export const enableSelectLanguage = isDEVMode;
export const enableContactSystem = false;

export const hosAdminConfig = (() => {
  return {
    showPageInDEVOnly: isDEVMode,
    doctorActivitySearch: isDEVMode,
    fhirDataAccessLog: isDEVMode,
    consentRequestLog: isDEVMode,
    showConsentChannelColumn: isDEVMode,
    showDashboard: isDEVMode,
    showDentist: false,
  };
})();

export const DoctorUsageTableColumns = [
  {
    Header: 'วัน/เวลา',
    accessor: 'timestamp',
  },
  {
    Header: 'ชื่อแพทย์ในสังกัด (เลขใบอนุญาต)',
    accessor: 'doctorFullname',
  },
  {
    Header: 'ชื่อผู้ป่วย (เลขบัตรประชาชน)',
    accessor: 'patientFullname',
  },
];

export const VisitTypes = ['OPD', 'IPD', 'EMER'];

export const GenderValues = {
  male: ['M', 'MALE'],
  female: ['F', 'FEMALE'],
};

export const PeriodDisplay = {
  '1week': '1 สัปดาห์',
  '1month': '1 เดือน',
  '3months': '3 เดือน',
  '6months': '6 เดือน',
  '1year': '1 ปี',
  'all-time': 'ทั้งหมด',
};

export const DoctorEvaluationFormURI = 'https://forms.gle/8cVTgvgycCZmAh6D7';
export const MDPAdminEvaluationFormURI =
  'https://docs.google.com/forms/d/e/1FAIpQLScuLfT1DB4X6F1C5qDTmcJHHGCQNEhBr5E7uPU-iDAvuc7jjA/viewform';

export const APIKeyGroupOption: ISelectOption[] = [
  {
    value: 'DMS',
    label: 'DMS',
  },
  {
    value: 'HIS',
    label: 'HIS',
  },
  {
    value: 'KTB',
    label: 'KTB',
  },
  {
    value: 'EXCHANGE_ZONE',
    label: 'EXCHANGE_ZONE',
  },
  {
    value: 'HIE',
    label: 'HIE',
  },
  {
    value: 'PHR',
    label: 'PHR',
  },
];

export const MAX_LOGIN_ATTEMPTS = 3;

export const EXCHANGE_ZONE_RESOURCE_TABLE_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Available',
    accessor: 'available',
  },
];

export const RESOURCE_NAME_LIST: (
  | 'allergy_intolerance'
  | 'medication_statement'
  | 'immunization'
  | 'condition'
  | 'procedure'
  | 'diagnostic_report'
  | 'observation'
  | 'encounter'
  | 'composition'
  | 'document_reference'
)[] = [
  'allergy_intolerance',
  'medication_statement',
  'immunization',
  'condition',
  'procedure',
  'diagnostic_report',
  'observation',
  'encounter',
  'composition',
  'document_reference',
];

export const ENABLE_SEARCHBAR_ROLES = ['DOCTOR'];
