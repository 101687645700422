import {
  defaultHospitalParam,
  defaultPeriodParam,
  defaultResourceExpand,
} from 'config/patient-info';
import { type RootState, store } from 'global_store/store';
import { IHospital, IPeriodParam, IResourceTab, RESOURCE_TABS } from 'interface';
import _ from 'lodash';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TabPeriodParams =
  | {
      [tab in IResourceTab]: IPeriodParam;
    }
  | {};

export type TabHospitalParams =
  | {
      [tab in IResourceTab]: IHospital[] | null;
    }
  | {};

export type TabItemExpansions =
  | {
      [tab in IResourceTab]: boolean[];
    }
  | {};

interface LoadedState {
  loadedList: IResourceTab[];
  totalList: any;
  byCaseNotConsentedTotalList: { key: string; total: number }[]; // TODO: convert to object type
  tabPeriodParams: TabPeriodParams;
  tabHospitalParams: TabHospitalParams;
  tabItemExpansions: TabItemExpansions;
}

const initialState: LoadedState = {
  loadedList: [],
  totalList: [],
  byCaseNotConsentedTotalList: [],
  tabPeriodParams: RESOURCE_TABS.reduce((acc, tab) => ({ ...acc, [tab]: defaultPeriodParam }), {}),
  tabHospitalParams: RESOURCE_TABS.reduce(
    (acc, tab) => ({ ...acc, [tab]: defaultHospitalParam }),
    {},
  ),
  tabItemExpansions: RESOURCE_TABS.reduce((acc, tab) => ({ ...acc, [tab]: [] }), {}),
};

export const loadedSlice = createSlice({
  name: 'loaded',
  initialState,
  reducers: {
    clearLoadedSlice: (state) => {
      // Reset state to initial state or any other desired state
      Object.assign(state, initialState);
    },
    setLoadedList: (state, action: PayloadAction<IResourceTab | null>) => {
      if (action.payload === null) {
        state.loadedList = [];
      } else {
        const temp = [action.payload, ...state.loadedList];
        state.loadedList = _.uniqBy(temp, (o) => o);
      }
    },
    clearLoadedList: (state) => {
      state.loadedList = initialState.loadedList;
    },
    setTotalList: (state, action: PayloadAction<any | null>) => {
      if (action.payload === null) {
        state.totalList = [];
      } else {
        const temp = [action.payload, ...state.totalList];
        state.totalList = _.uniqBy(temp, (o) => o.key);
      }
    },
    setByCaseNotConsentedTotalList: (state, action: PayloadAction<any | null>) => {
      if (action.payload === null) {
        state.byCaseNotConsentedTotalList = [];
      } else {
        const temp = [action.payload, ...state.byCaseNotConsentedTotalList];
        state.byCaseNotConsentedTotalList = _.uniqBy(temp, (o) => o.key);
      }
    },
    setTabPeriodParam: (
      state,
      action: PayloadAction<{ tab: IResourceTab; periodParam: IPeriodParam }>,
    ) => {
      state.tabPeriodParams[action.payload.tab] = action.payload.periodParam;
    },
    setTabHospitalParam: (
      state,
      action: PayloadAction<{ tab: IResourceTab; hospitalParam: IHospital[] | null }>,
    ) => {
      state.tabHospitalParams[action.payload.tab] = action.payload.hospitalParam;
    },
    setTabItemExpansion: (
      state,
      action: PayloadAction<{ tab: IResourceTab; itemExpansion: boolean[] }>,
    ) => {
      state.tabItemExpansions[action.payload.tab] = action.payload.itemExpansion;
    },
    toggleTabItemExpansion: (
      state,
      action: PayloadAction<{ tab: IResourceTab; index: number }>,
    ) => {
      const { tab, index } = action.payload;
      const prev = state.tabItemExpansions[tab];
      let updated: boolean[] = prev;
      if (index > prev.length - 1) {
        updated = Array(index + 1)
          .fill(defaultResourceExpand)
          .map((item, i) => (i <= prev.length - 1 ? prev[i] : defaultResourceExpand));
      }
      updated = updated.map((item, i) => (i === index ? !item : item));
      state.tabItemExpansions[tab] = updated;
    },
    setAllTabItemExpansion: (
      state,
      action: PayloadAction<{ tab: IResourceTab; expansion: boolean }>,
    ) => {
      state.tabItemExpansions[action.payload.tab] = state.tabItemExpansions[action.payload.tab].map(
        (item) => action.payload.expansion,
      );
    },
  },
});

export const {
  clearLoadedSlice,
  setLoadedList,
  setTotalList,
  setByCaseNotConsentedTotalList,
  setTabPeriodParam,
  setTabHospitalParam,
  setTabItemExpansion,
  toggleTabItemExpansion,
  clearLoadedList,
  setAllTabItemExpansion,
} = loadedSlice.actions;

export const selectLoadedList = (state: RootState) => state.loaded.loadedList;
export const selectTotalList = (state: RootState) => state.loaded.totalList;
export const selectByCaseNotConsentedTotalList = (state: RootState) =>
  state.loaded.byCaseNotConsentedTotalList;
export const selectTabPeriodParams = (state: RootState) => state.loaded.tabPeriodParams;
export const selectTabHospitalParams = (state: RootState) => state.loaded.tabHospitalParams;
export const selectTabItemExpansions = (state: RootState) => state.loaded.tabItemExpansions;

export default loadedSlice.reducer;
