import React from 'react';
import LoginImage from 'assets/img/login_image.png';
import LogoSmall from 'assets/img/logo_small.png';
import TMCService from 'assets/img/tmc_service.png';
import GenericButton from 'components/Button/GenericButton';
import Captcha from 'components/Captcha/Captcha';
import SelectHospitalDialog from 'components/Dialog/SelectHospitalDialog';
import TermsDialog from 'components/Dialog/TermsDialog';
import GenericInput from 'components/Input/GenericInput';
import { accessTokenAge, MAX_LOGIN_ATTEMPTS, sessionLength, useTCVersion } from 'config';
import {
  selectRoles,
  selectUser,
  setAccessToken,
  setAccessTokenAndSessionExpiredTime,
  setJmgntWsToken,
  setRoles,
  setSessionExpiredTime,
  setUser,
} from 'global_store/features/authen/authenSlice';
import { setSelectedHospital } from 'global_store/features/hospital/hospitalSlice';
import { setErrorMessage, setShowErrorDialog } from 'global_store/features/message/messageSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import { ITermElement, ITermResponse } from 'interface';
import { ILoginForm, IUser } from 'interface/authen';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaArrowCircleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { acceptTerms, getTerms } from 'services';
import { getAccessToken, getCurrentUser, requestLogin, requestLogout } from 'services/authen';
import { decodeAccessToken } from 'utils/authen';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { Box, CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PREFIX = 'Login';

const classes = {
  root: `${PREFIX}-root`,
  margin: `${PREFIX}-margin`,
  marginContent: `${PREFIX}-marginContent`,
  centerAllContent: `${PREFIX}-centerAllContent`,
  justifyRight: `${PREFIX}-justifyRight`,
  headerLine: `${PREFIX}-headerLine`,
  imageGrid: `${PREFIX}-imageGrid`,
  fullSizeImage: `${PREFIX}-fullSizeImage`,
  tmcImage: `${PREFIX}-tmcImage`,
  tmcImageContainer: `${PREFIX}-tmcImageContainer`,
  loginCard: `${PREFIX}-loginCard`,
  tealText: `${PREFIX}-tealText`,
  blockDisplay: `${PREFIX}-blockDisplay`,
  adminLogin: `${PREFIX}-adminLogin`,
  fullWidth: `${PREFIX}-fullWidth`,
  adminLoginButton: `${PREFIX}-adminLoginButton`,
  longBottomMargin: `${PREFIX}-longBottomMargin`,
  dialog: `${PREFIX}-dialog`,
  dialogContent: `${PREFIX}-dialogContent`,
  dialogButton: `${PREFIX}-dialogButton`,
  form: `${PREFIX}-form`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    maxWidth: '1044px',
  },

  [`& .${classes.margin}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
  },

  [`& .${classes.marginContent}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  [`& .${classes.centerAllContent}`]: {
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },

  [`& .${classes.justifyRight}`]: {
    justifyContent: 'right',
    textAlign: 'end',
  },

  [`& .${classes.headerLine}`]: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.imageGrid}`]: {
    // background: `url(${LoginImage})`,
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "stretch",
    minHeight: '400px',
    overflow: 'hidden',
  },

  [`& .${classes.fullSizeImage}`]: {
    height: '100%',
  },

  [`& .${classes.tmcImage}`]: {
    width: '50%',
    minWidth: '100px',
  },

  [`& .${classes.tmcImageContainer}`]: {
    minHeight: '80px',
    justifyContent: 'right',
  },

  [`& .${classes.loginCard}`]: {
    borderRadius: '30px',
    maxHeight: '389px',
  },

  [`& .${classes.tealText}`]: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },

  [`& .${classes.blockDisplay}`]: {
    display: 'block',
  },

  [`& .${classes.adminLogin}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.fullWidth}`]: {
    width: '100%',
  },

  [`& .${classes.adminLoginButton}`]: {
    background: theme.palette.secondary.main,
    color: '#F1FFFB',
    borderRadius: '50%',
    fontSize: '1.5em',
  },

  [`& .${classes.longBottomMargin}`]: {
    marginBottom: theme.spacing(12),
  },

  [`& .${classes.dialog}`]: {
    borderRadius: '16px',
  },

  [`& .${classes.dialogContent}`]: {
    padding: theme.spacing(4),
  },

  [`& .${classes.dialogButton}`]: {
    justifyContent: 'center',
    maxWidth: '50%',
  },

  [`& .${classes.form}`]: {
    display: 'flex',
  },
}));

interface ContainerProps {}

function Login(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const roles = useAppSelector(selectRoles);
  const schema = yup
    .object()
    .shape({
      username: yup.string().required('กรุณาใส่ชื่อผู้ใช้งาน'),
      password: yup.string().required('กรุณาใส่รหัสผ่าน'),
    })
    .required();
  const { control, handleSubmit } = useForm<ILoginForm>({
    resolver: yupResolver(schema),
  });
  const [openSelectHospitalDialog, setOpenSelectHospitalDialog] = React.useState<boolean>(false);
  const [openTermsDialog, setOpenTermsDialog] = React.useState<boolean>(false);
  const [termElements, setTermElements] = React.useState<ITermElement[] | null>(null);
  const [requiredTermVersion, setRequiredTermVersion] = React.useState<string | null>(null);
  const [loginAttempts, setLoginAttempts] = React.useState<number>(0);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [loadSuccess, setLoadSuccess] = React.useState<boolean>(true);

  const handleAcceptTerms = async () => {
    const { success, error } = await acceptTerms();
    if (success) {
      const accessTokenRes = await getAccessToken();
      if (!accessTokenRes.success) {
        console.log(accessTokenRes.error);
        return;
      }
      dispatch(setAccessToken(accessTokenRes.data.access_token));
      // dispatch(
      //   setAccessTokenAndSessionExpiredTime({
      //     accessToken: accessTokenRes.data.access_token,
      //   }),
      // );
      // Get User Profile
      const profileRes = await getCurrentUser();
      if (profileRes.success) {
        dispatch(setUser(profileRes.data));
      }
      setOpenTermsDialog(false);
    } else {
      console.log(error);
    }
  };

  const handleDeclineTerms = async () => {
    setOpenTermsDialog(false);
    handleLogout();
  };

  // const handleButtonClick = () => {
  //   if (!isLoading) {
  //     setIsLoading(true);
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 3000);
  //   }
  // };

  const isTermValid = React.useCallback(
    (user: IUser | null) => {
      if (!user) {
        return false;
      }
      if (useTCVersion) {
        if (!user.tc_version || !user.accept_tc) {
          return false;
        }

        const requiredTermDigits = requiredTermVersion?.split('.');
        const currentTermDigits = user.tc_version?.split('.');
        if (!requiredTermDigits || !currentTermDigits) {
          return false;
        }
        for (let i = 0; i < 3; i++) {
          if (parseInt(requiredTermDigits[i]) > parseInt(currentTermDigits[i])) {
            return false;
          }
        }
        return true;
      }
      return user.accept_tc;
    },
    [requiredTermVersion],
  );

  const isDoctor = () => {
    return roles?.includes('DOCTOR') || false;
  };

  const onSubmit: SubmitHandler<ILoginForm> = async (formData) => {
    const { success, data, error } = await requestLogin(formData);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    if (success) {
      // Set access token
      const tokenPayload = decodeAccessToken(data.access_token);
      // dispatch(
      //   setSessionExpiredTime(
      //     tokenPayload ? tokenPayload.exp + (sessionLength - accessTokenAge) : 0,
      //   ),
      // );
      // dispatch(setAccessToken(data.access_token));
      dispatch(
        setAccessTokenAndSessionExpiredTime({
          accessToken: data.access_token,
          decodedToken: tokenPayload,
        }),
      );
      dispatch(setRoles(tokenPayload?.roles || null));
      // Get User Profile
      const profileRes = await getCurrentUser();
      if (profileRes.success) {
        dispatch(setUser(profileRes.data));
      }
      if (tokenPayload?.roles.includes('MDP_ADMIN') || tokenPayload?.roles.includes('ADMIN')) {
        if (profileRes.data.list_mdp.length >= 1) {
          dispatch(setSelectedHospital(profileRes.data.list_mdp[0]));
        }
      }
    } else if (!data.errorIsHandled) {
      const get401Title = (type: string) => {
        if (type === 'Your account is locked.') {
          return {
            title:
              'ท่านกรอกรหัสผ่านไม่ถูกต้องเกินจำนวนครั้งที่กำหนด\nบัญชีผู้ใช้งานนี้จึงไม่สามารถใช้งานได้อีก',
            text: 'โปรดติดต่อทีมงาน Health Link ที่\nemail: gbdi-hie@depa.or.th\nโทร. 02 026 2333 ต่อ 3456',
          };
        }
        // note: moved Your IP address must be inside medical place IP network. to global interceptor because it should handle on refresh page as well in addition to on login. also it is 400 not 401
        return {
          title: 'บัญชีผู้ใช้งานนี้สามารถใช้งานได้เพียงหน้าจอเดียวเท่านั้น', // note: this case should have been handled in the global interceptor already
          text: 'กรุณาปิดหน้าจออื่นที่ใช้งานบัญชีนี้',
        };
      };

      const textError = {
        403: {
          title: 'ชื่อบัญชีผู้ใช้งานหรือรหัสผ่านของท่านไม่ถูกต้อง',
          text: 'โปรดลองใหม่อีกครั้ง',
        },
        401: {
          title: get401Title(data.detail).title,
          text: get401Title(data.detail).text,
        },
        500: {
          title: 'ระบบฯ มีปัญหาขัดข้อง ', // note: this case should have been handled in the global interceptor already
          text: 'โปรดติดต่อทีมงาน Health Link ที่\nemail: gbdi-hie@depa.or.th\nโทร. 02 026 2333 ต่อ 3456',
        },
      };
      if (error && textError[error]) {
        dispatch(
          setErrorMessage({
            type: 'error',
            code: typeof error === 'number' ? error : undefined,
            title: textError[error]?.title,
            text: textError[error]?.text,
          }),
        );
        dispatch(setShowErrorDialog(true));
      }
    }
    if (!success && data.detail === 'Invalid username or password') {
      setLoginAttempts(data.loginAttempts);
    }
    console.log(data);
  };

  const onInvalid = (e: any) => {
    console.log('Invalid Form');
  };

  const handleHospitalDialogConfirm = () => {
    setOpenSelectHospitalDialog(false);
    navigate('/console/home');
  };

  const handleHospitalDialogClose = async () => {
    setOpenSelectHospitalDialog(false);
  };

  const handleLogout = () => {
    requestLogout();
    dispatch(setUser(null));
    // dispatch(setAccessToken(null));
    dispatch(
      setAccessTokenAndSessionExpiredTime({
        accessToken: null,
      }),
    );
    dispatch(setJmgntWsToken(null));
    navigate('/page/login');
  };

  const requestTerms = React.useCallback(async () => {
    const { success, data } = await getTerms();
    if (success) {
      const { description, required_version } = data as ITermResponse;
      setTermElements(JSON.parse(description));
      setRequiredTermVersion(required_version);
    } else {
      setTermElements(null);
    }
  }, []);

  const onLoad = React.useCallback(async () => {
    if (user && roles) {
      await requestTerms();

      if (roles.includes('MDP_ADMIN') || roles.includes('ADMIN')) {
        // MDP_ADMIN
        if (isTermValid(user)) {
          navigate('/admin/home');
        } else {
          setOpenTermsDialog(true);
        }
      } else if (roles.includes('DOCTOR')) {
        // DOCTOR
        if (isTermValid(user)) {
          setOpenSelectHospitalDialog(true);
        } else {
          setOpenTermsDialog(true);
        }
      }
    }
  }, [user, roles, isTermValid, requestTerms]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <StyledContainer className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.justifyRight}>
          <br />
          <br />
          <br />
          {/* <IconButton>
              <Typography variant="h6">ENG</Typography>
              <FaChevronRight />
            </IconButton> */}
        </Grid>
        <Grid item xs={12} className={classes.headerLine}>
          <img src={LogoSmall} alt="HIE Logo" />
          <Typography variant="h1">ยินดีต้อนรับสู่ระบบ Health Link</Typography>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.loginCard}>
            <Grid container>
              <Grid item xs={6} className={classes.imageGrid}>
                <img src={LoginImage} alt="Login Cover" className={classes.fullSizeImage} />
              </Grid>
              <Grid item xs={6}>
                <List>
                  <ListItem className={classes.tmcImageContainer}>
                    <img src={TMCService} alt="TMC service" className={classes.tmcImage} />
                  </ListItem>
                  <ListItem className={classes.blockDisplay}>
                    <Typography variant="h3" className={classes.tealText} component="p">
                      แพทย์
                    </Typography>
                    <Typography variant="h3" className={classes.tealText} component="p">
                      กรุณาล็อกอิน:
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <GenericButton
                      onClick={() => {
                        window.location.href = '/api/auth/login/via-tmc';
                      }}
                      fullWidth
                      size="large"
                    >
                      Doctor Login
                    </GenericButton>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {loginAttempts < MAX_LOGIN_ATTEMPTS && (
          <Grid
            item
            xs={12}
            className={`${classes.centerAllContent} ${classes.margin} ${classes.adminLogin} ${classes.longBottomMargin}`}
          >
            <Typography variant="h6" className={classes.marginContent}>
              ไม่ใช่แพทย์ กรุณาล็อกอินที่นี่:
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit, onInvalid)}>
              <GenericInput
                control={control}
                name="username"
                label="username"
                type="text"
                defaultValue=""
                variant="generic"
                rules={{}}
                className={classes.marginContent}
              />
              <GenericInput
                control={control}
                name="password"
                label="password"
                type="password"
                defaultValue=""
                variant="generic"
                rules={{}}
                className={classes.marginContent}
              />
              <Box display="flex" alignItems="center">
                {isLoading ? (
                  <CircularProgress color="primary" size={25} sx={{ marginLeft: '1rem' }} />
                ) : (
                  <IconButton type="submit" size="large" disabled={isLoading}>
                    <FaArrowCircleRight className={classes.adminLoginButton} />
                  </IconButton>
                )}
              </Box>
            </form>
          </Grid>
        )}
        {loginAttempts >= MAX_LOGIN_ATTEMPTS && (
          <Captcha onSubmit={onSubmit} onInvalid={onInvalid} classes={classes} />
        )}
      </Grid>
      <SelectHospitalDialog
        open={openSelectHospitalDialog && !!user && isDoctor() && isTermValid(user)}
        handleLogout={handleLogout}
        handleCancel={handleHospitalDialogClose}
        handleConfirm={handleHospitalDialogConfirm}
      />
      <TermsDialog
        open={openTermsDialog && !!user && !isTermValid(user)}
        termElements={termElements || []}
        handleCancel={handleDeclineTerms}
        handleConfirm={handleAcceptTerms}
      />
    </StyledContainer>
  );
}

export default Login;
