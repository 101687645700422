import { IDemographic, IHospitalConsent } from 'interface';
import _ from 'lodash';
import { areArraysEqual } from 'utils';

export interface PaotangVerifyEmail {
  hasPaotang: boolean;
  hasEmail: boolean;
  email: string | null;
  isVerifiedEmail: boolean;
}

export interface ConsentDestination {
  paotang: boolean;
  email: boolean;
}

export type ConsentOrVerifyEmailButtonCase =
  | 'check_consent'
  | 'request_consent'
  | 'send_email_verify'
  | 'all_consented';
const getPaotangVerifyEmailInfo = (demographic: IDemographic | null): PaotangVerifyEmail => {
  let appList = demographic?.app_list?.split(',') ?? [];
  appList = appList.map((app) => app.trim());
  const hasPaotang = appList.includes('KTB');
  return {
    hasPaotang,
    hasEmail: !!demographic?.email,
    email: demographic?.email ?? null,
    isVerifiedEmail: demographic?.is_email_verified ?? false,
  };
};

const getConsentOrVerifyEmailButtonCase = (
  paotangVerifyEmail: PaotangVerifyEmail,
  hospitalConsentList: IHospitalConsent[] | null,
): ConsentOrVerifyEmailButtonCase | null => {
  if (!hospitalConsentList) return null;
  const { hasPaotang, hasEmail, email, isVerifiedEmail } = paotangVerifyEmail;
  const byCases = hospitalConsentList.filter((record) => record.type === 'case');
  const allConsented = byCases.filter((record) => record.consent_status === 'CONSENTED').length;
  if (allConsented) return 'all_consented';
  const hasPending =
    (hospitalConsentList &&
      hospitalConsentList.map((record) => record.consent_status).includes('PENDING')) ??
    false;
  if (hasPending) {
    return 'check_consent';
  }
  if (hasPaotang && isVerifiedEmail) {
    return 'request_consent';
  }
  if (hasPaotang && !isVerifiedEmail) {
    return 'request_consent';
  }
  if (!hasPaotang && isVerifiedEmail) {
    return 'request_consent';
  }
  if (!hasPaotang && !isVerifiedEmail) {
    return !hasEmail ? 'request_consent' : 'send_email_verify';
  }
  console.warn('invalid condition', paotangVerifyEmail);
  return null;
};

const getConsentDestination = (
  paotangVerifyEmail: PaotangVerifyEmail,
): ConsentDestination | null => {
  const { hasPaotang, hasEmail, email, isVerifiedEmail } = paotangVerifyEmail;
  if (hasPaotang && isVerifiedEmail) {
    return { paotang: true, email: true };
  }
  if (hasPaotang && !isVerifiedEmail) {
    return { paotang: true, email: false };
  }
  if (!hasPaotang && isVerifiedEmail) {
    return { paotang: false, email: true };
  }
  return null;
};

export const filterTrueConsented = (consentSummary: IHospitalConsent[] | null) => {
  return consentSummary?.filter((mdp) => mdp.consent_status === 'CONSENTED');
};

export const trueConsentedIdsHasChanged = (
  prevTrueConsentedIds: string[],
  trueConsentedIds: string[],
) => {
  return !areArraysEqual(prevTrueConsentedIds, trueConsentedIds);
};

const consentOrVerifyEmailUtils = {
  getPaotangVerifyEmailInfo,
  getConsentOrVerifyEmailButtonCase,
  getConsentDestination,
};
export default consentOrVerifyEmailUtils;
