import type { RootState } from 'global_store/store';
import { IProcedureEntry } from 'interface/fhir.resource';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface procedureState {
  procedureExpansion: boolean[];
  procedureList: IProcedureEntry[];
  procedureOffset: number;
  expansion: any;
}

const initialState: procedureState = {
  procedureExpansion: [],
  procedureList: [],
  procedureOffset: 0,
  expansion: [],
};

export const procedureSlice = createSlice({
  name: 'procedure',
  initialState,
  reducers: {
    clearProcedureSlice: (state) => {
      // Reset state to initial state or any other desired state
      Object.assign(state, initialState);
    },
    setProcedureExpansion: (state, action: PayloadAction<any | null>) => {
      state.procedureExpansion = action.payload;
    },
    setProcedureList: (state, action: PayloadAction<any | null>) => {
      state.procedureList = action.payload;
    },
    setProcedureOffset: (state, action: PayloadAction<any | null>) => {
      state.procedureOffset = action.payload;
    },
    setExpansion: (state, action: PayloadAction<any | null>) => {
      state.expansion = action.payload;
    },
  },
});

export const {
  clearProcedureSlice,
  setProcedureExpansion,
  setProcedureList,
  setProcedureOffset,
  setExpansion,
} = procedureSlice.actions;

export const selectProcedureExpansion = (state: RootState) => state.procedure.procedureExpansion;
export const selectProcedureList = (state: RootState) => state.procedure.procedureList;
export const selectProcedureOffset = (state: RootState) => state.procedure.procedureOffset;

export default procedureSlice.reducer;
