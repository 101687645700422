import React, { useState } from 'react';
import GenericInput, { GenericInputProps } from 'components/Input/GenericInput';
import { IGetGrantTokenForm } from 'interface/thirdParty';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { getGrantToken } from 'services/third-party';
import { sampleGetGrantTokenRequestBody } from 'variables/third-party.data';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button, Container, Grid, List, ListItem, Typography } from '@mui/material';
import { Stack } from '@mui/system';

const schema = yup.object().shape({
  user_role: yup.string().required(),
  active_mdp: yup.string().required(),
  user_info: yup.object().shape({
    certificate_id: yup.string().required(),
    firstname: yup.string(),
    lastname: yup.string(),
    emergency: yup.boolean(),
    department: yup.string(),
    user_type: yup.string(),
  }),
  patient_cid: yup.string().min(13).required(),
});

const formDefaultValue = {
  ...sampleGetGrantTokenRequestBody,
  api_key: 'xxxx',
};

function ThirdPartyFrontend() {
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit } = useForm<IGetGrantTokenForm>({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValue,
  });
  const onSubmit: SubmitHandler<IGetGrantTokenForm> = async (data) => {
    setIsLoading(true);
    console.log(data);
    // 1. call get grant token with api-key in header and form value in request body. get grant token and url for redirect in response.
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { api_key, ...rest } = data;
    const res = await getGrantToken(rest, api_key);
    console.debug('🚀 | constonSubmit:SubmitHandler<IGetGrantTokenForm>= | res', res);
    setIsLoading(false);
    if (res.success === false) {
      setErrorText(res.data?.detail);
    } else {
      // 2. use the grant token in the response to open healthlink url
      window.location.href =
        process.env.NODE_ENV === 'production'
          ? res.data.url
          : `${window.location.origin}/single-use/load?grant_token=${res.data.grant_token}`;
    }
  };
  const onInvalid = (e: any) => {
    console.log('Invalid Form');
  };
  return (
    <Container
      sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Stack>
        <Typography variant="h2" sx={{ marginBottom: '3rem' }}>
          {' '}
          ThirdPartyFrontend
        </Typography>
        {errorText && (
          <Typography variant="body2" color="error" sx={{ marginBottom: '1rem' }}>
            {errorText}
          </Typography>
        )}

        <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputList
                listHeader="api_key (third-party backend will have it)"
                control={control}
                inputDefs={[{ name: 'api_key', required: true }]}
              />
            </Grid>
            <Grid item xs={6}>
              <InputList
                listHeader="required"
                control={control}
                inputDefs={[
                  {
                    name: 'patient_cid',
                    defaultValue: formDefaultValue.patient_cid,
                    required: true,
                  },
                  { name: 'user_role', defaultValue: formDefaultValue.user_role, required: true },
                  { name: 'active_mdp', defaultValue: formDefaultValue.active_mdp, required: true },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <InputList
                listHeader="user_info"
                control={control}
                inputDefs={[
                  {
                    name: 'user_info.certificate_id',
                    defaultValue: formDefaultValue.user_info.certificate_id,
                    required: true,
                  },
                  {
                    name: 'user_info.firstname',
                    defaultValue: formDefaultValue.user_info.firstname,
                  },
                  {
                    name: 'user_info.lastname',
                    defaultValue: formDefaultValue.user_info.lastname,
                  },
                  {
                    name: 'user_info.emergency',
                    variant: 'switch',
                    defaultValue: formDefaultValue.user_info.emergency,
                  },
                  {
                    name: 'user_info.department',
                    defaultValue: formDefaultValue.user_info.department,
                  },
                  {
                    name: 'user_info.user_type',
                    defaultValue: formDefaultValue.user_info.user_type,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <InputList
                listHeader="view"
                control={control}
                inputDefs={[
                  {
                    name: 'view',
                    required: true,
                    defaultValue: formDefaultValue.view,
                  },
                ]}
              />
            </Grid>
          </Grid>

          <Button type="submit" disabled={isLoading}>
            เปิด HealthLink{' '}
          </Button>
        </form>
      </Stack>
    </Container>
  );
}

function InputList({
  listHeader,
  control,
  inputDefs,
}: {
  listHeader: string;
  control: Control<IGetGrantTokenForm>;
  inputDefs: {
    name: string;
    defaultValue?: GenericInputProps['defaultValue'];
    required?: boolean;
    variant?: GenericInputProps['variant'];
  }[];
}) {
  return (
    <Stack>
      <Typography variant="h6">{listHeader}</Typography>
      <List>
        {inputDefs.map((item) => (
          <ListItem key={item.name}>
            <GenericInput
              key={item.name}
              control={control}
              name={item.name}
              label={item.name}
              defaultValue={item.defaultValue}
              type="text"
              variant={item.variant ?? 'generic'}
              muiVariant="standard"
              rules={{}}
              required={item.required ?? false}
              // sxProps={{ width: '100%' }}
            />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}

export default ThirdPartyFrontend;
