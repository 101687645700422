import type { RootState } from 'global_store/store';
import { ConsentDestination, ConsentOrVerifyEmailButtonCase } from 'utils/consentOrVerifyEmail';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConsentOrVerifyEmailState {
  disableRequestConsentButton: boolean;
  disableRefreshConsentButton: boolean;
  disableSendVerifyEmailButton: boolean;
  consentOrVerifyEmailButtonCase: ConsentOrVerifyEmailButtonCase | null;
  consentDestination: ConsentDestination | null;
  isLoading: boolean;
}

const initialState: ConsentOrVerifyEmailState = {
  disableRequestConsentButton: false,
  disableRefreshConsentButton: false,
  disableSendVerifyEmailButton: false,
  consentOrVerifyEmailButtonCase: null,
  consentDestination: null,
  isLoading: false,
};

export const consentOrVerifyEmailSlice = createSlice({
  name: 'consentOrVerifyEmail',
  initialState,
  reducers: {
    setDisableRequestConsentButton: (
      state,
      action: PayloadAction<ConsentOrVerifyEmailState['disableRequestConsentButton']>,
    ) => {
      state.disableRequestConsentButton = action.payload;
    },
    setDisableRefreshConsentButton: (
      state,
      action: PayloadAction<ConsentOrVerifyEmailState['disableRefreshConsentButton']>,
    ) => {
      state.disableRefreshConsentButton = action.payload;
    },
    setDisableSendVerifyEmailButton: (
      state,
      action: PayloadAction<ConsentOrVerifyEmailState['disableSendVerifyEmailButton']>,
    ) => {
      state.disableSendVerifyEmailButton = action.payload;
    },
    setConsentInfo: (
      state,
      action: PayloadAction<{
        consentOrVerifyEmailButtonCase: ConsentOrVerifyEmailState['consentOrVerifyEmailButtonCase'];
        consentDestination: ConsentOrVerifyEmailState['consentDestination'];
      }>,
    ) => {
      state.consentOrVerifyEmailButtonCase = action.payload.consentOrVerifyEmailButtonCase;
      state.consentDestination = action.payload.consentDestination;
    },
    setIsLoading: (state, action: PayloadAction<ConsentOrVerifyEmailState['isLoading']>) => {
      state.isLoading = action.payload;
    },
    clearConsentOrVerifyEmailSlice: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setDisableRequestConsentButton,
  setDisableRefreshConsentButton,
  setDisableSendVerifyEmailButton,
  setConsentInfo,
  setIsLoading,
  clearConsentOrVerifyEmailSlice,
} = consentOrVerifyEmailSlice.actions;

export default consentOrVerifyEmailSlice.reducer;
