import {
  setAccessToken,
  setAccessTokenAndSessionExpiredTime,
  setJmgntWsToken,
  setUser,
} from 'global_store/features/authen/authenSlice';
import { MessageState } from 'global_store/features/message/messageSlice';
import { useAppDispatch } from 'global_store/hooks';
import { useNavigate } from 'react-router-dom';
import { requestLogout } from 'services/authen';

import Typography from '@mui/material/Typography';

import BaseDialog from './Base/BaseDialog';

interface ErrorMessageDialogProps {
  open: boolean;
  onClose: () => void;
  errorMessage: MessageState['errorMessage'];
}

function ErrorMessageDialog({ open, onClose, errorMessage }: ErrorMessageDialogProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const textError = {
    500: {
      title: 'ระบบฯ มีปัญหาขัดข้อง',
      text: 'โปรดติดต่อทีมงาน Health Link ที่\nemail: gbdi-hie@depa.or.th\nโทร. 02 026 2333 ต่อ 3456',
    },
  };

  const { title, text } = ((): { title: string; text: string } => {
    if (!errorMessage) return { title: 'เกิดข้อผิดพลาด', text: 'Error!' };

    switch (typeof errorMessage) {
      case 'string':
      case 'number':
        return {
          title: textError[errorMessage]?.title ?? 'เกิดข้อผิดพลาด',
          text: textError[errorMessage]?.text ?? `${errorMessage}`,
        };
      default:
        return {
          title: errorMessage?.title ?? textError[errorMessage.code]?.title ?? 'เกิดข้อผิดพลาด',
          text: errorMessage?.text ?? textError[errorMessage.code]?.text ?? `${errorMessage}`,
        };
    }
  })();

  const type =
    errorMessage && typeof errorMessage === 'object' ? errorMessage.type || 'error' : 'error';

  const handleClose = async () => {
    onClose();
    if (errorMessage && typeof errorMessage === 'object' && errorMessage.logoutOnConfirm) {
      requestLogout();
      dispatch(setUser(null));
      // dispatch(setAccessToken(null));
      dispatch(setAccessTokenAndSessionExpiredTime({ accessToken: null }));
      dispatch(setJmgntWsToken(null));
      navigate('/page/login');
    }
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      titleColor={type}
      title={title}
      maxWidth="md"
      dialogPaperSx={{
        zIndex: 2,
      }}
      withOkButton
      disableCloseOnClickBackdrop
    >
      <Typography
        sx={{
          justifyContent: 'center',
          alignContent: 'center',
          textAlign: 'center',
          whiteSpace: 'pre-line',
          mt: '12px',
          fontSize: '20px !important',
        }}
      >
        {text}
      </Typography>
    </BaseDialog>
  );
}

export default ErrorMessageDialog;
