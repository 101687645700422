import { IFHIRDataInfo } from 'interface';

export const samplePatientDemographic = {
  patient_id: 1,
  cid: '1234567891011',
  app_list: 'KTB,DOPA',
  title: 'นาย',
  firstname: 'ประสิทธิ์',
  lastname: 'โอสถสภา',
  dob: '2533-07-26T00:00:00',
  gender: 'Male',
  mobile: '0111111111',
  email: 'sample@mail.com',
  is_email_verified: false,
  is_active: true,
  contact_fname: 'ประสาท',
  contact_lname: 'โอสถสภา',
  contact_tel: '00111111112',
};

export const samplePatientFhirDataInfo: IFHIRDataInfo = {
  total: 300,
  page: 3,
  size: 50,
  list_data: [
    {
      mdp9_id: '001234500',
      num_visit: 1,
      allergy_intolerance: 1,
      condition: 1,
      diagnostic_report: 1,
      diagnostic_report_media: 1,
      document_reference: 1,
      encouter: 1,
      immunization: 1,
      medication: 1,
      medication_statement: 1,
      observation: 1,
      procedure: 1,
      last_updated: '2021-06-30T04:59:13',
      last_force_update: '2021-06-30T04:59:13',
      last_realtime_update: '2021-06-30T04:59:13',
      last_archive_update: '2021-06-30T04:59:13',
      last_delete_update: '2021-06-30T04:59:13',
      last_composition_update: '2021-06-30T04:59:13',
      last_batch_update: '2021-06-30T04:59:13',
      created_at: '2021-06-30T04:59:13',
      modified_at: '2021-06-30T05:00:21',
      mdp_name: 'สถานพยาบาลสมมติ',
    },
  ],
};
