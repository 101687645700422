import React, { useRef } from 'react';
import ExtendSessionDialog from 'components/Dialog/ExtendSessionDialog';
import SessionExpiredDialog from 'components/Dialog/SessionExpiredDialog';
import { showSessionTimer } from 'config';
import {
  selectAccessToken,
  selectSessionExpiredTime,
  selectShowSessionExpiredModal,
  setShowSessionExpiredModal,
} from 'global_store/features/authen/authenSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import { decodeAccessToken } from 'utils/authen';

import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'contents',
}));

function SessionTimer(): JSX.Element {
  const accessToken = useAppSelector(selectAccessToken);
  const expiredTime = useAppSelector(selectSessionExpiredTime);
  const showSessionExpiredModal = useAppSelector(selectShowSessionExpiredModal);
  const dispatch = useAppDispatch();
  const [remainingTime, setRemainingTime] = React.useState<number>(0);
  const [openExtendSessionDialog, setOpenExtendSessionDialog] = React.useState<boolean>(false);
  const timerIdRef = useRef<null | NodeJS.Timeout>(null);
  const expiredTimeRef = useRef(expiredTime);
  expiredTimeRef.current = expiredTime;
  const showSessionExpiredModalRef = useRef(showSessionExpiredModal);
  showSessionExpiredModalRef.current = showSessionExpiredModal;

  const renderTime = () => {
    const tokenPayload = decodeAccessToken(accessToken);
    if (tokenPayload) {
      return (
        <h1>
          Remaining minutes : {Math.floor(remainingTime / 60)} mins {remainingTime % 60} secs{' '}
        </h1>
      );
    }
    return <h1>Null payload</h1>;
  };

  const tick = () => {
    const newRemainingTime = expiredTimeRef.current - Math.floor(Date.now().valueOf() / 1000);
    // console.debug('🚀 sessionTimer | tick', { newRemainingTime, accessToken });
    if (newRemainingTime === 60) {
      setOpenExtendSessionDialog(true);
    } else if (newRemainingTime <= 0) {
      if (openExtendSessionDialog) {
        setOpenExtendSessionDialog(false);
      }
      if (!showSessionExpiredModal) {
        // console.debug(
        //   '🚀 sessionTimer | setShowSessionExpiredModal true. timerIdRef.current',
        //   timerIdRef.current,
        // );
        dispatch(setShowSessionExpiredModal(true));
        if (timerIdRef.current) clearInterval(timerIdRef.current);
      }
      if (remainingTime !== 0) {
        setRemainingTime(0);
      }
      return;
    } else if (newRemainingTime > 60 && showSessionExpiredModalRef.current) {
      dispatch(setShowSessionExpiredModal(false));
    }
    setRemainingTime(newRemainingTime);
  };

  React.useEffect(() => {
    // console.debug('🚀 sessionTimer | set interval ref.', { expiredTime, accessToken });
    if (accessToken) timerIdRef.current = setInterval(() => tick(), 1000);
    return () => {
      if (timerIdRef.current) clearInterval(timerIdRef.current);
    };
  }, [accessToken]);

  return (
    <StyledContainer>
      {showSessionTimer && <div>{renderTime()}</div>}
      <SessionExpiredDialog />
      <ExtendSessionDialog open={openExtendSessionDialog} setOpen={setOpenExtendSessionDialog} />
    </StyledContainer>
  );
}

export default SessionTimer;
