import React from 'react';
import LoadingBackdrop from 'components/Backdrop/LoadingBackdrop';
import GenderIcon from 'components/GenderIcon/GenderIcon';
import { GenderValues } from 'config';
import { selectUser } from 'global_store/features/authen/authenSlice';
import { setErrorMessage, setShowErrorDialog } from 'global_store/features/message/messageSlice';
import {
  selectSearchKeyword,
  selectSearchResult,
  setSearchKeyword,
  setSearchResult,
} from 'global_store/features/search/searchSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import {
  ISearchHistory,
  ISearchHistoryRecord,
  ISearchResult,
  ISearchResultRecord,
} from 'interface/logging';
import { FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getPatientViewHistory } from 'services/logging';
import { displayDate, formatCid } from 'utils';
import { clearFetchedData } from 'utils/patient';

import { useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PREFIX = 'PatientSearch';

const classes = {
  root: `${PREFIX}-root`,
  rootContainer: `${PREFIX}-rootContainer`,
  leftContent: `${PREFIX}-leftContent`,
  centerContent: `${PREFIX}-centerContent`,
  rightContent: `${PREFIX}-rightContent`,
  margin: `${PREFIX}-margin`,
  section: `${PREFIX}-section`,
  lightGreyText: `${PREFIX}-lightGreyText`,
  grayText: `${PREFIX}-grayText`,
  textMargin: `${PREFIX}-textMargin`,
  clickableCard: `${PREFIX}-clickableCard`,
  nonClickableCard: `${PREFIX}-nonClickableCard`,
  patientCard: `${PREFIX}-patientCard`,
  patientCardPadding: `${PREFIX}-patientCardPadding`,
  enrolledText: `${PREFIX}-enrolledText`,
  withdrawnText: `${PREFIX}-withdrawnText`,
  containerMargin: `${PREFIX}-containerMargin`,
  iconButton: `${PREFIX}-iconButton`,
  centerText: `${PREFIX}-centerText`,
  leftText: `${PREFIX}-leftText`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: '50px',
  },

  [`& .${classes.rootContainer}`]: {
    flexGrow: 1,
  },

  [`& .${classes.leftContent}`]: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
    textAlign: 'center',
  },

  [`& .${classes.centerContent}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },

  [`& .${classes.rightContent}`]: {
    display: 'flex',
    justifyContent: 'right',
    alignContent: 'center',
    textAlign: 'center',
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.section}`]: {
    paddingTop: '50px',
    textAlign: 'center',
  },

  [`& .${classes.lightGreyText}`]: {
    color: '#9B9B9B',
  },

  [`& .${classes.grayText}`]: {
    color: '#6B6B6B',
  },

  [`& .${classes.textMargin}`]: {
    marginTop: '10px',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '10px',
  },

  [`& .${classes.clickableCard}`]: {
    backgroundColor: '#F1FFFB',
  },

  [`& .${classes.nonClickableCard}`]: {
    backgroundColor: '#F4F4F4',
  },

  [`& .${classes.patientCard}`]: {
    alignContent: 'center',
    justifyContent: 'center',
    padding: 0,
  },

  [`& .${classes.patientCardPadding}`]: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },

  [`& .${classes.enrolledText}`]: {
    color: theme.palette.secondary.main,
  },

  [`& .${classes.withdrawnText}`]: {
    color: '#9B9B9B',
  },

  [`& .${classes.containerMargin}`]: {
    // marginLeft: "2rem",
  },

  [`& .${classes.iconButton}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.centerText}`]: {
    alignItems: 'center',
    display: 'flex',
  },

  [`& .${classes.leftText}`]: {
    textAlign: 'left',
    alignItems: 'center',
    display: 'flex',
  },
}));

function PatientSearch(): JSX.Element {
  const dispatch = useAppDispatch();
  const [searchHistoryList, setSearchHistoryList] = React.useState<ISearchHistory | null>();
  const navigate = useNavigate();
  const searchKey = useAppSelector(selectSearchKeyword);
  const searchResult: ISearchResult | null = useAppSelector(selectSearchResult);
  const user = useAppSelector(selectUser);
  const hiddenSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const loadPatientViewHistory = React.useCallback(async () => {
    setIsLoading(true);
    const result = await getPatientViewHistory({
      page: 1,
      pageSize: 5,
    });
    setIsLoading(false);
    if (result.success === true) {
      setSearchHistoryList(result.data);
    } else if (!result.data.errorIsHandled) {
      dispatch(setShowErrorDialog(true));
      dispatch(setErrorMessage(JSON.stringify(result.error)));
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (user) {
      loadPatientViewHistory();
    }
    return () => {
      dispatch(setSearchKeyword(''));
      dispatch(setSearchResult(null));
    };
  }, [loadPatientViewHistory, dispatch, user]);

  const viewPatientData = (cid: string) => {
    // Clear cached data of previous patient
    clearFetchedData(dispatch);
    navigate('/console/patient/overview', { state: { cid } });
  };

  const renderGenderIcon = (gender: string | null, age: number) => {
    if (!gender) return <GenderIcon iconType="Unknown" />;
    if (GenderValues.male.includes(gender.toUpperCase())) {
      if (age <= 15) {
        return <GenderIcon iconType="Boy" />;
      }
      return <GenderIcon iconType="Male" />;
    }
    if (GenderValues.female.includes(gender.toUpperCase())) {
      if (age <= 15) {
        return <GenderIcon iconType="Girl" />;
      }
      return <GenderIcon iconType="Female" />;
    }
    return <GenderIcon iconType="Unknown" />;
  };

  const renderRecord = (
    record: ISearchHistoryRecord | ISearchResultRecord,
    clickable?: boolean,
  ) => {
    if (!record.cid) {
      return null;
    }
    return (
      <Grid container className={classes.patientCardPadding}>
        <Grid item xs={4} className={classes.leftContent}>
          {hiddenSmDown ? null : renderGenderIcon(record.gender || '', record.age)}
          <Typography
            variant="h6"
            className={`${classes.textMargin} ${classes.leftText}`}
          >{`${record.title} ${record.firstname} ${record.lastname}`}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.leftContent}>
          <Typography variant="h6" className={`${classes.centerText}`}>{`${formatCid(
            record.cid,
          )}`}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.leftContent}>
          <Typography variant="h6" className={`${classes.centerText}`}>
            {record.birthdate
              ? `${displayDate(new Date(record.birthdate), 'dd MMMM yyyy')}`
              : '-- ---- ----'}
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.leftContent}>
          <Typography
            variant="h6"
            className={`${classes.centerText}`}
          >{`${record.age} ปี`}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.leftContent}>
          <Typography
            variant="h6"
            className={`${record.is_active ? classes.enrolledText : classes.withdrawnText} ${
              classes.centerText
            }`}
          >
            {record.is_active ? 'Enrolled' : 'Withdrawn'}
          </Typography>
          {clickable && (
            <Typography className={`${classes.centerText} ${classes.iconButton}`}>
              <FaChevronRight />
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderRecordList = (
    recordList: ISearchHistory | ISearchResult | null | undefined,
    limit?: number,
    clickable?: boolean,
  ) => {
    return recordList?.records.map((record, key) => {
      return (
        <ListItem
          key={key}
          className={`${
            clickable && record.is_active ? classes.clickableCard : classes.nonClickableCard
          } ${classes.patientCard} ${classes.margin}`}
          component={Card}
        >
          {clickable && record.is_active ? (
            <CardActionArea
              onClick={() => {
                viewPatientData(record.cid);
              }}
            >
              {renderRecord(record, clickable)}
            </CardActionArea>
          ) : (
            <>{renderRecord(record)}</>
          )}
        </ListItem>
      );
    });
  };

  return (
    <Root
      className={`${classes.root} ${
        process.env.REACT_APP_ENABLE_HIGHLIGHTING === 'true' ? '' : 'disable-highlighting'
      }`}
    >
      <Container className={classes.rootContainer}>
        {searchKey && (
          <Grid container justifyContent="center" className={classes.section}>
            {searchResult?.total ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h4" className={classes.leftText}>
                    {`พบข้อมูล ${
                      searchResult.records.filter((record) => record.cid).length
                    } รายการ`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <List>
                    <ListItem className={`${classes.margin} ${classes.grayText}`}>
                      <Grid container className={classes.containerMargin}>
                        <Grid item xs={4} className={classes.leftContent}>
                          <Typography variant="h6" className={`${classes.centerText}`}>
                            Patient's Name
                          </Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.leftContent}>
                          <Typography variant="h6" className={`${classes.centerText}`}>
                            Citizen ID
                          </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.leftContent}>
                          <Typography variant="h6" className={`${classes.centerText}`}>
                            Birthdate
                          </Typography>
                        </Grid>
                        <Grid item xs={1} className={classes.leftContent}>
                          <Typography variant="h6" className={`${classes.centerText}`}>
                            Age
                          </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.leftContent}>
                          <Typography variant="h6" className={`${classes.centerText}`}>
                            Status
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {renderRecordList(searchResult, 5, true)}
                  </List>
                </Grid>
              </>
            ) : (
              <Grid item>
                <Typography
                  variant="h4"
                  color="error"
                  className={classes.textMargin}
                >{`ไม่พบ "${searchKey}"`}</Typography>

                <Typography variant="h6" className={`${classes.grayText} ${classes.textMargin}`}>
                  กรุณาตรวจสอบข้อมูลอีกครั้ง หรือพิมพ์คำค้นใหม่
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        <Grid container justifyContent="center" className={classes.section}>
          {!searchHistoryList?.total ? (
            <Grid item>
              <Typography variant="h4" className={classes.textMargin}>{`ประวัติการเรียกดูข้อมูล ${
                searchHistoryList?.total || 0
              } รายการ`}</Typography>

              <Typography variant="h6" className={`${classes.grayText} ${classes.textMargin}`}>
                ไม่พบประวัติการเรียกดูข้อมูล กรุณาพิมพ์ค้นหาข้อมูลผู้ป่วยด้านบน
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.leftText}>
                  {`ประวัติการค้นหา ${
                    searchHistoryList.records.filter((record) => record.cid).length
                  } รายการ`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <List>
                  <ListItem className={`${classes.margin} ${classes.grayText}`}>
                    <Grid container className={classes.containerMargin}>
                      <Grid item xs={4} className={classes.leftContent}>
                        <Typography variant="h6" className={`${classes.centerText}`}>
                          Patient's Name
                        </Typography>
                      </Grid>
                      <Grid item xs={3} className={classes.leftContent}>
                        <Typography variant="h6" className={`${classes.centerText}`}>
                          Citizen ID
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.leftContent}>
                        <Typography variant="h6" className={`${classes.centerText}`}>
                          Birthdate
                        </Typography>
                      </Grid>
                      <Grid item xs={1} className={classes.leftContent}>
                        <Typography variant="h6" className={`${classes.centerText}`}>
                          Age
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.leftContent}>
                        <Typography variant="h6" className={`${classes.centerText}`}>
                          Status
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                  {renderRecordList(searchHistoryList, 5, false)}
                </List>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      <LoadingBackdrop open={isLoading} />
    </Root>
  );
}

export default PatientSearch;
