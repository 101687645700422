import React, { useCallback, useState } from 'react';
import { useTCVersion } from 'config';
import {
  selectRoles,
  selectUser,
  setAccessToken,
  setAccessTokenAndSessionExpiredTime,
  setJmgntWsToken,
  setUser,
} from 'global_store/features/authen/authenSlice';
import { ITermElement, ITermResponse } from 'interface';
import { IUser } from 'interface/authen';
import { useNavigate } from 'react-router-dom';
import { acceptTerms, getTerms } from 'services';
import { getAccessToken, getCurrentUser, requestLogout } from 'services/authen';

import { useAppDispatch, useAppSelector } from '.';

function useInitModalsDialog() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const roles = useAppSelector(selectRoles);
  const [openSelectHospitalDialog, setOpenSelectHospitalDialog] = React.useState<boolean>(false);
  const [openTermsDialog, setOpenTermsDialog] = useState<boolean>(false);
  const [termElements, setTermElements] = useState<ITermElement[] | null>(null);
  const [requiredTermVersion, setRequiredTermVersion] = useState<string | null>(null);

  const handleAcceptTerms = async () => {
    const { success, data, error } = await acceptTerms();
    if (success) {
      const accessTokenRes = await getAccessToken();
      if (!accessTokenRes.success) {
        console.log(accessTokenRes.error);
        return;
      }
      dispatch(setAccessToken(accessTokenRes.data.access_token));
      // Get User Profile
      const profileRes = await getCurrentUser();
      if (profileRes.success) {
        dispatch(setUser(profileRes.data));
      }
      setOpenTermsDialog(false);
    } else {
      console.log(error);
    }
  };

  const handleHospitalDialogConfirm = () => {
    setOpenSelectHospitalDialog(false);
    navigate('/console/home');
  };

  const handleHospitalDialogCancel = () => {
    setOpenSelectHospitalDialog(false);
  };

  const handleLogout = () => {
    requestLogout();
    dispatch(setUser(null));
    dispatch(setAccessTokenAndSessionExpiredTime({ accessToken: null }));
    // dispatch(setAccessToken(null));
    dispatch(setJmgntWsToken(null));
    navigate('/page/login');
  };

  const handleDeclineTerms = async () => {
    setOpenTermsDialog(false);
    handleLogout();
  };

  const isTermValid = useCallback(
    (user: IUser | null): null | boolean => {
      if (!requiredTermVersion) return null;
      if (!user) {
        return null;
      }
      if (useTCVersion) {
        if (!user.tc_version || !user.accept_tc) {
          return false;
        }

        const requiredTermDigits = requiredTermVersion?.split('.');
        const currentTermDigits = user.tc_version?.split('.');
        if (!requiredTermDigits || !currentTermDigits) {
          return false;
        }
        for (let i = 0; i < 3; i++) {
          if (parseInt(requiredTermDigits[i]) > parseInt(currentTermDigits[i])) {
            return false;
          }
        }
        return true;
      }
      return user.accept_tc;
    },
    [requiredTermVersion],
  );

  const requestTerms = React.useCallback(async () => {
    const { success, data } = await getTerms();
    if (success) {
      const { description, required_version } = data as ITermResponse;
      setTermElements(JSON.parse(description));
      setRequiredTermVersion(required_version);
    } else {
      setTermElements(null);
    }
  }, []);

  const isDoctor = () => {
    return roles?.includes('DOCTOR') || false;
  };

  return {
    user,
    roles,
    openTermsDialog,
    setOpenTermsDialog,
    termElements,
    setTermElements,
    requiredTermVersion,
    setRequiredTermVersion,
    handleAcceptTerms,
    handleDeclineTerms,
    isTermValid,
    requestTerms,
    handleHospitalDialogConfirm,
    handleHospitalDialogCancel,
    openSelectHospitalDialog,
    setOpenSelectHospitalDialog,
    isDoctor,
    handleLogout,
  };
}

export default useInitModalsDialog;
