export const commonErrors = [
  {
    code: 400,
    detail: 'Your IP address must be inside medical place IP network.', // if is undefined or null, will just match the code
    display: {
      code: 400, // might remove
      title: 'เครื่องของท่านไม่ได้อยู่ในเครือข่ายที่ระบบฯ กำหนด',
      text: 'โปรดใช้เครือข่ายที่ระบบฯ กำหนดในการเข้าถึงระบบฯ',
      logoutOnConfirm: true,
    },
  },
  {
    code: 401,
    detail: 'Could not validate credentials', // if is undefined or null, will just match the code
    display: {
      code: 401, // might remove
      title: 'ระบบฯมีปัญหากับการยืนยันความถูกต้องของบัญชีของท่าน',
      text: 'กรุณาลองอีกครั้ง',
    },
  },
  {
    code: 403,
    detail: 'You are not currently in your working hours', // if is undefined or null, will just match the code
    display: {
      code: 403, // might remove
      title: 'ขออภัยท่านไม่สามารถเข้าใช้งานได้',
      text: 'เนื่องจากอยู่นอกเวลาทำการที่สถานพยาบาลมอบหมายให้',
      logoutOnConfirm: true,
    },
  },
  {
    code: 422,
    detail: 'The token has invalid single device login token', // if is undefined or null, will just match the code
    display: {
      code: 422, // might remove
      title: 'บัญชีผู้ใช้งานนี้สามารถใช้งานได้เพียงหน้าจอเดียวเท่านั้น',
      text: 'กรุณาปิดหน้าจออื่นที่ใช้งานบัญชีนี้',
      logoutOnConfirm: true,
    },
  },
  {
    code: 500,
    detail: null, // if is undefined or null, will just match the code
    display: {
      code: 500, // might remove
      title: 'ระบบฯ มีปัญหาขัดข้อง',
      text: 'โปรดติดต่อทีมงาน Health Link ที่\nemail: gbdi-hie@depa.or.th\nโทร. 02 026 2333 ต่อ 3456',
    },
  },
  {
    code: 429,
    detail: 'API rate limit exceeded', // if is undefined or null, will just match the code
    display: {
      code: 429, // might remove
      title: 'ระบบฯ ถูกเรียกใช้งานมากเกินไป',
      text: 'กรุณารอสักครู่ แล้วลองอีกครั้ง',
    },
  },
];
