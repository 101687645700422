import { RunForceUpdateResponse } from 'interface/schema';
import { IFormattedError } from 'interface/service';
import { getDefaultRequestHeaders } from 'utils/authen';

import api from './api';

export const getWsToken = async (): Promise<
  | {
      success: true;
      data: { ws_token: string }; // TODO: move data type to interface file
      error: null;
    }
  | IFormattedError
> => {
  try {
    console.log('getting auth wstoken');
    const res = await api.get<{ ws_token: string }>('/api/patient/client-mgmt-ws-token/get', {
      headers: await getDefaultRequestHeaders(true),
    });
    return { success: true, data: res.data, error: null };
  } catch (e: any) {
    console.log(e.response);
    return { success: false, data: e.response.data, error: e.response.status };
  }

  // // mock data
  // return {
  //   success: true,
  //   data: {
  //     wsToken: 'mocked_ws_token', // NOTE: if there is no job, no token is generated
  //   },
  //   error: null,
  // };
};

export const createJob = async (
  cid: string,
  mdp9Ids: string[],
): Promise<
  | {
      success: true;
      data: RunForceUpdateResponse;
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.post<RunForceUpdateResponse>(
      '/api/patient/fhir-data/force-update',
      {
        cid,
        mdp9_ids: mdp9Ids,
      },
      {
        headers: await getDefaultRequestHeaders(true),
      },
    );
    return { success: true, data: res.data /* could be null TODO: handle */, error: null };
  } catch (e: any) {
    console.log(e.response);
    return { success: false, data: e.response.data, error: e.response.status };
  }

  // // mock data
  // return {
  //   success: true,
  //   data: {
  //     wsToken: 'mocked_ws_token',
  //     jobDetail: mdp9Ids.map((mdp9Id, index) => ({
  //       mdp9_id: mdp9Id,
  //       job_id: index,
  //       message: 'mocked message',
  //     })),
  //   },
  //   error: null,
  // };
};
