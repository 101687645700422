import { hosAdminConfig } from 'config';
import { USER_ROUTE_PREFIX, USER_TAB } from 'config/hospital-admin';

import BaseAdminManagementPage from '../BaseAdmin/BaseAdminManagementPage';

function UserManagement(): JSX.Element {
  return (
    <BaseAdminManagementPage
      defaultActiveTab="profile"
      routePrefix={USER_ROUTE_PREFIX}
      tabList={USER_TAB}
      filterTabRecord={(tabRecord) => {
        switch (tabRecord.tabKey) {
          case 'contact':
            return hosAdminConfig.showPageInDEVOnly;
          default:
            return true;
        }
      }}
    />
  );
}

export default UserManagement;
