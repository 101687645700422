import React from 'react';

import Card from '@mui/material/Card';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: any;
}

function TabPanel({ children, value, index, className, ...other }: TabPanelProps): JSX.Element {
  return <div>{value === index && <Card className={className}>{children}</Card>}</div>;
}

export default TabPanel;
