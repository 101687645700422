import type { RootState } from 'global_store/store';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IGlobalLoadingState {
  isLoading: boolean;
}

const initialState: IGlobalLoadingState = {
  isLoading: false,
};

export const globalLoadingSlice = createSlice({
  name: 'globalLoading',
  initialState,
  reducers: {
    setGlobalIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setGlobalIsLoading } = globalLoadingSlice.actions;

export const selectIsGlobalLoading = (state: RootState) => state.globalLoading.isLoading;

export default globalLoadingSlice.reducer;
