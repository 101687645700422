import { Suspense, useMemo } from 'react';
import ErrorMessageDialog from 'components/Dialog/ErrorMessageDialog';
import SuccessMessageDialog from 'components/Dialog/SuccessMessageDialog';
import ConsoleFooter from 'components/Footer/ConsoleFooter';
import BaseNavbar from 'components/Navbar/BaseNavbar';
// import ConsoleNavbar from 'components/Navbar/_to_delete_after_test/OldConsoleNavbar';
import SessionTimer from 'components/Timer/SessionTimer';
import { selectRoles } from 'global_store/features/authen/authenSlice';
import { selectIsGlobalLoading } from 'global_store/features/global-loading/globalLoadingSlice';
import {
  selectErrorMessage,
  selectShowErrorDialog,
  selectShowSuccessDialog,
  selectSuccessMessage,
  setErrorMessage,
  setShowErrorDialog,
  setShowSuccessDialog,
  setSuccessMessage,
} from 'global_store/features/message/messageSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import { Outlet, useLocation } from 'react-router-dom';
import routes from 'routes';
import { canRolesAccessCurrentPage, getPageProperties } from 'utils/layout';

import { Box, LinearProgress } from '@mui/material';

function ConsoleLayout(): JSX.Element {
  const dispatch = useAppDispatch();
  const showErrorDialog = useAppSelector(selectShowErrorDialog);
  const errorMessage = useAppSelector(selectErrorMessage);
  const showSuccessDialog = useAppSelector(selectShowSuccessDialog);
  const successMessage = useAppSelector(selectSuccessMessage);
  const location = useLocation();
  const roles = useAppSelector(selectRoles);
  const userRoles = useAppSelector(selectRoles);
  const isGlobalLoading = useAppSelector(selectIsGlobalLoading);

  const expandSearchbar = useMemo(() => {
    const rolesHaveAccess = canRolesAccessCurrentPage(routes, location?.pathname, roles);
    if (!rolesHaveAccess) return false;
    return getPageProperties(routes, location.pathname)?.expandSearchbar ?? false;
  }, [routes, location?.pathname, roles]);

  const handleCloseErrorDialog = () => {
    dispatch(setShowErrorDialog(false));
    dispatch(setErrorMessage(null));
  };

  const handleCloseSuccessDialog = () => {
    dispatch(setShowSuccessDialog(false));
    dispatch(setSuccessMessage(null));
  };

  const onSinglePatientMode = useMemo(
    () => roles?.includes('DOCTOR_SINGLE_PATIENT') ?? false,
    [roles],
  );

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          height: '100vh',
          // overflow: 'hidden',
        },
      })}
    >
      {isGlobalLoading && <LinearProgress />}
      {/* <SessionTimer /> */}
      <ErrorMessageDialog
        open={showErrorDialog}
        onClose={handleCloseErrorDialog}
        errorMessage={errorMessage}
      />
      <SuccessMessageDialog
        open={showSuccessDialog}
        onClose={handleCloseSuccessDialog}
        message={successMessage}
      />
      {/* <ConsoleNavbar expandSearchbar={getExpandSearchbar()} /> */}
      <BaseNavbar
        logoNavigateTo={onSinglePatientMode ? '/console/patient/overview' : '/console/home'}
        expandSearchbar={expandSearchbar}
        enableEvaluation
        enableHospitalSelection={!onSinglePatientMode}
        showOnlyActiveHospitalName={onSinglePatientMode}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
        <ConsoleFooter />
      </Suspense>
    </Box>
  );
}

export default ConsoleLayout;
