import { Suspense, useMemo } from 'react';
import { selectSelectedHospitalIsExchanger } from 'global_store/features/hospital/hospitalSlice';
import { useAppSelector } from 'global_store/hooks';
import { ITabMeta } from 'interface';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { sendEvent } from 'utils/ga4';

import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';

const PREFIX = 'BaseAdminManagementPage';

const classes = {
  root: `${PREFIX}-root`,
  bodyContainer: `${PREFIX}-bodyContainer`,
  outletContainer: `${PREFIX}-outletContainer`,
  tabContainer: `${PREFIX}-tabContainer`,
  tabButton: `${PREFIX}-tabButton`,
  tabContentCard: `${PREFIX}-tabContentCard`,
  icon: `${PREFIX}-icon`,
  iconAndText: `${PREFIX}-iconAndText`,
  subMenuIconAndText: `${PREFIX}-subMenuIconAndText`,
  subMenuIcon: `${PREFIX}-subMenuIcon`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonActive: `${PREFIX}-menuButtonActive`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: '#F2F6F5',
  },

  [`& .${classes.bodyContainer}`]: {
    marginTop: 0,
  },

  [`& .${classes.outletContainer}`]: {
    padding: '20px',
  },

  [`& .${classes.tabContainer}`]: {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 'calc(100vh - 239px)',
  },

  [`& .${classes.tabButton}`]: {
    alignContent: 'center',
    textAlign: 'center',
    justifyContent: 'right',
  },

  [`& .${classes.tabContentCard}`]: {
    boxShadow: '1px 0px 1px 0px #7fb7a452 inset',
  },
  [`& .${classes.icon}`]: {
    fontSize: '28px',
    minWidth: '28px',
    fill: '#22D99B',
  },
  [`& .${classes.iconAndText}`]: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '20px 12px',
    columnGap: '12px',
    height: '63px',
  },
  [`& .${classes.subMenuIconAndText}`]: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '28px 28px',
    columnGap: '12px',
    height: '79px',
  },
  [`& .${classes.subMenuIcon}`]: {
    fontSize: '28px',
    minWidth: '28px',
    color: theme.palette.text.primary,
  },
  [`& .${classes.menuButton}`]: {
    paddingLeft: '40px',
    paddingRight: '0px',
  },
  [`& .${classes.menuButtonActive}`]: {
    background: '#F1FFFB',
  },
}));
interface BaseAdminManagementPageProps {
  defaultActiveTab: string;
  tabList: ITabMeta[];
  routePrefix: string;
  filterTabRecord?: (tabRecord: ITabMeta) => boolean; // true means use it
}
function BaseAdminManagementPage({
  defaultActiveTab,
  tabList,
  routePrefix,
  filterTabRecord,
}: BaseAdminManagementPageProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const prefix = routePrefix ? `${routePrefix}/` : '';
  const isExchanger = useAppSelector(selectSelectedHospitalIsExchanger);

  const checkActiveSubMenu = (pathName: string[]) => {
    return pathName.some((item) => item === location.pathname);
  };

  const generateTabButtons = (tabList: ITabMeta[], isSubMenu?: boolean) => {
    return tabList
      .filter((tabRecord) => filterTabRecord?.(tabRecord) ?? true)
      .map((tabRecord) => {
        const isActive = checkActiveSubMenu([`${prefix}${tabRecord.tabKey}`]);
        if (tabRecord.subMenu?.length)
          return (
            <div key={`btn-${tabRecord.tabKey}`}>
              <div className={classes.subMenuIconAndText}>
                <tabRecord.iconComponent className={classes.icon} />
                <Typography whiteSpace="pre-line" variant="h6" lineHeight={1.35}>
                  {tabRecord.tabName}
                </Typography>
              </div>
              {generateTabButtons(tabRecord.subMenu, true)}
            </div>
          );

        const { tabKey } = tabRecord;
        if (!isExchanger) {
          switch (tabKey) {
            case 'api-key':
            case 'user-updated-log':
            case 'exchange-zone-config':
            case 'user-access-log':
              return null;
            default:
              break;
          }
        }

        return (
          <ListItemButton
            selected={isActive}
            key={`btn-${tabRecord.tabKey}`}
            onClick={() => {
              if (tabKey === 'notice') sendEvent('notification_from_management');
              navigate(tabRecord.path ? tabRecord.path : `${prefix}${tabRecord.tabKey}`);
            }}
            className={isSubMenu ? classes.menuButton : ''}
            classes={{ selected: classes.menuButtonActive }}
          >
            <div className={classes.iconAndText}>
              <tabRecord.iconComponent className={isSubMenu ? classes.subMenuIcon : classes.icon} />
              <Typography whiteSpace="pre-line" variant="h6" lineHeight={1.35}>
                {tabRecord.tabName}
              </Typography>
            </div>
          </ListItemButton>
        );
      });
  };

  const renderTabButtons = useMemo(() => {
    return generateTabButtons(tabList);
  }, [tabList, location, isExchanger]);

  return (
    <StyledGrid container className={classes.root}>
      <Grid item xs={12}>
        <Card elevation={0} className={classes.bodyContainer}>
          <Stack direction="row">
            <Stack sx={{ width: '22%', maxWidth: '400px', minWidth: '300px' }}>
              <Container className={classes.tabContainer}>
                <List
                  sx={{ width: '100%', bgcolor: 'background.paper', padding: 0 }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  {renderTabButtons}
                </List>
              </Container>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack sx={{ width: '78%' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <div className={classes.outletContainer}>
                  <Outlet />
                </div>
              </Suspense>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </StyledGrid>
  );
}

export default BaseAdminManagementPage;
