import { accessTokenAge, MAX_LOGIN_ATTEMPTS, sessionLength, useTCVersion } from 'config';
import { RootState } from 'global_store/store';
import { IConsentStatus } from 'interface';
import { IUser } from 'interface/authen';
import { INoticeRecord } from 'interface/doctor.management';
import { sampleNoticeList } from 'variables/user.sample.data';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthenState {
  user: IUser | null;
  accessToken: string | null;
  roles: string[] | null;
  noticeList: INoticeRecord[] | null;
  consentStatus: IConsentStatus | null;
  showSessionExpiredModal: boolean;
  sessionExpiredTime: number;

  jmgntWsToken: string | null;
  jmgntWsConnected: boolean;
}

const initialState: AuthenState = {
  user: null,
  accessToken: null,
  roles: null,
  noticeList: sampleNoticeList,
  consentStatus: null,
  showSessionExpiredModal: false,
  sessionExpiredTime: 0,

  jmgntWsToken: null,
  jmgntWsConnected: false,
};

export const authenSlice = createSlice({
  name: 'authen',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser | null>) => {
      state.user = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setSessionExpiredTime: (state, action: PayloadAction<number>) => {
      state.sessionExpiredTime = action.payload;
    },
    setAccessTokenAndSessionExpiredTime: (
      state,
      action: PayloadAction<{ accessToken: null } | { accessToken: string; decodedToken: any }>,
    ) => {
      // if decoded to token is passed then won't calculate
      const { accessToken } = action.payload;
      let updatedSessionExpiredTime = 0;
      if (accessToken) {
        const { decodedToken } = action.payload as any;
        if (!decodedToken) throw Error('decodedToken required if accessToken is not null');
        updatedSessionExpiredTime = decodedToken.exp + (sessionLength - accessTokenAge);
      }
      state.accessToken = accessToken;
      state.sessionExpiredTime = updatedSessionExpiredTime;
    },
    setRoles: (state, action: PayloadAction<string[] | null>) => {
      state.roles = action.payload;
    },
    setNoticeList: (state, action: PayloadAction<INoticeRecord[] | null>) => {
      state.noticeList = action.payload;
    },
    setConsentStatus: (state, action: PayloadAction<IConsentStatus | null>) => {
      state.consentStatus = action.payload;
    },
    setShowSessionExpiredModal: (state, action: PayloadAction<boolean>) => {
      state.showSessionExpiredModal = action.payload;
    },
    setJmgntWsToken: (state, action: PayloadAction<string | null>) => {
      state.jmgntWsToken = action.payload;
    },
    setJmgntWsConnected: (state, action: PayloadAction<boolean>) => {
      state.jmgntWsConnected = action.payload;
    },
  },
});

export const {
  setUser,
  setAccessToken,
  setRoles,
  setNoticeList,
  setConsentStatus,
  setShowSessionExpiredModal,
  setSessionExpiredTime,
  setJmgntWsToken,
  setJmgntWsConnected,
  setAccessTokenAndSessionExpiredTime,
} = authenSlice.actions;

export const selectUser = (state: RootState) => state.authen.user;
export const selectAccessToken = (state: RootState) => state.authen.accessToken;
export const selectRoles = (state: RootState) => state.authen.roles;
export const selectNoticeList = (state: RootState) => state.authen.noticeList;
export const selectConsentStatus = (state: RootState) => state.authen.consentStatus;
export const selectShowSessionExpiredModal = (state: RootState) =>
  state.authen.showSessionExpiredModal;
export const selectSessionExpiredTime = (state: RootState) => state.authen.sessionExpiredTime;
export const selectJmgntWsToken = (state: RootState) => state.authen.jmgntWsToken;

export default authenSlice.reducer;
