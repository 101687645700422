import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'global_store/hooks';
import { useInitModalsLayoutContext } from 'layouts/InitModalsContext';
import { useSearchParams } from 'react-router-dom';
import { initializeApp } from 'utils/app';

import { Box, Container } from '@mui/material';

function ThirdPartyLanding() {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { onLoad } = useInitModalsLayoutContext();
  const [failedMessage, setFailedMessage] = useState<null | string>(null);

  const grantToken = useMemo(() => searchParams.get('grant_token'), [searchParams]);

  const callInitializeApp = useCallback(async () => {
    console.debug(
      '🚀 | callInitializeApp | if no grantToken wont proceed | hasGrantToken',
      !!grantToken,
    );
    if (!grantToken) return;
    const success = await initializeApp(
      dispatch,
      grantToken,
      (accessTokenRes) => {
        console.debug('🚀 | callInitializeApp | accessTokenRes:', accessTokenRes);
        let failedMessage_ = 'ไม่สามารถใช้งาน token นี้ได้';
        if (accessTokenRes?.data?.detail === 'You are not currently in your working hours') {
          failedMessage_ = `ขออภัยท่านไม่สามารถเข้าใช้งานได้ เนื่องจากอยู่นอกเวลาทำการที่สถานพยาบาลมอบหมายให้`;
        } else if (accessTokenRes?.data?.detail === 'The token has expired.') {
          failedMessage_ = `ขออภัยท่านไม่สามารถเข้าใช้งานได้ เนื่องจาก token หมดอายุแล้ว`;
        }
        setFailedMessage(failedMessage_);
      } /* NOTE: could pass onGetAccessTokenError callback here */,
    );
  }, [grantToken]);

  useEffect(() => {
    console.debug('🚀 useEffect | onLoad changed');
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    callInitializeApp();
  }, [callInitializeApp]);

  return (
    <Container sx={{ height: '100vh' }}>
      <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
        {failedMessage ?? 'loading...'}
      </Box>
    </Container>
  );
}

export default ThirdPartyLanding;
