import authenReducer from 'global_store/features/authen/authenSlice';
import autoPullingSlice from 'global_store/features/autoPulling/autoPullingSlice';
import consentOrVerifyEmailSlice from 'global_store/features/consentOrVerifyEmail/consentOrVerifyEmailSlice';
import allergySlice from 'global_store/features/fetchData/allergySlice';
import conditionSlice from 'global_store/features/fetchData/conditionSlice';
import immunizationSlice from 'global_store/features/fetchData/immunizationSlice';
import labSlice from 'global_store/features/fetchData/labSlice';
import loadedSlice from 'global_store/features/fetchData/loadedSlice';
import medicationSlice from 'global_store/features/fetchData/medicationSlice';
import overviewSlice from 'global_store/features/fetchData/overviewSlice';
import procedureSlice from 'global_store/features/fetchData/procedureSlice';
import forceUpdateSlice from 'global_store/features/forceUpdate/forceUpdateSlice';
import globalLoadingSlice from 'global_store/features/global-loading/globalLoadingSlice';
import globalModalSlice from 'global_store/features/global-modal/globalModalSlice';
import hospitalSlice from 'global_store/features/hospital/hospitalSlice';
import jobMgntSlice from 'global_store/features/jobMgnt/jobMgntSlice';
import messageSlice from 'global_store/features/message/messageSlice';
import patientSlice from 'global_store/features/patient/patientSlice';
import searchSlice from 'global_store/features/search/searchSlice';

import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    authen: authenReducer,
    hospital: hospitalSlice,
    search: searchSlice,
    patient: patientSlice,
    message: messageSlice,
    globalModal: globalModalSlice,
    globalLoading: globalLoadingSlice,
    overview: overviewSlice,
    allergy: allergySlice,
    immunization: immunizationSlice,
    condition: conditionSlice,
    procedure: procedureSlice,
    medication: medicationSlice,
    lab: labSlice,
    loaded: loadedSlice,
    jobMgnt: jobMgntSlice,
    autoPulling: autoPullingSlice,
    forceUpdate: forceUpdateSlice,
    consentOrVerifyEmail: consentOrVerifyEmailSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
