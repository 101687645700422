import SessionExpiredIcon from 'assets/img/sessionExpired.svg';
import { renewAccessToken } from 'utils/authen';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import BaseDialog from './Base/BaseDialog';

const PREFIX = 'ExtendSessionDialog';

const classes = {
  centerAllContent: `${PREFIX}-centerAllContent`,
  expiredIcon: `${PREFIX}-expiredIcon`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.centerAllContent}`]: {
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  [`& .${classes.expiredIcon}`]: {
    width: '100px',
    height: '100px',
  },
}));

interface ExtendSessionDialogProps {
  open: boolean;
  setOpen: (newState: boolean) => void;
}

function ExtendSessionDialog({ open, setOpen }: ExtendSessionDialogProps): JSX.Element {
  const handleRenewSession = async () => {
    await renewAccessToken();
    setOpen(false);
  };

  return (
    <BaseDialog
      open={open}
      maxWidth="sm"
      handleClose={handleRenewSession}
      withOkButton
      disableCloseOnClickBackdrop
      fullWidth
    >
      <StyledBox>
        <Typography variant="h2" color="error" className={classes.centerAllContent}>
          <img src={SessionExpiredIcon} alt="HIE Logo" className={classes.expiredIcon} />
        </Typography>
        <Typography variant="h2" sx={{ marginTop: '10px' }} className={classes.centerAllContent}>
          ท่านเหลือเวลาอีก 1 นาทีในการใช้งาน
        </Typography>
        <Typography
          variant="h2"
          sx={{ fontSize: '24px', marginTop: '10px' }}
          className={classes.centerAllContent}
          component="p"
        >
          ท่านต้องการใช้งานระบบฯ ต่อหรือไม่
        </Typography>
      </StyledBox>
    </BaseDialog>
  );
}

export default ExtendSessionDialog;
