import { IGetGrantTokenRequestBody } from 'interface/thirdParty';

export const sampleGetGrantTokenRequestBody: IGetGrantTokenRequestBody = {
  user_role: 'DOCTOR',
  active_mdp: '000000000',
  user_info: {
    certificate_id: 'doc_00019',
    firstname: 'สมมุติ',
    lastname: 'ลองเป็นหมอ',
    emergency: false,
    department: 'เวชศาสตร์ครอบครัว',
    user_type: 'แพทย์เฉพาะทาง',
  },
  patient_cid: '0000000000001',
  view: 'summary',
};
