import React from 'react';

import { Container } from '@mui/material';

function Evaluation() {
  return (
    <Container>
      <h1>Evaluation</h1>
    </Container>
  );
}

export default Evaluation;
