import React from 'react';

import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Button)(({ theme }) => ({
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  color: '#FAFAFA',
  // borderColor: '#FAFAFA',
  '&:hover': {
    backgroundImage: `linear-gradient(to left, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  },
  '&:active': {
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    filter: 'brightness(0.6)',
  },
}));

const styles = {
  disabled: {
    backgroundImage: 'none',
    backgroundColor: '#CBCBCB',
    color: '#FFFFFF',
  },
};

interface GenericButtonProps extends ButtonProps {
  fullWidth?: boolean;
  disabled?: boolean;
}

function GenericButton(props: GenericButtonProps): JSX.Element {
  return (
    <Root
      {...props}
      sx={{
        ...(props.disabled ? styles.disabled : {}),
      }}
    />
  );
}

export default GenericButton;
