import type { RootState } from 'global_store/store';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAutoPullingState {
  isPullingServer: boolean;
  isPullingClient: boolean;
  noServerResult: boolean; // not sure if needed
  noClientResult: boolean; // not sure if needed
  serverDataIsLoaded: boolean;
  clientDataIsLoaded: boolean;
}

const initialState: IAutoPullingState = {
  isPullingServer: false,
  isPullingClient: false,
  noServerResult: false,
  noClientResult: false,
  serverDataIsLoaded: false,
  clientDataIsLoaded: false,
};

export const autoPullingSlice = createSlice({
  name: 'autoPulling',
  initialState,
  reducers: {
    clearAutoPullingSlice: (state) => {
      // Reset state to initial state or any other desired state
      Object.assign(state, initialState);
    },
    setIsPullingServer: (state, action: PayloadAction<IAutoPullingState['isPullingServer']>) => {
      state.isPullingServer = action.payload;
    },
    setIsPullingClient: (state, action: PayloadAction<IAutoPullingState['isPullingClient']>) => {
      state.isPullingClient = action.payload;
    },
    setNoServerResult: (state, action: PayloadAction<IAutoPullingState['noServerResult']>) => {
      state.noServerResult = action.payload;
    },
    setNoClientResult: (state, action: PayloadAction<IAutoPullingState['noClientResult']>) => {
      state.noClientResult = action.payload;
    },
    setServerDataIsLoaded: (
      state,
      action: PayloadAction<IAutoPullingState['serverDataIsLoaded']>,
    ) => {
      state.serverDataIsLoaded = action.payload;
    },
    setClientDataIsLoaded: (
      state,
      action: PayloadAction<IAutoPullingState['clientDataIsLoaded']>,
    ) => {
      state.clientDataIsLoaded = action.payload;
    },
  },
});

export const {
  setIsPullingServer,
  setIsPullingClient,
  setNoServerResult,
  setNoClientResult,
  setServerDataIsLoaded,
  setClientDataIsLoaded,
  clearAutoPullingSlice,
} = autoPullingSlice.actions;

export const selectNoServerResult = (state: RootState) => state.autoPulling.noServerResult;
export const selectNoClientResult = (state: RootState) => state.autoPulling.noClientResult;
export const selectServerDataIsLoaded = (state: RootState) => state.autoPulling.serverDataIsLoaded;
export const selectClientDataIsLoaded = (state: RootState) => state.autoPulling.clientDataIsLoaded;

export default autoPullingSlice.reducer;
