import axios, { AxiosError } from 'axios';
import { commonErrors } from 'config/errors';
import { setErrorMessage, setShowErrorDialog } from 'global_store/features/message/messageSlice';
import { store } from 'global_store/store';
import { IErrorResponseData } from 'interface/service';
import { handleGlobalError } from 'utils/errors';

const api = axios.create({
  // baseURL: '', // Replace with your API's base URL
});

const skipGlobalErrorModalRequestUrls = {
  '/api/doctor/link/mdp': true,
  '/api/doctor/check': true,
  '/api/doctor/unlink/mdp': true,
};
api.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error: AxiosError<IErrorResponseData>) => {
    const { response, request } = error;
    if (response) {
      if (typeof error.response.data === 'string') {
        error.response.data = {
          detail: error.response.data,
        };
      }
      if (!skipGlobalErrorModalRequestUrls[response.config.url]) {
        error = handleGlobalError(error, true);
      }
    }
    if (!response) {
      console.error('error that does not have response', error);
    }
    // not sure if should always throw or only throw if haven't matched the common case
    throw error;
  },
);

export default api;
