import { IHospitalResourceStatus, ITermResponse } from 'interface';
import { IFormattedError } from 'interface/service';
import { getDefaultRequestHeaders } from 'utils/authen';
import { mockFlag } from 'utils/mock';
import { sampleTerms } from 'variables/terms.sample.data';

import api from './api';

export const getTerms = async (): Promise<
  | {
      success: true;
      data: ITermResponse;
      error: null;
    }
  | IFormattedError
> => {
  try {
    let resData;
    if (mockFlag) {
      resData = sampleTerms;
    } else {
      const res = await api.get('/api/consent-form/terms-and-conditions', {
        headers: await getDefaultRequestHeaders(true),
      });
      resData = res.data;
    }
    return {
      success: true,
      data: resData,
      error: null,
    };
  } catch (err: any) {
    console.log(err.response);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const acceptTerms = async (): Promise<
  | {
      success: true;
      data: { refresh_token: string };
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.put(
      '/api/user/accept-terms',
      {},
      {
        headers: await getDefaultRequestHeaders(true),
      },
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err: any) {
    console.log(err.response);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const declineTerms = async (): Promise<
  | {
      success: true;
      data: {
        accept_tc: boolean;
      };
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.put(
      '/api/user/withdraw-terms',
      {},
      {
        headers: await getDefaultRequestHeaders(true),
      },
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err: any) {
    console.log(err.response);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const updateProfile = async ({
  title,
  firstname,
  lastname,
  email,
  mobile,
  workTelephone,
}: {
  title: string;
  firstname: string;
  lastname: string;
  email: string;
  mobile: string;
  workTelephone: string;
}): Promise<{ success: true; data: any; error: any } | IFormattedError> => {
  try {
    const res = await api.put(
      '/api/user/user-info',
      {
        title,
        firstname,
        lastname,
        email,
        mobile,
        work_telephone: workTelephone,
      },
      {
        headers: await getDefaultRequestHeaders(true),
      },
    );
    return { success: true, data: res.data, error: null };
  } catch (err: any) {
    console.log(err.response);
    return { success: false, data: err.response.data, error: err.response.status };
  }
};

export const getHospitalResourceStatus = async (
  mdp9idList: string[],
): Promise<
  | {
      success: true;
      data: IHospitalResourceStatus[];
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.post(
      '/api/hospital/hos-resource-status/get',
      {
        list_mdp9_id: mdp9idList,
      },
      {
        headers: await getDefaultRequestHeaders(true),
      },
    );
    return { success: true, data: res.data, error: null };
  } catch (err: any) {
    console.log(err.response);
    return { success: false, data: err.response.data, error: err.response.status };
  }
};
