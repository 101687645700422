import GenericButton from 'components/Button/GenericButton';
import SecondaryButton from 'components/Button/SecondaryButton';

import { Stack } from '@mui/system';

interface DialogFormButtonsProps {
  handleClose: () => void;
  direction?: 'column' | 'row';
  disableConfirm?: boolean;
}

function DialogFormButtons({
  handleClose,
  direction = 'row',
  disableConfirm = false,
}: DialogFormButtonsProps) {
  return (
    <Stack
      direction={direction}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100%',
        marginTop: '16px',
        alignItems: 'center',
      }}
    >
      <SecondaryButton
        sx={{ border: '1px solid #47BDEB' }}
        fullWidth
        onClick={handleClose}
        size="small"
      >
        ยกเลิก
      </SecondaryButton>
      <GenericButton type="submit" fullWidth size="small" disabled={disableConfirm}>
        ตกลง
      </GenericButton>
    </Stack>
  );
}

export default DialogFormButtons;
