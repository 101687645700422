import type { RootState } from 'global_store/store';
import { IMedicationStatementEntry } from 'interface/fhir.resource';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface medicationState {
  medicationExpansion: boolean[];
  medicationList: IMedicationStatementEntry[];
  medicationOffset: number;
  expansion: any;
}

const initialState: medicationState = {
  medicationExpansion: [],
  medicationList: [],
  medicationOffset: 0,
  expansion: [],
};

export const medicationSlice = createSlice({
  name: 'medication',
  initialState,
  reducers: {
    clearMedicationSlice: (state) => {
      // Reset state to initial state or any other desired state
      Object.assign(state, initialState);
    },
    setMedicationExpansion: (state, action: PayloadAction<any | null>) => {
      state.medicationExpansion = action.payload;
    },
    setMedicationList: (state, action: PayloadAction<any | null>) => {
      state.medicationList = action.payload;
    },
    setMedicationOffset: (state, action: PayloadAction<any | null>) => {
      state.medicationOffset = action.payload;
    },
    setExpansion: (state, action: PayloadAction<any | null>) => {
      state.expansion = action.payload;
    },
  },
});

export const {
  clearMedicationSlice,
  setMedicationExpansion,
  setMedicationList,
  setMedicationOffset,
  setExpansion,
} = medicationSlice.actions;

export const selectMedicationExpansion = (state: RootState) => state.medication.medicationExpansion;
export const selectMedicationList = (state: RootState) => state.medication.medicationList;
export const selectMedicationOffset = (state: RootState) => state.medication.medicationOffset;

export default medicationSlice.reducer;
