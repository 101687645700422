import crypto from 'crypto';
import { format } from 'date-fns';
import { ec as EC } from 'elliptic';
import { clearAllergySlice } from 'global_store/features/fetchData/allergySlice';
import { clearConditionSlice } from 'global_store/features/fetchData/conditionSlice';
import { clearImmunizationSlice } from 'global_store/features/fetchData/immunizationSlice';
import { clearLabSlice } from 'global_store/features/fetchData/labSlice';
import { clearLoadedSlice } from 'global_store/features/fetchData/loadedSlice';
import { clearMedicationSlice } from 'global_store/features/fetchData/medicationSlice';
import { clearOverviewSlice } from 'global_store/features/fetchData/overviewSlice';
import { clearProcedureSlice } from 'global_store/features/fetchData/procedureSlice';
import { clearPatientSlice } from 'global_store/features/patient/patientSlice';
import { ICategoryParam, IHospitalConsent, IKeyPair, IPeriodParam } from 'interface';
import { IRequestEntry } from 'interface/fhir.resource';

const ec = new EC('curve25519-weier');
const symmetricAlgorithm = 'aes-256-gcm';
const macBitSize = 128;

export const generateKeyPair = (): IKeyPair => {
  const ecKeypair = ec.genKeyPair();
  const keypair: IKeyPair = {
    privateKey: Buffer.from(ecKeypair.getPrivate().toArray()).toString('base64'),
    publicKey: Buffer.from(ecKeypair.getPublic().encode(undefined, false)).toString('base64'),
  };
  return keypair;
};

export const getSymmetricKey = (localPrivateKey: string, remotePublicKey: string): string => {
  const sha256 = crypto.createHash('SHA256');
  const keyPair = ec.keyFromPrivate(Buffer.from(localPrivateKey, 'base64'));
  const remotePb = ec.keyFromPublic(Buffer.from(remotePublicKey, 'base64')).getPublic();
  const buffer = Buffer.from(keyPair.derive(remotePb).toArray());
  const symmetricKey = sha256.update(buffer).digest();
  return symmetricKey.toString('base64');
};

export const decryptData = (encrypted: Buffer, key: Buffer, iv: Buffer) => {
  const encryptedContent = encrypted.slice(0, encrypted.length - macBitSize / 8);
  const tag = encrypted.slice(encrypted.length - macBitSize / 8, encrypted.length);

  const decipher = crypto.createDecipheriv(symmetricAlgorithm, key, iv);
  decipher.setAuthTag(tag);
  let dec = decipher.update(encryptedContent as any, 'hex', 'utf8');
  dec += decipher.final('utf8');
  return dec;
};

function arrayToQueryParams(
  identifier: string,
  values: string | undefined | number | boolean | (string | undefined | number | boolean)[],
  useOr: boolean = false,
): string {
  if (!Array.isArray(values)) {
    values = [values];
  }
  values = values
    .filter((value) => value !== undefined)
    .map((value) => `${encodeURIComponent(value)}`);
  if (useOr) {
    return `${identifier}=${values.join(',')}`;
  }
  return values.map((value) => `${identifier}=${value}`).join('&');
}

function objectToQueryParams(
  obj: Record<
    string,
    string | undefined | number | boolean | (string | undefined | number | boolean)[]
  >,
  orParams?: string[],
): string {
  return Object.entries(obj)
    .filter(([key, value]) => (Array.isArray(value) ? value.length > 0 : value !== undefined))
    .map(([key, value]) =>
      arrayToQueryParams(key, value, orParams?.length ? orParams.includes(key) : false),
    )
    .join('&');
}

function createUrl(
  pathname: string,
  queryObject: Record<
    string,
    string | undefined | number | boolean | (string | undefined | number | boolean)[]
  >,
  orParams?: string[],
): string {
  const queryParams = objectToQueryParams(queryObject, orParams);
  return `${pathname}?${queryParams}`;
}

function formatPeriodParam(periodParam: IPeriodParam | null): string[] {
  if (!periodParam) return [];
  const ret = [];
  if (periodParam.startDate) {
    ret.push(`ge${format(new Date(periodParam.startDate), 'yyyy-MM-dd')}`);
  }
  if (periodParam.endDate) {
    ret.push(`le${format(new Date(periodParam.endDate), 'yyyy-MM-dd')}`);
  }
  return ret;
}

export const formatContextParam = (_visitTypeList: string[] | null): string[] => {
  if (!_visitTypeList) return [];
  const visitTypeList = _visitTypeList.map((v) => {
    switch (v) {
      case 'IPD':
        return 'IMP';
      case 'OPD':
        return 'AMB';
      default:
        return v;
    }
  });
  return visitTypeList;
};

export const formatCategoryParam = (categoryParam: ICategoryParam | null): string => {
  if (!categoryParam) {
    return '';
  }
  return `${categoryParam.medication ? `&section=10160-0` : ''}${
    categoryParam.procedure ? `&section=47519-4` : ''
  }${categoryParam.diagnosis ? `&section=11348-0,11450-4,29548-5` : ''}${
    categoryParam.lab ? `&section=50398-7,30954-2,8716-3` : ''
  }`;
};

export const createCompositionRequestEntry = (
  identifers: string[],
  periodParam: IPeriodParam | null,
  visitTypeParam: string[] | null,
  categoryParam: ICategoryParam | null,
  patientCid: string,
  count: number,
  offset: number,
  encounter_missing: boolean,
): IRequestEntry => {
  const url = createUrl(
    'Composition',
    {
      identifier: identifers,
      'patient.identifier': patientCid,
      _count: count,
      _getpagesoffset: offset,
      _sort: '-period',
      'encounter:missing': encounter_missing,
      period: formatPeriodParam(periodParam),
      context: formatContextParam(visitTypeParam),
    },
    ['identifier', 'context'],
  );
  return {
    request: {
      method: 'GET',
      url: `${url}${formatCategoryParam(categoryParam)}`,
    },
  };
};

export const createAllergyIntoleranceRequestEntry = (
  identifers: string[],
  periodParam: IPeriodParam | null,
  patientCid: string,
  count: number,
  offset: number,
): IRequestEntry => {
  const url = createUrl(
    'AllergyIntolerance',
    {
      identifier: identifers,
      'patient.identifier': patientCid,
      _count: count,
      _getpagesoffset: offset,
      _sort: '-date',
      period: formatPeriodParam(periodParam),
    },
    ['identifier'],
  );
  return {
    request: {
      method: 'GET',
      url,
    },
  };
};

export const createImmunizationRequestEntry = (
  identifers: string[],
  periodParam: IPeriodParam | null,
  patientCid: string,
  count: number,
  offset: number,
): IRequestEntry => {
  const url = createUrl(
    'Immunization',
    {
      identifier: identifers,
      'patient.identifier': patientCid,
      _count: count,
      _getpagesoffset: offset,
      _sort: '-date',
      period: formatPeriodParam(periodParam),
    },
    ['identifier'],
  );
  return {
    request: {
      method: 'GET',
      url,
    },
  };
};

export const createProcedureRequestEntry = (
  identifers: string[],
  periodParam: IPeriodParam | null,
  patientCid: string,
  count: number,
  offset: number,
): IRequestEntry => {
  const url = createUrl(
    'Procedure',
    {
      identifier: identifers,
      'patient.identifier': patientCid,
      _count: count,
      _getpagesoffset: offset,
      _sort: '-date',
      period: formatPeriodParam(periodParam),
    },
    ['identifier'],
  );
  return {
    request: {
      method: 'GET',
      url,
    },
  };
};

export const createMedicationStatementRequestEntry = (
  identifers: string[],
  periodParam: IPeriodParam | null,
  patientCid: string,
  count: number,
  offset: number,
): IRequestEntry => {
  const url = createUrl(
    'MedicationStatement',
    {
      identifier: identifers,
      'patient.identifier': patientCid,
      _count: count,
      _getpagesoffset: offset,
      _sort: '-date',
      period: formatPeriodParam(periodParam),
    },
    ['identifier'],
  );
  return {
    request: {
      method: 'GET',
      url,
    },
  };
};

export const createDiagnosticReportRequestEntry = (
  identifers: string[],
  periodParam: IPeriodParam | null,
  patientCid: string,
  count: number,
  offset: number,
): IRequestEntry => {
  const url = createUrl(
    'DiagnosticReport',
    {
      identifier: identifers,
      'patient.identifier': patientCid,
      _count: count,
      _getpagesoffset: offset,
      _sort: '-date',
      period: formatPeriodParam(periodParam),
    },
    ['identifier'],
  );
  return {
    request: {
      method: 'GET',
      url,
    },
  };
};

export const createObservationRequestEntry = (
  identifers: string[],
  periodParam: IPeriodParam | null,
  patientCid: string,
  count: number,
  offset: number,
): IRequestEntry => {
  const url = createUrl(
    'Observation',
    {
      identifier: identifers,
      'patient.identifier': patientCid,
      _count: count,
      _getpagesoffset: offset,
      _sort: '-date',
      period: formatPeriodParam(periodParam),
    },
    ['identifier'],
  );
  return {
    request: {
      method: 'GET',
      url,
    },
  };
};

export const createConditionRequestEntry = (
  identifers: string[],
  periodParam: IPeriodParam | null,
  patientCid: string,
  count: number,
  offset: number,
): IRequestEntry => {
  const url = createUrl(
    'Condition',
    {
      identifier: identifers,
      'patient.identifier': patientCid,
      _count: count,
      _getpagesoffset: offset,
      _sort: '-date',
      period: formatPeriodParam(periodParam),
    },
    ['identifier'],
  );
  return {
    request: {
      method: 'GET',
      url,
    },
  };
};

export const createLabRequestEntry = (
  identifers: string[],
  periodParam: IPeriodParam | null,
  patientCid: string,
  count: number,
  offset: number,
): IRequestEntry => {
  const url = createUrl(
    'DiagnosticReport',
    {
      identifier: identifers,
      _include: 'DiagnostiReport:result',
      'patient.identifier': patientCid,
      _count: count,
      _getpagesoffset: offset,
      _sort: '-date',
      period: formatPeriodParam(periodParam),
    },
    ['identifier'],
  );
  return {
    request: {
      method: 'GET',
      url,
    },
  };
};

export const clearFetchedData = (dispatch) => {
  dispatch(clearAllergySlice());
  dispatch(clearConditionSlice());
  dispatch(clearImmunizationSlice());
  dispatch(clearLabSlice());
  dispatch(clearLoadedSlice());
  dispatch(clearMedicationSlice());
  dispatch(clearOverviewSlice());
  dispatch(clearProcedureSlice());
  dispatch(clearPatientSlice());
};
