import { IUser, IWorkingPeriod } from 'interface/authen';

export interface ISelectOption {
  value: any;
  label: string;
}

export interface IPageProperties {
  expandSearchbar?: boolean;
}

export interface IRoute {
  path: string;
  name?: string;
  icon?: any;
  component: JSX.Element;
  views?: null | IRoute[];
  properties?: IPageProperties;
  roles?: string[] | null;
  indexRoute?: JSX.Element;
  defaultRoute?: JSX.Element;
  skipInitializeInApp?: boolean;
  resourceTab?: IResourceTab;
}

export interface IDemographic {
  patient_id: number;
  gender: 'M' | 'F';
  title: string;
  firstname: string;
  lastname: string;
  age: number;
  citizenId: string;
  birthdate: Date;
  mobile?: string | null;
  email?: string | null;
  is_email_verified?: boolean;

  app_list?: string; // ex: "KTB,DOPA" or "KTB"
}

export interface IKeyPair {
  privateKey: string;
  publicKey: string;
}

export interface IHospital {
  mdp_id?: number;
  mdp9_id?: string;
  mdn_id?: number;
  address_id?: number;
  consent_type?: string;
  mdp_name: string;
  mdp_name_en?: string;
  mdp_info?: string;
  inherit_consent?: boolean;
  exchanger?: boolean;
  exchanger_date?: Date | string;
  withdraw_date?: Date | string;
}

export interface IPeriodParam {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  periodType: '1week' | '1month' | '3months' | '6months' | '1year' | 'all-time';
}

export interface ICategoryParam {
  medication: boolean;
  procedure: boolean;
  diagnosis: boolean;
  lab: boolean;
}

export interface IConsentDestination {
  ktb_paotung: 'not send' | 'failed' | 'success';
  patient_email: 'not send' | 'failed' | 'success';
}

export interface IConsentStatus {
  ref_code?: string;
  doctor_id?: number;
  consent_status?: string;
  destination: IConsentDestination;
  expire_datetime?: string;
}

export interface IHospitalConsent {
  mdp9_id: string;
  mdp_name: string;
  fhir_data_from_server_required: boolean; // used to be fhir_data_pulling_required
  fhir_data_from_client_required: boolean;
  consent_type: 'case' | 'hospital';
  consent_status: 'FORBIDDEN' | 'CONSENTED' | 'EXPIRED' | 'DENIED' | 'TIMEOUT' | 'PENDING';
  type?: string;
  fhir_client_support: boolean;
}

export interface HospitalWithDataList {
  mdp9_id: string;
  num_visit: number;
  allergy_intolerance: number;
  condition: number;
  diagnostic_report: number;
  diagnostic_report_media: number;
  document_reference: number;
  encouter: number;
  immunization: number;
  medication: number;
  medication_statement: number;
  observation: number;
  procedure: number;
  last_updated?: any;
  last_force_update?: any;
  last_realtime_update?: any;
  last_archive_update?: any;
  last_delete_update?: any;
  last_composition_update?: any;
  last_batch_update?: any;
  created_at: Date;
  modified_at: Date;
  mdp_name: string;
}

export interface IListData {
  mdp9_id?: string;
  num_visit?: number;
  allergy_intolerance?: number;
  condition?: number;
  diagnostic_report?: number;
  diagnostic_report_media?: number;
  document_reference?: number;
  encouter?: number;
  immunization?: number;
  medication?: number;
  medication_statement?: number;
  observation?: number;
  procedure?: number;
  last_updated?: any;
  last_force_update?: any;
  last_realtime_update?: any;
  last_archive_update?: any;
  last_delete_update?: any;
  last_composition_update?: any;
  last_batch_update?: any;
  created_at?: Date;
  modified_at?: Date;
  mdp_name?: string;
}

export interface IHospitalListData {
  total: number;
  page: number;
  size: number;
  list_data?: IListData[] | any[];
}

export interface ISplittedHospitalConsentList {
  required: IHospitalConsent[] | null;
  notRequired: IHospitalConsent[] | null;
  fromClientRequired: IHospitalConsent[] | null;
}

export interface ISplittedHospitalConsentListIds {
  required: string[];
  notRequired: string[];
  fromClientRequired: string[];
}

export interface IUserListResult {
  total: number;
  currentPage: number;
  pageSize: number;
  records: IUser[];
  lastDoctorManagementDate?: Date;
}

export interface ITermResponse {
  created_datetime: string;
  description: string;
  form_id: number;
  form_name: string;
  form_type: string;
  required_version: string;
  updated_datetime: string;
  version: string;
}

export interface ITermElement {
  content: string;
  type: 'header' | 'paragraph' | 'checkbox';
  replace_keys?: any;
}

export interface ITabMeta {
  iconComponent: any;
  tabName: string;
  tabKey: string;
  tabPanelComponent?: React.FC<any>;
  subMenu?: IHospitalAdminTabMeta[];
  path?: string;
  showPageInDEVOnly?: boolean;
}

export interface IHospitalAdminTabMeta extends ITabMeta {
  // tabKey:
  //   | 'exchange-zone'
  //   | 'exchange-zone-config'
  //   | 'manage-doctor'
  //   | 'doctor-activity'
  //   | 'dashboard'
  //   | 'profile'
  //   | 'device'
  //   | 'api-key'
  //   | 'fhir-data-access-log'
  //   | 'consent-request-log'
  //   | 'tracking-and-system'
  tabKey:
    | 'admin-home'
    | 'doctor-management'
    | 'exchange-zone'
    | 'exchange-zone-config'
    | 'manage-doctor'
    | 'doctor-activity'
    | 'dashboard'
    | 'profile'
    | 'device'
    | 'api-key'
    | 'fhir-data-access-log'
    | 'consent-request-log'
    | 'tracking-and-system'
    | 'user-updated-log'
    | 'user-accept-log'
    | 'setting-system'
    | 'user-access-log';
}

export interface IPageResult {
  total: number;
  currentPage: number;
  pageSize: number;
  records: any[];
}

export interface IMedicalNetwork {
  mdn_id: number;
  mdn9_id: string;
  mdn_name: string;
  mdn_name_en: string;
  mdn_info: string;
}

export interface IMedicalNetworkForm extends IMedicalNetwork {
  mdn9_id: string;
  mdn_name: string;
  mdn_name_en: string;
  mdn_info: string;
}

export interface IMedicalNetworkPageResult extends IPageResult {
  records: IMedicalNetwork[];
}

export interface IMedicalPlace {
  mdp_id: number;
  mdp9_id: string;
  mdn_id?: number;
  consent_type?: string;
  mdp_name: string;
  mdp_name_en?: string;
  mdp_info?: string;
  exchanger?: boolean;
  exchanger_date?: Date | string;
  withdraw_date?: Date | string;
  mdn9_id?: string;
  mdn_name?: string;
  mdn_name_en?: string;
  mdn_info?: string;
  ip_address?: ISystemAdminDevice[];
  fhir_data_from_client_required?: boolean;
  fhir_data_from_server_required?: boolean;
}

export interface IMedicalPlacePageResult extends IPageResult {
  records: IMedicalPlace[];
}

export interface IMedicalPlaceForm {
  mdp_id: number;
  mdp9_id: string;
  mdn_id: number;
  mdn_option: ISelectOption | null;
  consent_type: string;
  mdp_name: string;
  mdp_name_en: string;
  mdp_info: string;
  exchanger: boolean;
}

export interface IHospitalResourceStatus {
  patient: boolean;
  organization: boolean;
  allergy_intolerance: boolean;
  medication_statement: boolean;
  immunization: boolean;
  condition: boolean;
  procedure: boolean;
  diagnostic_report: boolean;
  observation: boolean;
  encounter: boolean;
  composition: boolean;
  document_reference: boolean;
  mdp9_id: string;
}

export interface IHospitalAdminDevice {
  mdp_ip_address_id?: number;
  ip_address: string;
  password?: string;
}

export interface IFormCheckDoctor {
  number: string;
  firstname: string;
  lastname: string;
}

export interface ISystemAdminDevice {
  mdp_ip_address_id?: number;
  mdp_id?: number;
  ip_address: string;
}

export interface IHospitalAdminAPIKeyForm {
  password: string;
}

export interface ISystemAdminAPIKeyForm {
  owner: string;
  group: string;
  email: string;
  group_option?: ISelectOption | null;
}
export interface INewAPIKeyForm {
  token_id: number;
}

export interface IAddDoctorForm {
  number: string;
  firstname: string;
  lastname: string;
  isPartTime: boolean;
  workingHours?: IWorkingPeriod[];
  user_type: string;
  user_mdp_info: string;
}

export interface IFHIRDataInfoHospital {
  mdp9_id: string;
  num_visit: number;
  allergy_intolerance: number;
  condition: number;
  diagnostic_report: number;
  diagnostic_report_media: number;
  document_reference: number;
  encouter: number;
  immunization: number;
  medication: number;
  medication_statement: number;
  observation: number;
  procedure: number;
  last_updated: string;
  last_force_update: string;
  last_realtime_update: string;
  last_archive_update: string;
  last_delete_update: string;
  last_composition_update: string;
  last_batch_update: string;
  created_at: string;
  modified_at: string;
  mdp_name: string;
  cid?: string;
}

export type IFHIRDataInfoHospitals = IFHIRDataInfoHospital[];

export interface IFHIRDataInfo {
  total: number;
  page: number;
  size: number;
  list_data: IFHIRDataInfoHospitals;
}

export const RESOURCE_TABS = [
  'Allergy',
  'Diagnosis',
  'Vaccine',
  'Lab',
  'Medication',
  'Procedure',
  'Overview',
] as const;

export type IResourceTab = (typeof RESOURCE_TABS)[number];

export interface IFhirDataInfoResourceCount {
  num_visit: number;
  allergy_intolerance: number;
  condition: number;
  diagnostic_report: number;
  diagnostic_report_media: number;
  document_reference: number;
  encouter: number;
  immunization: number;
  medication: number;
  medication_statement: number;
  observation: number;
  procedure: number;
}
export interface IResourceCount {
  composition: number;
  allergyIntolerance: number;
  immunization: number;
  condition: number;
  medicationStatement: number;
  procedure: number;
  diagnosticReport: number;
  observation: number;
}
