export const sampleTerms = {
  created_datetime: '2022-01-01',
  description: '[]',
  form_id: 1,
  form_name: 'xxx',
  form_type: 'xxx',
  required_version: '0.0.0',
  updated_datetime: '2021-10-11T13:21:03.621333',
  version: '0.0.0',
};
