import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type typeOtion = 'Male' | 'Female' | 'Boy' | 'Girl' | 'Unknown';

export interface GenderIconProps {
  iconType: typeOtion;
}

const genderColor = {
  Male: '#CFDDF6',
  Female: '#F6CFEB',
  Boy: 'CFDDF6',
  Girl: 'F6CFEB',
  Unknown: 'CFCFCF',
};

const genderText = {
  Male: 'ช',
  Female: 'ญ',
  Boy: 'ดช',
  Girl: 'ดญ',
  Unknown: 'U',
};

function GenderIcon({ iconType }: GenderIconProps): JSX.Element {
  const classes = {
    logo: `${iconType}Icon-${iconType.toLowerCase()}Logo`,
    largeFont: `${iconType}Icon-largeFont`,
  };

  const Root = styled('div')(({ theme }) => ({
    [`&.${classes.logo}`]: {
      backgroundColor: genderColor[iconType],
      borderColor: genderColor[iconType],
      color: theme.palette.text.primary,
      borderRadius: '50%',
      border: '2px solid',
      height: '50px',
      width: '50px',
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },

    [`& .${classes.largeFont}`]: {
      fontSize: '1.5rem',
    },
  }));
  return (
    <Root className={classes.logo}>
      <Typography variant="button" className={classes.largeFont}>
        {genderText[iconType]}
      </Typography>
    </Root>
  );
}

export default GenderIcon;
