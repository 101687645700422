import React from 'react';
import { TAB_LIST as HOSPITAL_ADMIN_TAB_LIST, USER_TAB } from 'config/hospital-admin';
import { TAB_LIST as SYSTEM_TAB_LIST } from 'config/system-admin';
import { IRoute } from 'interface';
import AdminLayout from 'layouts/Admin/AdminLayout';
import ConsoleLayout from 'layouts/Console/ConsoleLayout';
import InitModalsLayoutContextProvider from 'layouts/InitModalsContext';
import PageLayout from 'layouts/Page/PageLayout';
import SimplePageLayout from 'layouts/SimplePageLayout';
import DoctorManagement from 'pages/console/DoctorManagement';
import HospitalAdminManagement from 'pages/console/HospitalAdminManagement/HospitalAdminManagement';
import NotAuthorized from 'pages/console/NotAuthorized';
import PatientSearch from 'pages/console/PatientSearch';
import SystemAdminManagement from 'pages/console/SystemAdminManagement/SystemAdminManagement';
import UserManagement from 'pages/console/UserManagement/UserManagement';
import Login from 'pages/page/Login';
import ThirdPartyFrontend from 'pages/page/ThirdPartyFrontend';
import ThirdPartyLanding from 'pages/page/ThirdPartyLanding';
import { Navigate } from 'react-router-dom';

// import PatientInfo from 'pages/console/PatientInfo/PatientInfo';
// import Overview from 'pages/console/PatientInfo/Overview';
// import Allergy from 'pages/console/PatientInfo/DetailTabs/Allergy';
// import Immunization from 'pages/console/PatientInfo/DetailTabs/Immunization';
// import Labs from 'pages/console/PatientInfo/DetailTabs/Labs';
// import Condition from 'pages/console/PatientInfo/DetailTabs/Condition';
// import Procedure from 'pages/console/PatientInfo/DetailTabs/Procedure';
// import Medication from 'pages/console/PatientInfo/DetailTabs/Medication';

const PatientInfo = React.lazy(() => import('pages/console/PatientInfo/PatientInfo'));
const Overview = React.lazy(() => import('pages/console/PatientInfo/Overview'));
const Allergy = React.lazy(() => import('pages/console/PatientInfo/DetailTabs/Allergy'));
const Immunization = React.lazy(() => import('pages/console/PatientInfo/DetailTabs/Immunization'));
const Labs = React.lazy(() => import('pages/console/PatientInfo/DetailTabs/Labs'));
const Condition = React.lazy(() => import('pages/console/PatientInfo/DetailTabs/Condition'));
const Procedure = React.lazy(() => import('pages/console/PatientInfo/DetailTabs/Procedure'));
const Medication = React.lazy(() => import('pages/console/PatientInfo/DetailTabs/Medication'));

const routes: IRoute[] = [
  ...(process.env.NODE_ENV === 'development'
    ? [
        {
          path: 'third-party-frontend',
          component: <SimplePageLayout />,
          views: [
            {
              path: 'view',
              name: 'Third-Party Frontend',
              component: <ThirdPartyFrontend />,
            },
          ],
          indexRoute: <Navigate to="/third-party-frontend/view" replace />,
          defaultRoute: <NotAuthorized />,
          roles: null,
        },
      ]
    : []),
  {
    path: 'single-use',
    component: <SimplePageLayout />,
    views: [
      {
        path: 'load',
        name: 'Load',
        component: (
          <InitModalsLayoutContextProvider>
            <ThirdPartyLanding />
          </InitModalsLayoutContextProvider>
        ),
        skipInitializeInApp: true, // skip in app because is handled in its own page
      },
    ],
    indexRoute: <Navigate to="/page/login" replace />,
    defaultRoute: <NotAuthorized />,
    roles: null,
  },
  {
    path: 'page',
    component: <PageLayout />,
    views: [
      {
        path: 'login',
        name: 'Login',
        component: (
          <InitModalsLayoutContextProvider>
            <Login />
          </InitModalsLayoutContextProvider>
        ),
      },
    ],
    indexRoute: <Navigate to="/page/login" replace />,
    defaultRoute: <NotAuthorized />,
    roles: null,
  },
  {
    path: 'console',
    component: <ConsoleLayout />,
    views: [
      {
        path: 'home',
        name: 'Patient Search',
        component: <PatientSearch />,
        properties: {
          expandSearchbar: true,
        },
        roles: ['DOCTOR'],
      },
      {
        path: 'profile',
        name: 'Doctor Management',
        component: <DoctorManagement />,
        roles: ['DOCTOR', 'DOCTOR_SINGLE_PATIENT'],
      },
      {
        path: 'patient',
        name: 'Patient Information',
        component: <PatientInfo />,
        views: [
          {
            path: 'overview',
            component: <Overview />,
          },
          {
            path: 'allergy',
            component: <Allergy />,
          },
          {
            path: 'immunization',
            component: <Immunization />,
          },
          {
            path: 'condition',
            component: <Condition />,
          },
          {
            path: 'procedure',
            component: <Procedure />,
          },
          {
            path: 'medication',
            component: <Medication />,
          },
          {
            path: 'lab',
            component: <Labs />,
          },
        ],
        indexRoute: <Navigate to="/console/patient/overview" replace />,
        defaultRoute: <NotAuthorized />,
        roles: ['DOCTOR', 'DOCTOR_SINGLE_PATIENT'],
      },
    ],
    indexRoute: <Navigate to="/console/home" replace />,
    defaultRoute: <NotAuthorized />,
    roles: ['DOCTOR', 'DOCTOR_SINGLE_PATIENT'],
  },
  {
    path: 'admin',
    component: <AdminLayout />,
    views: [
      {
        path: 'home',
        name: 'Hospital Admin Management',
        component: <HospitalAdminManagement />,
        views: HOSPITAL_ADMIN_TAB_LIST.reduce((acc, cur) => {
          if (cur.subMenu) {
            const subMenu = cur.subMenu.map((item) => ({
              path: item.tabKey,
              component: <item.tabPanelComponent />,
            }));

            return [...acc, ...subMenu];
          }
          return [
            ...acc,
            {
              path: cur.tabKey,
              component: <cur.tabPanelComponent />,
            },
          ];
        }, []),
        indexRoute: <Navigate to="/admin/home/admin-home" replace />,
        defaultRoute: <NotAuthorized />,
      },
    ],
    indexRoute: <Navigate to="/admin/home" replace />,
    defaultRoute: <NotAuthorized />,
    roles: ['MDP_ADMIN'],
  },
  {
    path: 'admin',
    component: <AdminLayout />,
    views: [
      {
        path: 'user',
        name: 'Admin Management',
        component: <UserManagement />,
        views: USER_TAB.map((item) => {
          return {
            path: item.tabKey,
            name: item.tabKey,
            component: <item.tabPanelComponent />,
          };
        }),
        indexRoute: <Navigate to="/admin/user/profile" replace />,
        defaultRoute: <NotAuthorized />,
      },
    ],
    indexRoute: <Navigate to="/user/profile" replace />,
    defaultRoute: <NotAuthorized />,
    roles: ['MDP_ADMIN'],
  },
  {
    path: 'admin',
    component: <AdminLayout />,
    views: [
      {
        path: 'home',
        name: 'Admin Management',
        component: <SystemAdminManagement />,
        views: SYSTEM_TAB_LIST.map((tabRecord) => ({
          path: tabRecord.tabKey,
          component: <tabRecord.tabPanelComponent />,
        })),
        indexRoute: <Navigate to="/admin/home/dashboard" replace />,
        defaultRoute: <NotAuthorized />,
      },
    ],
    indexRoute: <Navigate to="/admin/home" replace />,
    defaultRoute: <NotAuthorized />,
    roles: ['ADMIN'],
  },
];

export default routes;
