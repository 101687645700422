import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LogoLarge from 'assets/img/logo_large.png';
import AvatarIcon from 'components/AvatarIcon/AvatarIcon';
import LoadingBackdrop from 'components/Backdrop/LoadingBackdrop';
import ConfirmLogoutDialog from 'components/Dialog/ConfirmLogoutDialog';
import EvaluationDialog from 'components/Dialog/EvaluationDialog';
import GenericInput from 'components/Input/GenericInput';
import { ENABLE_SEARCHBAR_ROLES, enableContactSystem, enableSelectLanguage } from 'config';
import {
  selectNoticeList,
  selectRoles,
  selectUser,
  setAccessToken,
  setAccessTokenAndSessionExpiredTime,
  setJmgntWsToken,
  setUser,
} from 'global_store/features/authen/authenSlice';
import {
  selectSelectedHospital,
  setSelectedHospital,
} from 'global_store/features/hospital/hospitalSlice';
import { setErrorMessage, setShowErrorDialog } from 'global_store/features/message/messageSlice';
import { setSearchKeyword, setSearchResult } from 'global_store/features/search/searchSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import { IHospital } from 'interface';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FaBell,
  FaChevronDown,
  FaChevronUp,
  FaExclamation,
  FaSearch,
  FaSignOutAlt,
  FaUser,
  FaUserMd,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { requestLogout, setActiveMdp } from 'services/authen';
import { getPatientListByKeyword } from 'services/patient';
import { renewAccessToken } from 'utils/authen';
import { sendEvent } from 'utils/ga4';
import { NOTICE_MOCK } from 'utils/mock-notice';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SwipeableDrawer,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

interface BaseNavbarProps {
  logoNavigateTo: String;
  expandSearchbar?: boolean;
  enableHospitalSelection?: boolean;
  AdminNavbar?: boolean;
  enableEvaluation?: boolean;
  showOnlyActiveHospitalName?: boolean;
  showOnlyLogoutInAvatarMenu?: boolean;
  evaluationChance?: number;
}

interface IKeywordForm {
  keyword: string;
}

const navbarType = 'Base';
const classes = {
  root: `${navbarType}Navbar-root`,
  menuButton: `${navbarType}Navbar-menuButton`,
  menuItem: `${navbarType}Navbar-menuItem`,
  title: `${navbarType}Navbar-title`,
  homeIcon: `${navbarType}Navbar-homeIcon`,
  doctorName: `${navbarType}Navbar-doctorName`,
  hospitalDropdown: `${navbarType}Navbar-hospitalDropdown`,
  hospitalDropdownText: `${navbarType}Navbar-hospitalDropdownText`,
  languageDropdownText: `${navbarType}Navbar-languageDropdownText`,
  horizontalCenterContent: `${navbarType}Navbar-horizontalCenterContent`,
  centerContent: `${navbarType}Navbar-centerContent`,
  rightContent: `${navbarType}Navbar-rightContent`,
  margin: `${navbarType}Navbar-margin`,
  marginRight: `${navbarType}Navbar-marginRight`,
  searchbar: `${navbarType}Navbar-searchbar`,
  formControl: `${navbarType}Navbar-formControl`,
  adornment: `${navbarType}Navbar-adornment`,
  largeBanner: `${navbarType}Navbar-largeBanner`,
  lightText: `${navbarType}Navbar-lightText`,
  siteLogo: `${navbarType}Navbar-siteLogo`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    paddingTop: '9.35px',
  },

  [`& .${classes.menuButton}`]: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    color: '#FFFFFF',
    borderRadius: '10px',
  },

  [`& .${classes.menuItem}`]: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },

  [`& .${classes.homeIcon}`]: {
    maxWidth: '50px',
    maxHeight: '50px',
  },

  [`& .${classes.doctorName}`]: {
    paddingBottom: 0,
  },

  [`& .${classes.hospitalDropdown}`]: {
    paddingTop: 0,
  },

  [`& .${classes.hospitalDropdownText}`]: {
    maxWidth: '24rem', // needed for desktop
    marginRight: theme.spacing(1),
    color: '#FAFAFA',
  },

  [`& .${classes.languageDropdownText}`]: {
    maxWidth: '290px',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    color: '#FAFAFA',
  },

  [`& .${classes.horizontalCenterContent}`]: {
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.centerContent}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.rightContent}`]: {
    display: 'flex',
    justifyContent: 'right',
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.marginRight}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.searchbar}`]: {
    margin: 'auto',
    color: '#6B6B6B',
    width: '90%',
  },

  [`& .${classes.formControl}`]: {
    backgroundColor: '#FAFAFA',
    borderRadius: '50px',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },

  [`& .${classes.adornment}`]: {
    color: '#009DDB',
  },

  [`& .${classes.largeBanner}`]: {
    height: '250px',
    color: '#FAFAFA',
  },

  [`& .${classes.lightText}`]: {
    color: '#FAFAFA',
  },

  [`& .${classes.siteLogo}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function BaseNavbar({
  logoNavigateTo,
  expandSearchbar = false,
  enableHospitalSelection = false,
  AdminNavbar = false,
  enableEvaluation = false,
  showOnlyActiveHospitalName = false,
  showOnlyLogoutInAvatarMenu = false,
  evaluationChance = 1,
}: BaseNavbarProps): JSX.Element {
  useEffect(() => {
    if (!enableSelectLanguage) {
      localStorage.removeItem('@lang');
    }
    handleSelectLanguage(localStorage.getItem('@lang') ? localStorage.getItem('@lang') : 'en');
  }, []);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hiddenMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isTabletLayout = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isMobileLayout = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [openNotice, setOpenNotice] = useState(false);
  const [noticeHovered, setNoticeHovered] = useState(false);
  const [noticeTimeout, setNoticeTimeout] = useState<any>(null);
  const roles = useAppSelector(selectRoles);
  const noticeList = useAppSelector(selectNoticeList);

  const notices = useMemo(() => {
    if (!roles) return [];
    if (roles.includes('ADMIN')) return [];
    if (roles.includes('MDP_ADMIN')) return NOTICE_MOCK;
    return noticeList;
  }, [roles, noticeList]);

  const openedNotice = useCallback(() => {
    if (noticeTimeout) clearTimeout(noticeTimeout);

    setNoticeTimeout(
      setTimeout(() => {
        setOpenNotice(false);
      }, 300),
    );
  }, [noticeHovered, noticeTimeout]);

  useEffect(() => {
    if (!noticeHovered) return;
    if (noticeTimeout) clearTimeout(noticeTimeout);
  }, [noticeHovered, noticeTimeout]);

  const enableSearchbar = useMemo(
    () => (roles && roles.some((role) => ENABLE_SEARCHBAR_ROLES.includes(role))) ?? false,
    [roles],
  );
  const UserIconClass =
    roles && (roles.includes('DOCTOR') || roles.includes('DOCTOR_SINGLE_PATIENT'))
      ? FaUserMd
      : FaUser;
  const activeMdpName = useMemo(
    () => (user?.list_mdp?.length ? user.list_mdp[0].mdp_name : ''),
    [user?.list_mdp],
  );

  const handleLogout = () => {
    // TODO: consider packing these together
    requestLogout();
    dispatch(setUser(null));
    // dispatch(setAccessToken(null));
    dispatch(setAccessTokenAndSessionExpiredTime({ accessToken: null }));
    dispatch(setJmgntWsToken(null));
    dispatch(setSelectedHospital(null));
    setDrawer(false);
    navigate('/page/login');
  };

  const selectedHospital = useAppSelector(selectSelectedHospital);
  const [hospitalMenuAnchor, setHospitalMenuAnchor] = useState<null | HTMLElement>(null);
  const [avatarMenuAnchor, setAvatarMenuAnchor] = useState<null | HTMLElement>(null);
  const openAvatarMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarMenuAnchor(e.currentTarget);
  };

  const handleCloseAvatarMenu = () => {
    setAvatarMenuAnchor(null);
  };
  const openHospitalMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setHospitalMenuAnchor(e.currentTarget);
  };

  const handleCloseHospitalMenu = () => {
    setHospitalMenuAnchor(null);
  };

  const handleSelectHospital = async (hospital: IHospital) => {
    // do not let mdp admin call setActiveMdp for now until mdp admin can have more than 1 hospital
    if (!roles || roles.includes('MDP_ADMIN')) {
      handleCloseHospitalMenu();
      return;
    }
    // Set selected hospital
    const { success, data, error } = await setActiveMdp(hospital.mdp_id || 0);
    if (success) {
      dispatch(setSelectedHospital(hospital));
      await renewAccessToken();
      handleCloseHospitalMenu();
    } else if (!data.errorIsHandled) {
      dispatch(setErrorMessage(error));
      dispatch(setShowErrorDialog(true));
    }
  };

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [showEvaluationDialog, setShowEvaluationDialog] = React.useState<boolean>(false);

  const [languageMenuAnchor, setLanguageMenuAnchor] = useState<null | HTMLElement>(null);
  const [languageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);
  const [selectLanguage, setSelectLanguage] = useState<string>('English');
  const [showLogoutDialog, setShowLogoutDialog] = useState<boolean>(false);
  const openLanguageMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageMenuAnchor(e.currentTarget);
  };
  const handleCloseLanguageMenu = () => {
    setLanguageMenuAnchor(null);
  };
  const handleSelectLanguage = (lang) => {
    console.log('เปลี่ยนภาษา', lang);
    i18n.changeLanguage(lang);
    setSelectLanguage(lang === 'en' ? 'English' : 'ไทย');
    handleCloseLanguageMenu();
    localStorage.setItem('@lang', lang);
  };

  const handleCloseEvaluationDialog = () => {
    setShowEvaluationDialog(false);
    handleLogout();
  };

  const handleCloseLogoutDialog = () => {
    setShowLogoutDialog(false);
  };
  const handleOpenLogoutDialog = () => {
    setShowLogoutDialog(true);
  };
  const handleConfirmLogoutDialog = () => {
    handleCloseLogoutDialog();
    const shouldShowEval = Math.random() < evaluationChance;
    if (!enableEvaluation || !shouldShowEval) {
      handleLogout();
      return;
    }
    if (shouldShowEval) setShowEvaluationDialog(true);
  };

  const navigateToDoctorManagement = (targetTab: string) => {
    navigate('/console/profile', { state: { targetTab } });
  };

  const navigateToAdminManagement = (path: string) => {
    navigate(`/admin/user/${path}`);
    handleCloseHospitalMenu();
  };

  const schema = yup
    .object()
    .shape({
      keyword: yup
        .string()
        .trim()
        .required('')
        .test(
          'cid',
          'เลขบัตรประชาชนต้องเป็นตัวเลข 13 หลัก ไม่มีเครื่องหมาย',
          (val: string | undefined) => /^[^0-9]/.test(val!) || /^((\d-?){13})?$/.test(val!),
        )
        .test(
          'fullname',
          'กรุณากรอกชื่อและนามสกุล ไม่ต้องมีคำนำหน้า',
          (val: string | undefined) => /^[0-9]/.test(val!) || / /.test(val!),
        ),
    })
    .required();

  const { control, handleSubmit, setValue } = useForm<IKeywordForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IKeywordForm> = async (data) => {
    let { keyword } = data;
    if (keyword.match(/^((\d-?){13})?$/)) {
      keyword = keyword.replaceAll('-', '');
    }
    setIsLoading(true);
    const result = await getPatientListByKeyword({
      keyword,
      page: 1,
      pageSize: 5,
    });
    setIsLoading(false);
    if (result.success === true) {
      dispatch(
        setSearchResult({
          ...result.data,
          records: result.data.records.sort((a, b) => (a.cid < b.cid ? -1 : a.cid > b.cid ? 1 : 0)),
        }),
      );
      dispatch(setSearchKeyword(keyword));
      setValue('keyword', '');
      navigate('/console/home'); // TODO: check if need this line
    } else if (!result.data.errorIsHandled) {
      dispatch(setErrorMessage(JSON.stringify(result.error)));
      dispatch(setShowErrorDialog(true));
    }
  };

  const onInvalid = (e: any) => {
    console.log('blank keyword');
  };

  const endAdornment = (
    <IconButton className={classes.adornment} type="submit" size="large">
      <FaSearch />
    </IconButton>
  );

  const setDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event?.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    )
      setLanguageMenuOpen(false);
    setIsExpanded(open);
  };

  const NavbarItems = {
    logo: (
      <IconButton
        edge="start"
        className={classes.marginRight}
        color="inherit"
        aria-label="menu"
        onClick={() => {
          navigate(logoNavigateTo as never);
        }}
        size="large"
      >
        <img src={LogoLarge} alt="HIE Logo" className={classes.homeIcon} />
      </IconButton>
    ),
    title: 'Health Link',
    searchBarTitle: 'ค้นหาผู้ป่วย',
    searchBar: (
      <FormControl
        className={`${classes.margin} ${classes.formControl}`}
        component="form"
        onSubmit={handleSubmit(onSubmit, onInvalid)}
      >
        <GenericInput
          control={control}
          name="keyword"
          label="Search Input"
          defaultValue=""
          type="text"
          variant="inputWithAdornment"
          placeholder="เลขบัตรประชาชน หรือ ชื่อและนามสกุล"
          rules={{}}
          endAdornment={endAdornment}
          className={classes.searchbar}
        />
      </FormControl>
    ),
    langauges: [
      {
        code: 'th',
        name: 'ไทย',
      },
      {
        code: 'en',
        name: 'English',
      },
    ],
    selectHospitalText: 'กรุณาเลือกสังกัดโรงพยาบาล',

    logoutText: 'ออกจากระบบ',
  };
  const NavbarMenu: { text: string; name: string; icon: JSX.Element; handler?: () => void }[] =
    enableContactSystem
      ? [
          {
            text: 'จัดการบัญชีผู้ใช้งาน',
            name: 'profile',
            /* change to AvatarIcon? */
            icon: (
              <FaUserMd
                style={{
                  background: theme.palette.secondary.main,
                  color: theme.palette.background.paper,
                  width: 40,
                  height: 40,
                  padding: 8,
                  borderRadius: '50%',
                }}
              />
            ),
          },
          {
            text: 'แจ้งเตือน',
            name: 'notice',
            icon: (
              <FaBell
                style={{
                  background: theme.palette.secondary.main,
                  color: theme.palette.background.paper,
                  width: 40,
                  height: 40,
                  padding: 8,
                  borderRadius: '50%',
                }}
              />
            ),
            handler: () => {
              sendEvent('notification_from_menu');
            },
          },
          {
            text: 'ติดต่อระบบฯ',
            name: 'contact',
            icon: (
              <FaExclamation
                style={{
                  background: theme.palette.secondary.main,
                  color: theme.palette.background.paper,
                  width: 40,
                  height: 40,
                  padding: 8,
                  borderRadius: '50%',
                }}
              />
            ),
          },
        ]
      : [
          {
            text: 'จัดการบัญชีผู้ใช้งาน',
            name: 'profile',
            /* change to AvatarIcon? */
            icon: (
              <FaUserMd
                style={{
                  background: theme.palette.secondary.main,
                  color: theme.palette.background.paper,
                  width: 40,
                  height: 40,
                  padding: 8,
                  borderRadius: '50%',
                }}
              />
            ),
          },
          {
            text: 'แจ้งเตือน',
            name: 'notice',
            icon: (
              <FaBell
                style={{
                  background: theme.palette.secondary.main,
                  color: theme.palette.background.paper,
                  width: 40,
                  height: 40,
                  padding: 8,
                  borderRadius: '50%',
                }}
              />
            ),
            handler: () => {
              sendEvent('notification_from_menu');
            },
          },
        ];

  // mobile and tablet layout
  if (isMobileLayout || isTabletLayout) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <StyledAppBar position="static" className={classes.root}>
          <Toolbar>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Stack>{NavbarItems.logo}</Stack>
              <Stack
                direction="column"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                <Typography
                  variant="h6"
                  justifyContent="center"
                  sx={{
                    color: '#FAFAFA',
                    lineHeight: 1.5,
                    fontWeight: 700,
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {`${user?.title ?? ''} ${user?.firstname ?? ''} ${user?.lastname ?? ''}`}
                </Typography>
                <List
                  sx={{
                    display: 'inline-block',
                    p: 0,
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {(enableHospitalSelection || showOnlyActiveHospitalName) && (
                    <>
                      <ListItem
                        component={showOnlyActiveHospitalName ? undefined : Button}
                        className={classes.hospitalDropdown}
                        onClick={showOnlyActiveHospitalName ? undefined : openHospitalMenu}
                      >
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                        >
                          <Stack
                            sx={{
                              flex: '1 1 0%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              className={classes.hospitalDropdownText}
                              sx={{
                                width: '100%',
                                fontSize: '1.125rem',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              noWrap
                            >
                              {`${selectedHospital?.mdp_name || NavbarItems.selectHospitalText}`}
                            </Typography>
                          </Stack>
                          {!showOnlyActiveHospitalName && (
                            <Stack
                              className={`${classes.centerContent} ${classes.lightText}`}
                              sx={{ color: theme.palette.background.paper, paddingLeft: 1 }}
                            >
                              {hospitalMenuAnchor ? <FaChevronUp /> : <FaChevronDown />}
                            </Stack>
                          )}
                        </Stack>
                      </ListItem>
                      <Menu
                        id="hospital-select-menu"
                        anchorEl={hospitalMenuAnchor}
                        keepMounted
                        open={Boolean(hospitalMenuAnchor)}
                        onClose={handleCloseHospitalMenu}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        {user?.list_mdp.map((hospital, key) => (
                          <MenuItem
                            className={classes.menuItem}
                            key={key}
                            onClick={() => {
                              handleSelectHospital(hospital);
                            }}
                          >
                            {hospital.mdp_name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </List>
              </Stack>
              <Stack>
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  sx={{ p: '0 1', color: 'white' }}
                  onClick={setDrawer(true)}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              </Stack>
            </Stack>
          </Toolbar>
        </StyledAppBar>
        {expandSearchbar && (
          <StyledAppBar
            position="static"
            className={classes.root}
            style={{ boxShadow: '0px -1px 0px 0px #0000001f' }}
          >
            <Grid container py={4}>
              <Grid item xs={8} mx="auto">
                <Typography variant="h1" className={classes.lightText}>
                  {NavbarItems.searchBarTitle}
                </Typography>
                {NavbarItems.searchBar}
              </Grid>
            </Grid>
          </StyledAppBar>
        )}
        <SwipeableDrawer
          anchor="right"
          open={isExpanded}
          onOpen={setDrawer(true)}
          onClose={setDrawer(false)}
          PaperProps={{
            sx: {
              width: '16rem',
            },
          }}
        >
          <List>
            {!showOnlyLogoutInAvatarMenu && (
              <>
                {NavbarMenu.map((menu) => (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          if (menu.handler) menu.handler();
                          setIsExpanded(false);
                          setLanguageMenuOpen(false);
                          if (AdminNavbar) return navigateToAdminManagement(menu.name);
                          return navigateToDoctorManagement(menu.name);
                        }}
                      >
                        <ListItemIcon>{menu.icon}</ListItemIcon>
                        <ListItemText primary={menu.text} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </>
                ))}
                {enableSelectLanguage && (
                  <Collapse in={languageMenuOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {NavbarItems.langauges.map((language) => (
                        <ListItemButton
                          onClick={() => {
                            setIsExpanded(false);
                            setLanguageMenuOpen(false);
                            handleSelectLanguage(language.code);
                          }}
                        >
                          <ListItemText style={{ paddingLeft: '56px' }} primary={language.name} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
                <Divider />
              </>
            )}
            <ListItem disablePadding>
              <ListItemButton onClick={handleOpenLogoutDialog}>
                <ListItemIcon>
                  <FaSignOutAlt
                    style={{
                      background: theme.palette.secondary.main,
                      color: theme.palette.background.paper,
                      width: 40,
                      height: 40,
                      padding: 8,
                      borderRadius: '50%',
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={NavbarItems.logoutText} />
              </ListItemButton>
            </ListItem>
          </List>
        </SwipeableDrawer>
        <LoadingBackdrop open={isLoading} />
        <ConfirmLogoutDialog
          open={showLogoutDialog}
          closeDialog={handleCloseLogoutDialog}
          onConfirm={handleConfirmLogoutDialog}
        />
        <EvaluationDialog open={showEvaluationDialog} onClose={handleCloseEvaluationDialog} />
      </Box>
    );
  }

  // desktop layout
  return (
    <StyledAppBar position="static" className={classes.root}>
      <Toolbar sx={{ position: 'relative' }}>
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item md={5} xs={10} className={classes.siteLogo}>
            {NavbarItems.logo}
            {expandSearchbar && (
              <Typography variant="h2" className={classes.lightText}>
                {NavbarItems.title}
              </Typography>
            )}
            {enableSearchbar && !expandSearchbar && NavbarItems.searchBar}
          </Grid>
          {hiddenMdUp ? null : <Grid item xs={2} />}
          <Grid item md={7} xs={12}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item xs="auto" className={classes.rightContent}>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => {
                    sendEvent('notification_from_icon');
                    if (AdminNavbar) return navigateToAdminManagement('notice');
                    return navigateToDoctorManagement('notice');
                  }}
                  onMouseEnter={() => setOpenNotice(true)}
                  onMouseLeave={() => openedNotice()}
                  size="large"
                  sx={{
                    position: 'relative',
                  }}
                >
                  <FaBell />
                  <Box
                    sx={{
                      width: '16px',
                      height: '16px',
                      bgcolor: '#F06464',
                      borderRadius: '100px',
                      position: 'absolute',
                      top: ' 5px',
                      right: '-2px',
                    }}
                  />
                </IconButton>

                {enableSelectLanguage && (
                  <>
                    <IconButton
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="menu"
                      onClick={(e) => openLanguageMenu(e)}
                      size="large"
                    >
                      <LanguageIcon sx={{ fontSize: '29px' }} />
                      <Stack alignItems="center">
                        <Typography
                          fontSize="16px"
                          fontWeight="600"
                          className={classes.languageDropdownText}
                          lineHeight="18px"
                          noWrap
                        >
                          {selectLanguage}
                        </Typography>
                        {languageMenuAnchor ? (
                          <FaChevronUp size={16} />
                        ) : (
                          <FaChevronDown size={16} />
                        )}
                      </Stack>
                    </IconButton>
                    <Menu
                      id="language-select-menu"
                      anchorEl={languageMenuAnchor}
                      keepMounted
                      open={Boolean(languageMenuAnchor)}
                      onClose={handleCloseLanguageMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      {NavbarItems.langauges.map((language) => (
                        <MenuItem onClick={() => handleSelectLanguage(language.code)}>
                          {language.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </Grid>
              <Grid item xs="auto" className={classes.rightContent}>
                <List>
                  <ListItem className={classes.doctorName}>
                    <Typography variant="h5" className={classes.lightText}>{`${user?.title ?? ''} ${
                      user?.firstname ?? ''
                    } ${user?.lastname ?? ''}`}</Typography>
                  </ListItem>
                  {enableHospitalSelection && (
                    <>
                      <ListItem
                        component={showOnlyActiveHospitalName ? undefined : Button}
                        className={classes.hospitalDropdown}
                        onClick={showOnlyActiveHospitalName ? undefined : openHospitalMenu}
                      >
                        <Grid container>
                          <Grid item>
                            <Typography className={classes.hospitalDropdownText} noWrap>
                              {`${selectedHospital?.mdp_name || NavbarItems.selectHospitalText}`}
                            </Typography>
                          </Grid>
                          {!showOnlyActiveHospitalName && (
                            <Grid
                              item
                              xs="auto"
                              className={`${classes.centerContent} ${classes.lightText}`}
                            >
                              {hospitalMenuAnchor ? <FaChevronUp /> : <FaChevronDown />}
                            </Grid>
                          )}
                        </Grid>
                      </ListItem>
                      <Menu
                        id="hospital-select-menu"
                        anchorEl={hospitalMenuAnchor}
                        keepMounted
                        open={Boolean(hospitalMenuAnchor)}
                        onClose={handleCloseHospitalMenu}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        sx={{ left: '20px' }}
                      >
                        {user?.list_mdp.map((hospital, key) => (
                          <MenuItem
                            className={classes.menuItem}
                            key={key}
                            onClick={() => {
                              handleSelectHospital(hospital);
                            }}
                          >
                            {hospital.mdp_name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </List>
              </Grid>
              <Grid item xs="auto" className={classes.rightContent}>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={(e) => {
                    openAvatarMenu(e);
                  }}
                  size="large"
                >
                  <AvatarIcon Icon={UserIconClass} iconColor={theme.palette.secondary.main} />
                </IconButton>
                <Menu
                  id="avatar-menu"
                  anchorEl={avatarMenuAnchor}
                  keepMounted
                  open={Boolean(avatarMenuAnchor)}
                  onClose={handleCloseAvatarMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {!showOnlyLogoutInAvatarMenu && (
                    <div>
                      {NavbarMenu.map((menu) => (
                        <MenuItem
                          key={menu.name}
                          onClick={() => {
                            if (menu.handler) menu.handler();
                            handleCloseAvatarMenu();
                            if (AdminNavbar) return navigateToAdminManagement(menu.name);
                            return navigateToDoctorManagement(menu.name);
                          }}
                        >
                          {menu.text}
                        </MenuItem>
                      ))}
                    </div>
                  )}
                  <MenuItem onClick={handleOpenLogoutDialog}>{NavbarItems.logoutText}</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {openNotice && (
          <Stack
            direction="column"
            gap="8px"
            onMouseOver={() => setNoticeHovered(true)}
            onMouseLeave={() => {
              setOpenNotice(false);
              setNoticeHovered(false);
            }}
            sx={{
              boxShadow: '3px 2px 13px 4px rgba(191, 191, 191, 0.25)',
              position: 'absolute',
              bgcolor: 'white',
              height: 'calc(100vh - 98px)',
              padding: '16px',
              top: '86px',
              width: '368px',
              right: '0px',
              color: 'black',
              zIndex: '100',
            }}
          >
            {(() => {
              const cutTextWithEllipsis = (text: string, maxLength = 60) => {
                if (text.length <= maxLength) {
                  return text;
                }

                return `${text.slice(0, maxLength - 3)}...`;
              };

              return (
                <>
                  {notices.map((notice) => {
                    return (
                      <Box
                        component="div"
                        key={notice.id}
                        sx={[
                          {
                            padding: '16px',
                            position: 'relative',
                            cursor: 'pointer',
                            borderRadius: '8px',
                          },
                          (theme) => ({
                            '&:hover': {
                              background: `${theme.palette.secondary.light}`,
                            },
                          }),
                        ]}
                        onClick={() => {
                          if (!roles || roles.includes('ADMIN')) return;
                          if (roles.includes('MDP_ADMIN'))
                            navigate(`/admin/user/notice#${notice.id}`);
                          else navigateToDoctorManagement('notice'); // TODO: add handle opening specific noti onclick for doctor
                        }}
                      >
                        {cutTextWithEllipsis(notice.title)}

                        <b style={{ position: 'absolute', bottom: '16px', right: '16px' }}>
                          {notice.date}
                        </b>
                      </Box>
                    );
                  })}
                </>
              );
            })()}
          </Stack>
        )}
      </Toolbar>
      {expandSearchbar && (
        <Grid container className={`${classes.horizontalCenterContent} ${classes.largeBanner}`}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item md={6} xs={8}>
            <Typography variant="h1" className={classes.lightText}>
              {NavbarItems.searchBarTitle}
            </Typography>
            {NavbarItems.searchBar}
          </Grid>
        </Grid>
      )}

      <LoadingBackdrop open={isLoading} />
      <ConfirmLogoutDialog
        open={showLogoutDialog}
        closeDialog={handleCloseLogoutDialog}
        onConfirm={handleConfirmLogoutDialog}
      />
      <EvaluationDialog open={showEvaluationDialog} onClose={handleCloseEvaluationDialog} />
    </StyledAppBar>
  );
}

export default BaseNavbar;
