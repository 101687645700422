import { accessTokenAge, sessionLength } from 'config';
import {
  setAccessToken,
  setAccessTokenAndSessionExpiredTime,
  setSessionExpiredTime,
  setShowSessionExpiredModal,
} from 'global_store/features/authen/authenSlice';
import { store } from 'global_store/store';
import { IAccessTokenPayload } from 'interface/authen';
import trueJwtDecode from 'jwt-decode';
import { getAccessToken } from 'services/authen';
import { sampleGrantDocDecodedAccessToken } from 'variables/user.sample.data';

import { mockFlag } from './mock';

const mockedJwtDecode = (token) => {
  return sampleGrantDocDecodedAccessToken;
};
const jwtDecode = mockFlag ? mockedJwtDecode : trueJwtDecode;

export function decodeAccessToken(token: string | null): IAccessTokenPayload | null {
  if (!token) {
    return null;
  }
  const decodedJWT: IAccessTokenPayload = jwtDecode(token);
  return decodedJWT;
}

export function isTokenValid(token: string | null): boolean {
  if (!token) {
    return false;
  }
  try {
    const decodedJWT: IAccessTokenPayload = jwtDecode(token);
    const currentTime = Date.now().valueOf() / 1000;
    return decodedJWT.exp > currentTime;
  } catch (error) {
    return false;
  }
}

export async function renewAccessToken() {
  /* Get Access Token */
  const { success, data } = await getAccessToken();
  if (success) {
    // store.dispatch(setAccessToken(data.access_token));
    const tokenPayload = decodeAccessToken(data.access_token);
    // store.dispatch(
    //   setSessionExpiredTime(tokenPayload ? tokenPayload.exp + (sessionLength - accessTokenAge) : 0),
    // );
    store.dispatch(
      setAccessTokenAndSessionExpiredTime({
        accessToken: data.access_token,
        decodedToken: tokenPayload,
      }),
    );
  } else if (!data.errorIsHandled) {
    console.debug('🚀 renewAccessToken | setShowSessionExpiredModal true');
    store.dispatch(setShowSessionExpiredModal(true));
  }
  return { success: true, data };
}

export async function getDefaultRequestHeaders(includeAuthorization: boolean) {
  const header = {
    'Cache-Control': `no-cache`,
    Pragma: `no-cache`,
    Expires: `0`,
    'Access-Control-Allow-Origin': `*`,
  };
  if (includeAuthorization) {
    if (!isTokenValid(store.getState().authen.accessToken)) {
      await renewAccessToken();
    }
    return {
      ...header,
      Authorization: `Bearer ${store.getState().authen.accessToken}`,
    };
  }
  return header;
}
