import ReactGA from 'react-ga4';

export const InitializeGA4 = () => {
  const GA_MEASUREMENT_ID: string = process.env.REACT_APP_TRACKING_ID;
  console.log('GA', GA_MEASUREMENT_ID);

  try {
    ReactGA.initialize(GA_MEASUREMENT_ID, {
      // testMode: true
    });
  } catch (err) {
    console.error('failed to initialize GA', err);
  }
};

export const setUserType = (userType?: string) => {
  console.log('userType', userType);
  if (userType) {
    ReactGA.gtag('set', 'user_properties', {
      userType,
    });
  }
};

export const sendEvent = (name: string, params?: {}) => {
  try {
    console.log('sendEvent', name);
    ReactGA.event(name, params ?? null);
  } catch (err) {
    console.error('failed to sendEvent GA', err);
  }
};

export const sendPageview = (path: string) => {
  try {
    ReactGA.send({
      hitType: 'pageview',
      page: path,
    });
  } catch (err) {
    console.error('failed to sensendPageviewdEvent GA', err);
  }
};
