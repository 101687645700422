import React from 'react';
import NoticeAccordion from 'components/NoticeAccordion/NoticeAccordion';
import { selectNoticeList } from 'global_store/features/authen/authenSlice';
import { useAppSelector } from 'global_store/hooks';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PREFIX = 'Notice';

const classes = {
  headerPadding: `${PREFIX}-headerPadding`,
};

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.headerPadding}`]: {
    padding: '8px 48px 0 32px',
  },
}));

function Notice(): JSX.Element {
  const noticeList = useAppSelector(selectNoticeList);

  const renderNoticeList = () => {
    return noticeList?.map((notice, key) => {
      return (
        <ListItem key={key}>
          <NoticeAccordion record={notice} />
        </ListItem>
      );
    });
  };

  return (
    <StyledList>
      <ListItem className={classes.headerPadding}>
        <Grid container spacing={2}>
          <Grid item xs={3} md={2}>
            <Typography variant="caption">DD/MM/YY</Typography>
          </Grid>
          <Grid item xs={7} md={8}>
            <Typography variant="caption">หัวข้อเรื่อง</Typography>
          </Grid>
          {/* <Grid item xs={2}>
            <Typography variant="caption">สถานะ</Typography>
          </Grid> */}
        </Grid>
      </ListItem>
      {renderNoticeList()}
    </StyledList>
  );
}

export default Notice;
