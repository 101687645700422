import React from 'react';
import LoadingBackdrop from 'components/Backdrop/LoadingBackdrop';
import GenericButton from 'components/Button/GenericButton';
import GenericInput from 'components/Input/GenericInput';
import TextWithLabel from 'components/Text/TextWithLabel';
import { selectRoles, selectUser, setUser } from 'global_store/features/authen/authenSlice';
import {
  selectSelectedHospital,
  setSelectedHospital,
} from 'global_store/features/hospital/hospitalSlice';
import {
  setErrorMessage,
  setShowErrorDialog,
  setShowSuccessDialog,
  setSuccessMessage,
} from 'global_store/features/message/messageSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import { IHospital } from 'interface';
import { IProfileUpdate } from 'interface/doctor.management';
import { SubmitHandler, useForm } from 'react-hook-form';
import { updateProfile } from 'services';
import { getCurrentUser, setActiveMdp } from 'services/authen';
import { renewAccessToken } from 'utils/authen';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

const PREFIX = 'Profile';

const classes = {
  root: `${PREFIX}-root`,
  inputStyle: `${PREFIX}-inputStyle`,
  inputLabelStyle: `${PREFIX}-inputLabelStyle`,
  noVerticalPadding: `${PREFIX}-noVerticalPadding`,
  noPaddingTop: `${PREFIX}-noPaddingTop`,
  noPaddingBottom: `${PREFIX}-noPaddingBottom`,
  hospitalListContainer: `${PREFIX}-hospitalListContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    margin: theme.spacing(1),
    marginTop: 0,
  },

  [`& .${classes.inputStyle}`]: {
    width: '90%',
    color: theme.typography.h6.color,
    fontWeight: theme.typography.h6.fontWeight,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    letterSpacing: theme.typography.h6.letterSpacing,
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.inputLabelStyle}`]: {
    color: theme.typography.caption.color,
    fontWeight: theme.typography.caption.fontWeight,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    letterSpacing: theme.typography.caption.letterSpacing,
  },

  [`& .${classes.noVerticalPadding}`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`& .${classes.noPaddingTop}`]: {
    paddingTop: 0,
  },

  [`& .${classes.noPaddingBottom}`]: {
    paddingBottom: 0,
  },

  [`& .${classes.hospitalListContainer}`]: {
    padding: '24px 0 24px 0',
  },
}));

function Profile() {
  const user = useAppSelector(selectUser);
  const roles = useAppSelector(selectRoles);
  const dispatch = useAppDispatch();
  const schema = yup.object().shape({
    email: yup.string().email('อีเมล์ไม่ถูกต้อง'),
    mobile: yup
      .string()
      .matches(
        /^(\d{8,10})?$/,
        'กรุณากรอกเบอร์โทรศัพท์เป็นตัวเลข 8-10 หลัก ห้ามมีตัวอักษร เครื่องหมาย หรือเว้นวรรค',
      ),
    work_telephone: yup
      .string()
      .matches(
        /^(\d{8,10})?$/,
        'กรุณากรอกเบอร์โทรศัพท์เป็นตัวเลข 8-10 หลัก ห้ามมีตัวอักษร เครื่องหมาย หรือเว้นวรรค',
      ),
  });
  const { control, handleSubmit, setValue, watch } = useForm<IProfileUpdate>({
    resolver: yupResolver(schema),
  });
  const selectedHospital = useAppSelector(selectSelectedHospital);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const initFormValues = React.useCallback(() => {
    setValue('title', user?.title || '-');
    setValue('fullname', user ? `${user.firstname} ${user.lastname}` : '-');
    setValue('doctorId', user ? user.username : '-');
    setValue('specialty', '-');
    setValue('email', user?.email || '');
    setValue('mobile', user?.mobile || '');
    setValue('work_telephone', user?.work_telephone || '');
  }, [user, setValue]);

  React.useEffect(() => {
    initFormValues();
  }, [initFormValues]);

  const handleSelectHospital = async (newHospital: IHospital) => {
    // Set selected hospital
    const { success, data, error } = await setActiveMdp(newHospital.mdp_id || 0);
    if (success) {
      dispatch(setSelectedHospital(newHospital));
      await renewAccessToken();
    } else if (!data.errorIsHandled) {
      dispatch(setErrorMessage(error));
      dispatch(setShowErrorDialog(true));
    }
  };

  const onSubmit: SubmitHandler<IProfileUpdate> = async (data) => {
    setIsLoading(true);
    const {
      success: updateSuccess,
      data: updateData,
      error: updateError,
    } = await updateProfile({
      title: data.title,
      firstname: user?.firstname || '',
      lastname: user?.lastname || '',
      email: data.email || (user?.email ? null : undefined), // undefined if didn't have the value before so it doesn't send the value null to replace
      mobile: data.mobile || (user?.mobile ? null : undefined), // undefined if didn't have the value before so it doesn't send the value null to replace
      workTelephone: data.work_telephone || (user?.work_telephone ? null : undefined), // undefined if didn't have the value before so it doesn't send the value null to replace
    });
    setIsLoading(false);
    if (updateSuccess) {
      const {
        success: getNewDataSuccess,
        data: newData,
        error: getNewDataError,
      } = await getCurrentUser();
      if (getNewDataSuccess) {
        dispatch(setUser(newData));
        dispatch(setSuccessMessage('การอัปเดตสำเร็จ'));
        dispatch(setShowSuccessDialog(true));
      } else if (!newData.errorIsHandled) {
        dispatch(
          setErrorMessage(
            `การอัปเดตสำเร็จแต่เกิดข้อผิดพลาดในการเรียกข้อมูล รายละเอียด: ${JSON.stringify(
              getNewDataError,
            )}`,
          ),
        );
        dispatch(setShowErrorDialog(true));
      }
    } else if (!updateData.errorIsHandled) {
      dispatch(setErrorMessage(JSON.stringify(updateError)));
      dispatch(setShowErrorDialog(true));
    }
  };

  const onInvalid = (e: any) => {
    console.log('Invalid Form');
  };

  return (
    <Root>
      <div className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <List>
            <ListItem className={classes.noPaddingTop}>
              <Typography variant="h4">ประวัติแพทย์</Typography>
            </ListItem>
            <ListItem>
              <Grid container>
                <Grid item xs={4} sm={6}>
                  <TextWithLabel label="คำนำหน้า" text={watch('title')} />
                </Grid>
                <Grid item xs={8} sm={6}>
                  <TextWithLabel label="ชื่อ-นามสกุล" text={watch('fullname')} />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem className={classes.noPaddingBottom}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <TextWithLabel label="เลขที่ใบประกอบวิชาชีพ" text={watch('doctorId')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextWithLabel label="ความชำนาญเฉพาะทาง" text={watch('specialty')} />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem className={classes.noVerticalPadding}>
              <Card elevation={0}>
                <Container className={classes.hospitalListContainer}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className={classes.inputLabelStyle}>
                      สังกัดโรงพยาบาล
                    </FormLabel>
                    <RadioGroup
                      aria-label="hospital"
                      name="selectedHospital"
                      value={selectedHospital?.mdp_name}
                      defaultValue={selectedHospital?.mdp_name}
                    >
                      {user?.list_mdp.map((hospital, key) => {
                        return (
                          <FormControlLabel
                            value={hospital.mdp_name}
                            control={<Radio />}
                            label={hospital.mdp_name}
                            id={`${key}-${hospital}`}
                            name="hospital_selected"
                            key={key}
                            onChange={() => {
                              handleSelectHospital(hospital);
                            }}
                            disabled={roles?.includes('DOCTOR_SINGLE_PATIENT') ?? false}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Container>
              </Card>
            </ListItem>
            <ListItem className={classes.noPaddingBottom}>
              <Typography variant="h4">ข้อมูลติดต่อ</Typography>
            </ListItem>
            <ListItem className={classes.noPaddingTop}>
              <Grid container>
                <Grid item xs={6}>
                  <GenericInput
                    control={control}
                    name="mobile"
                    label="เบอร์ติดต่อ"
                    defaultValue=""
                    type="text"
                    variant="generic"
                    muiVariant="standard"
                    rules={{}}
                    className={classes.inputStyle}
                  />
                </Grid>
                <Grid item xs={6}>
                  <GenericInput
                    control={control}
                    name="work_telephone"
                    label="เบอร์ติดต่อที่ทำงาน"
                    defaultValue=""
                    type="text"
                    variant="generic"
                    muiVariant="standard"
                    rules={{}}
                    className={classes.inputStyle}
                  />
                </Grid>
                <Grid item xs={6}>
                  <GenericInput
                    control={control}
                    name="email"
                    label="อีเมล"
                    defaultValue=""
                    type="text"
                    variant="generic"
                    muiVariant="standard"
                    rules={{}}
                    className={classes.inputStyle}
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <GenericButton size="small" type="submit" style={{ width: '160px' }}>
                อัปเดต
              </GenericButton>
            </ListItem>
          </List>
        </form>
      </div>
      <LoadingBackdrop open={isLoading} />
    </Root>
  );
}

export default Profile;
