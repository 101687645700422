import { accessTokenAge, sessionLength } from 'config';
import {
  setAccessToken,
  setAccessTokenAndSessionExpiredTime,
  setRoles,
  setSessionExpiredTime,
  setUser,
} from 'global_store/features/authen/authenSlice';
import { setSelectedHospital } from 'global_store/features/hospital/hospitalSlice';
import { setPatientID } from 'global_store/features/patient/patientSlice';
import { IHospital } from 'interface';
import { getAccessToken, getCurrentUser } from 'services/authen';
import { decodeAccessToken } from 'utils/authen';

export const initializeApp = async (
  dispatch,
  grantToken = undefined,
  onGetAccessTokenError = undefined,
): Promise<boolean> => {
  const accessTokenRes = await getAccessToken(grantToken);
  if (!accessTokenRes.success) {
    if (onGetAccessTokenError) onGetAccessTokenError(accessTokenRes);
    return false;
  }
  const tokenPayload = decodeAccessToken(accessTokenRes.data.access_token);
  // dispatch(setAccessToken(accessTokenRes.data.access_token));
  // const expiredTime = tokenPayload ? tokenPayload.exp + (sessionLength - accessTokenAge) : 0;
  // dispatch(setSessionExpiredTime(expiredTime));
  dispatch(
    setAccessTokenAndSessionExpiredTime({
      accessToken: accessTokenRes.data.access_token,
      decodedToken: tokenPayload,
    }),
  );
  const userRes = await getCurrentUser();
  if (!userRes.success) {
    return false;
  }
  dispatch(setUser(userRes.data));
  // Set selected hospital based on access token
  // Decode token to get roles. this could be [DOCTOR_SINGLE_PATIENT] roles
  dispatch(setRoles(tokenPayload?.roles || null));
  dispatch(setPatientID(tokenPayload?.patient_id || null)); // decoded access token patient_id could be null

  const userHospital = (userRes.data.list_mdp as IHospital[]).filter(
    (record) => record.mdp9_id === tokenPayload?.mdp_active,
  );
  console.debug('🚀 | userHospital', userHospital);
  if (userHospital.length > 0) {
    dispatch(setSelectedHospital(userHospital[0]));
  }

  return true;
};
