import React from 'react';

import { Container } from '@mui/material';

function Manual() {
  return (
    <Container>
      <h1>Manual</h1>
    </Container>
  );
}

export default Manual;
