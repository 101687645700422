import type { RootState } from 'global_store/store';
import { IImmunizationEntry } from 'interface/fhir.resource';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface immunizationState {
  immunizationExpansion: boolean[];
  immunizationList: IImmunizationEntry[];
  immunizationOffset: number;
  expansion: any;
}

const initialState: immunizationState = {
  immunizationExpansion: [],
  immunizationList: [],
  immunizationOffset: 0,
  expansion: [],
};

export const immunizationSlice = createSlice({
  name: 'immunization',
  initialState,
  reducers: {
    clearImmunizationSlice: (state) => {
      // Reset state to initial state or any other desired state
      Object.assign(state, initialState);
    },
    setImmunizationExpansion: (state, action: PayloadAction<any | null>) => {
      state.immunizationExpansion = action.payload;
    },
    setImmunizationList: (state, action: PayloadAction<any | null>) => {
      state.immunizationList = action.payload;
    },
    setImmunizationOffset: (state, action: PayloadAction<any | null>) => {
      state.immunizationOffset = action.payload;
    },
    setExpansion: (state, action: PayloadAction<any | null>) => {
      state.expansion = action.payload;
    },
  },
});

export const {
  clearImmunizationSlice,
  setImmunizationExpansion,
  setImmunizationList,
  setImmunizationOffset,
  setExpansion,
} = immunizationSlice.actions;

export const selectImmunizationExpansion = (state: RootState) =>
  state.immunization.immunizationExpansion;
export const selectImmunizationList = (state: RootState) => state.immunization.immunizationList;
export const selectImmunizationOffset = (state: RootState) => state.immunization.immunizationOffset;

export default immunizationSlice.reducer;
