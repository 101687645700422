import { ICategoryParam, IHospital, IPeriodParam } from 'interface';

export const useNewLabApi = false; // change this to true when backend is ready
export const useNewLabSampleData = false;
export const useChronicDiseaseApi = false;
export const useChronicDiseaseSampleData = false;
export const useVitalSignApi = false; // change this to true when backend is ready
export const useVitalSignSampleData = false;
export const useVisitMediaApi = false; // change this to true when backend is ready
export const useVisitMediaSampleData = false;
export const pageSize = 10;
export const defaultPeriodParam: IPeriodParam = {
  startDate: null,
  endDate: null,
  periodType: 'all-time',
};
export const defaultHospitalParam: IHospital[] | null = null;
export const defaultVisitTypeParam: string[] | null = null;
export const defaultCategoryParam: ICategoryParam = {
  medication: false,
  procedure: false,
  diagnosis: false,
  lab: false,
};
export const patientInfoRoutePrefix = '/console/patient';
export const defaultResourceExpand = false;
