import React from 'react';
import { ITabMeta } from 'interface';
import { FaBook, FaDesktop, FaKey, FaUserMd } from 'react-icons/fa';

import { DashboardCustomize } from '@mui/icons-material';

// import ManageMDN from 'pages/console/SystemAdminManagement/ManageMDN';
// import ManageMDP from 'pages/console/SystemAdminManagement/ManageMDP';
// import ManageDoctor from 'pages/console/SystemAdminManagement/ManageDoctor';
// import ManageMDPAdmin from 'pages/console/SystemAdminManagement/ManageMDPAdmin';
// import SystemLog from 'pages/console/SystemAdminManagement/SystemLog';
// import ExchangeHistory from 'pages/console/SystemAdminManagement/ExchangeHistory';
// import DeviceManagement from 'pages/console/SystemAdminManagement/DeviceManagement';
// import APIKeyManagement from 'pages/console/SystemAdminManagement/APIKeyManagement';
// import FHIRDataAccessLog from 'pages/console/SystemAdminManagement/FHIRDataAccessLog';
// import ConsentRequestLog from 'pages/console/SystemAdminManagement/ConsentRequestLog';
// import ExchangeZone from 'pages/console/SystemAdminManagement/ExchangeZone';

const Dashboard = React.lazy(() => import('pages/console/SystemAdminManagement/Dashboard'));
const ManageMDP = React.lazy(() => import('pages/console/SystemAdminManagement/ManageMDP'));
const ManageDoctor = React.lazy(() => import('pages/console/SystemAdminManagement/ManageDoctor'));
const ManageMDPAdmin = React.lazy(
  () => import('pages/console/SystemAdminManagement/ManageMDPAdmin'),
);
const ManageMDN = React.lazy(() => import('pages/console/SystemAdminManagement/ManageMDN'));
const SystemLog = React.lazy(() => import('pages/console/SystemAdminManagement/SystemLog'));
const ExchangeHistory = React.lazy(
  () => import('pages/console/SystemAdminManagement/ExchangeHistory'),
);
const DeviceManagement = React.lazy(
  () => import('pages/console/SystemAdminManagement/DeviceManagement'),
);
const APIKeyManagement = React.lazy(
  () => import('pages/console/SystemAdminManagement/APIKeyManagement'),
);
const FHIRDataAccessLog = React.lazy(
  () => import('pages/console/SystemAdminManagement/FHIRDataAccessLog'),
);
const ConsentRequestLog = React.lazy(
  () => import('pages/console/SystemAdminManagement/ConsentRequestLog'),
);
const ExchangeZone = React.lazy(() => import('pages/console/SystemAdminManagement/ExchangeZone'));

export const ROUTE_PREFIX = '/admin/home';

export const TAB_LIST: ITabMeta[] = [
  {
    iconComponent: DashboardCustomize,
    tabName: 'Dashboard',
    tabKey: 'dashboard',
    tabPanelComponent: Dashboard,
  },
  {
    iconComponent: FaUserMd,
    tabName: 'Manage MDN',
    tabKey: 'manage-mdn',
    tabPanelComponent: ManageMDN,
  },
  {
    iconComponent: FaUserMd,
    tabName: 'Manage MDP',
    tabKey: 'manage-mdp',
    tabPanelComponent: ManageMDP,
  },
  {
    iconComponent: FaUserMd,
    tabName: 'Manage MDP Admin',
    tabKey: 'manage-mdp-admin',
    tabPanelComponent: ManageMDPAdmin,
  },
  {
    iconComponent: FaUserMd,
    tabName: 'Manage Doctor',
    tabKey: 'manage-doctor',
    tabPanelComponent: ManageDoctor,
  },
  {
    iconComponent: FaUserMd,
    tabName: 'System Log',
    tabKey: 'system-log',
    tabPanelComponent: SystemLog,
  },
  {
    iconComponent: FaUserMd,
    tabName: 'Exchange History',
    tabKey: 'exchange-history',
    tabPanelComponent: ExchangeHistory,
  },
  {
    iconComponent: FaDesktop,
    tabName: 'Manage Device',
    tabKey: 'device',
    tabPanelComponent: DeviceManagement,
  },
  {
    iconComponent: FaKey,
    tabName: 'API Keys',
    tabKey: 'api-key',
    tabPanelComponent: APIKeyManagement,
  },
  {
    iconComponent: FaBook,
    tabName: 'FHIR Data Access Log',
    tabKey: 'fhir-data-access-log',
    tabPanelComponent: FHIRDataAccessLog,
  },
  {
    iconComponent: FaBook,
    tabName: 'Consent Request Log',
    tabKey: 'consent-request-log',
    tabPanelComponent: ConsentRequestLog,
  },
  {
    iconComponent: FaBook,
    tabName: 'Exchange Zone',
    tabKey: 'exchange',
    tabPanelComponent: ExchangeZone,
  },
];

export const MDN_TABLE_COLUMNS = [
  {
    Header: 'MDN9ID',
    accessor: 'mdn9_id',
  },
  {
    Header: 'Name',
    accessor: 'mdn_name',
  },
  {
    Header: 'Name (English)',
    accessor: 'mdn_name_en',
  },
  {
    Header: 'Info',
    accessor: 'mdn_info',
  },
  {
    Header: 'Manage',
    accessor: 'actions',
  },
];

export const MDP_TABLE_COLUMNS = [
  {
    Header: 'MDP9ID',
    accessor: 'mdp9_id',
  },
  {
    Header: 'Consent Type',
    accessor: 'consent_type',
  },
  {
    Header: 'Name',
    accessor: 'mdp_name',
  },
  {
    Header: 'Name (English)',
    accessor: 'mdp_name_en',
  },
  {
    Header: 'MDN9ID',
    accessor: 'mdn9_id',
  },
  {
    Header: 'MDN Name',
    accessor: 'mdn_name',
  },
  {
    Header: 'Info',
    accessor: 'mdp_info',
  },
  {
    Header: 'Manage',
    accessor: 'actions',
  },
];

export const MDP_ADMIN_USER_TABLE_COLUMNS = [
  {
    Header: 'Username',
    accessor: 'username',
  },
  {
    Header: 'Active',
    accessor: 'active',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'First Name',
    accessor: 'firstname',
  },
  {
    Header: 'Last Name',
    accessor: 'lastname',
  },
  {
    Header: 'Manage',
    accessor: 'actions',
  },
];

export const DOCTOR_USER_TABLE_COLUMNS = [
  {
    Header: 'Medical Certificate Number',
    accessor: 'username',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'First Name',
    accessor: 'firstname',
  },
  {
    Header: 'Last Name',
    accessor: 'lastname',
  },
  {
    Header: 'Manage',
    accessor: 'actions',
  },
];

export const MDP_ADMIN_LOG_TABLE_COLUMNS = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
  },
  {
    Header: 'MDP Admin (Fullname)',
    accessor: 'mdpAdminFullname',
  },
  {
    Header: 'Doctor (Medical Certificate Number)',
    accessor: 'doctorFullname',
  },
  {
    Header: 'Hospital (MDP9ID)',
    accessor: 'hospitalName',
  },
  {
    Header: 'Activity',
    accessor: 'activity',
  },
];

export const DOCTOR_USAGE_TABLE_COLUMNS = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
  },
  {
    Header: 'Doctor (Medical Certificate Number)',
    accessor: 'doctorFullname',
  },
  {
    Header: 'Patient (CID)',
    accessor: 'patientFullname',
  },
  {
    Header: 'Hospital (MDP9ID)',
    accessor: 'hospitalName',
  },
];

export const EXCHANGE_HISTORY_TABLE_COLUMNS = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
  },
  {
    Header: 'Hospital Name (mdp9id)',
    accessor: 'hospitalName',
  },
  {
    Header: 'Job Id',
    accessor: 'jobId',
  },
  {
    Header: 'Number of IPS',
    accessor: 'ipsCount',
  },
  {
    Header: 'Number of Visits',
    accessor: 'visitCount',
  },
  {
    Header: 'Manage',
    accessor: 'actions',
  },
];

export const DEVICE_TABLE_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'mdp_ip_address_id',
  },
  {
    Header: 'MDP ID',
    accessor: 'mdp_id',
  },
  {
    Header: 'IP address',
    accessor: 'ip_address',
  },
  {
    Header: 'Manage',
    accessor: 'actions',
  },
];

export const FHIR_DATA_ACCESS_LOG_TABLE_COLUMNS = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
  },
  {
    Header: 'Doctor (Medical Certificate Number)',
    accessor: 'doctor_name',
  },
  {
    Header: 'MDP9ID',
    accessor: 'doctor_mdp9id',
  },
  {
    Header: 'Patient (CID)',
    accessor: 'patient_name',
  },
  {
    Header: 'Entry Type',
    accessor: 'entry_type',
  },
];

export const CONSENT_REQUEST_LOG_TABLE_COLUMNS = [
  {
    Header: 'Patient (CID)',
    accessor: 'patient_name',
  },
  {
    Header: 'Doctor (Medical Number)',
    accessor: 'doctor_name',
  },
  {
    Header: 'Hospital (MDP9ID)',
    accessor: 'mdp_name',
  },
  {
    Header: 'Allowed Date',
    accessor: 'allow_date',
  },
];
