import React from 'react';
import GenericInput from 'components/Input/GenericInput';
import { setErrorMessage, setShowErrorDialog } from 'global_store/features/message/messageSlice';
import { useAppDispatch } from 'global_store/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaArrowCircleRight } from 'react-icons/fa';
import { LoadCanvasTemplate, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

interface CaptchaInputForm {
  username: string;
  password: string;
  captcha: string;
}

interface CaptchaProps {
  onSubmit: any;
  onInvalid: any;
  classes: any;
}

function Captcha({ onSubmit, onInvalid, classes }: CaptchaProps): JSX.Element {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const schema = yup
    .object()
    .shape({
      username: yup.string().required('กรุณาใส่ชื่อผู้ใช้งาน'),
      password: yup.string().required('กรุณาใส่รหัสผ่าน'),
      captcha: yup.string().required('กรุณากรอก captcha'),
    })
    .required();
  const { control, handleSubmit } = useForm<CaptchaInputForm>({
    resolver: yupResolver(schema),
  });

  const onSubmitCaptcha: SubmitHandler<CaptchaInputForm> = (captchaInput) => {
    if (validateCaptcha(captchaInput.captcha) === true) {
      onSubmit(captchaInput);
      loadCaptchaEnginge(6);
    } else {
      dispatch(setErrorMessage('Captcha ไม่ถูกต้อง'));
      dispatch(setShowErrorDialog(true));
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        className={`${classes.centerAllContent} ${classes.margin} ${classes.adminLogin}`}
      >
        <Typography variant="h6" className={classes.marginContent}>
          ไม่ใช่แพทย์ กรุณาล็อกอินที่นี่:
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmitCaptcha, onInvalid)}>
          <GenericInput
            control={control}
            name="username"
            label="username"
            type="text"
            defaultValue=""
            variant="generic"
            rules={{}}
            className={classes.marginContent}
          />
          <GenericInput
            control={control}
            name="password"
            label="password"
            type="password"
            defaultValue=""
            variant="generic"
            rules={{}}
            className={classes.marginContent}
          />
          <IconButton type="submit" size="large">
            <FaArrowCircleRight className={classes.adminLoginButton} />
          </IconButton>
        </form>
      </Grid>
      <Grid
        item
        xs={12}
        className={`${classes.centerAllContent} ${classes.margin} ${classes.adminLogin} ${classes.longBottomMargin}`}
      >
        <LoadCanvasTemplate />
        <GenericInput
          control={control}
          name="captcha"
          label="captcha"
          type="text"
          defaultValue=""
          variant="generic"
          rules={{}}
          className={classes.marginContent}
        />
      </Grid>
    </>
  );
}

export default Captcha;
