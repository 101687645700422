import { Suspense } from 'react';
import ErrorMessageDialog from 'components/Dialog/ErrorMessageDialog';
import SuccessMessageDialog from 'components/Dialog/SuccessMessageDialog';
import ConsoleFooter from 'components/Footer/ConsoleFooter';
import BaseNavbar from 'components/Navbar/BaseNavbar';
import SessionTimer from 'components/Timer/SessionTimer';
import { selectRoles } from 'global_store/features/authen/authenSlice';
import {
  selectErrorMessage,
  selectShowErrorDialog,
  selectShowSuccessDialog,
  selectSuccessMessage,
  setErrorMessage,
  setShowErrorDialog,
  setShowSuccessDialog,
  setSuccessMessage,
} from 'global_store/features/message/messageSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import { Outlet, useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';

const PREFIX = 'AdminConsoleLayout';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: '#F1FFFB',
  },
}));

function AdminLayout(): JSX.Element {
  console.debug('🚀 | AdminLayout | AdminLayout:');
  const dispatch = useAppDispatch();
  const showErrorDialog = useAppSelector(selectShowErrorDialog);
  const errorMessage = useAppSelector(selectErrorMessage);
  const showSuccessDialog = useAppSelector(selectShowSuccessDialog);
  const successMessage = useAppSelector(selectSuccessMessage);
  const userRoles = useAppSelector(selectRoles);
  const location = useLocation();

  const handleCloseErrorDialog = () => {
    dispatch(setShowErrorDialog(false));
    dispatch(setErrorMessage(null));
  };

  const handleCloseSuccessDialog = () => {
    dispatch(setShowSuccessDialog(false));
    dispatch(setSuccessMessage(null));
  };

  return (
    <Root className={classes.root}>
      {/* <SessionTimer /> */}
      <ErrorMessageDialog
        open={showErrorDialog}
        onClose={handleCloseErrorDialog}
        errorMessage={errorMessage}
      />
      <SuccessMessageDialog
        open={showSuccessDialog}
        onClose={handleCloseSuccessDialog}
        message={successMessage}
      />
      {userRoles?.includes('ADMIN') /* system admin */ ? (
        <BaseNavbar logoNavigateTo="/admin/home" showOnlyLogoutInAvatarMenu />
      ) : (
        <BaseNavbar
          logoNavigateTo="/admin/home"
          AdminNavbar
          enableHospitalSelection
          showOnlyActiveHospitalName
          enableEvaluation
          evaluationChance={0.5}
        />
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
        <ConsoleFooter />
      </Suspense>
    </Root>
  );
}

export default AdminLayout;
