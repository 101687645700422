import React, { useCallback, useEffect, useRef } from 'react';
import {
  selectJmgntWsToken,
  selectUser,
  setJmgntWsConnected,
  setJmgntWsToken,
} from 'global_store/features/authen/authenSlice';
import {
  mergeNewProgress,
  setPatientProgressInfo,
} from 'global_store/features/jobMgnt/jobMgntSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import { ProgressInfoResData } from 'interface/forceUpdate';
import { getWsToken } from 'services/job-mgnt';
import { Manager } from 'socket.io-client';
import { formatRawWsProgress } from 'utils/forceUpdate';

export enum SocketEvent {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  CONNECT_ERROR = 'connect_error',
  REQ = 'req',
  PROGRESS = 'progress',
}

// NOTE: could change from direct ip to proxy if know how
const manager = new Manager(process.env.REACT_APP_JMGMT_WS_URL, {
  reconnectionDelayMax: 10000,
  autoConnect: false,
});

export const socket = manager.socket('/ws-events', {
  auth: {
    token: null,
  },
});

/**
 * IMPORTANT: only used in App, so it's setup only once.
 */
const useWebSocket = () => {
  const jmgntWsToken = useAppSelector(selectJmgntWsToken);
  const user = useAppSelector(selectUser);
  const hasEmitGetJobOnLoad = useRef(false);

  const dispatch = useAppDispatch();

  const handleGetJmgntWsToken = useCallback(async () => {
    if (!user?.user_id) return;
    const { success, data, error } = await getWsToken();
    if (success) {
      // console.log('getJmgntWsToken data', data);
      dispatch(setJmgntWsToken(data.ws_token ?? null));
    } else {
      console.error('getJmgntWsToken error', error);
    }
  }, [user?.user_id]);

  // vvvvvvvvv NOTE: not doing set job management token onload app in release/1.9 but will in the force update button release
  // useEffect(() => {
  //   handleGetJmgntWsToken();
  // }, [handleGetJmgntWsToken]);

  useEffect(() => {
    socket.on(SocketEvent.CONNECT, () => {
      console.debug('🚨 ws connect', {
        socketId: socket.id,
        hasEmitGetJobOnLoad: hasEmitGetJobOnLoad.current,
      });
      dispatch(setJmgntWsConnected(true));
      // TODO: emit get all job progress (getJobStatus)
      // vvvvvvvvv NOTE: not doing set job management token onload app in release/1.9 but will in the force update button release
      // if (!hasEmitGetJobOnLoad.current) {
      //   console.debug('🚨 call emit req getJobStatus');
      //   socket.emit('req', JSON.stringify({ cmd: 'getJobStatus' })); // this works
      //   hasEmitGetJobOnLoad.current = true;
      // }
    });

    socket.on(SocketEvent.DISCONNECT, () => {
      console.debug('🚨 ws disconnect');
      dispatch(setJmgntWsConnected(false));
    });

    socket.on(SocketEvent.CONNECT_ERROR, (error) => {
      console.debug('🚨 ws connect_error', { error });
      if (error?.message === 'FORBIDDEN') {
        console.log('ws FORBIDDEN');
        // socket.disconnect();
        // TODO: handle connect_error
      }
      dispatch(setJmgntWsConnected(false));
    });

    socket.on(SocketEvent.PROGRESS, (data: ProgressInfoResData) => {
      console.debug('🚨 ws progress', { data });
      // TODO: double check progress data
      dispatch(mergeNewProgress([formatRawWsProgress(data)]));
    });

    socket.onAny((eventName, ...args) => {
      console.debug('🚨 ws catch-all listeners', { eventName, args });
    });

    return () => {
      if (socket) {
        socket.removeAllListeners();
        console.debug('🚨 call ws disconnect');
        socket.disconnect();
      }
      dispatch(setJmgntWsToken(null));
      hasEmitGetJobOnLoad.current = false;
    };
  }, []);

  // when token changes, it should reconnect
  useEffect(() => {
    if (jmgntWsToken) {
      if (socket.connected && (socket.auth as any).token !== jmgntWsToken) socket.disconnect();
      console.debug('🚨 set auth token for ws to ', jmgntWsToken);
      (socket.auth as any).token = jmgntWsToken;
      socket.connect();
    } else if (socket.connected) {
      socket.disconnect();
    }
  }, [jmgntWsToken]);
};

export default useWebSocket;
