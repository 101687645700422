import SessionExpiredIcon from 'assets/img/sessionExpired.svg';
import {
  selectShowSessionExpiredModal,
  setShowSessionExpiredModal,
  setUser,
} from 'global_store/features/authen/authenSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import BaseDialog from './Base/BaseDialog';

const PREFIX = 'SessionExpiredDialog';

const classes = {
  centerAllContent: `${PREFIX}-centerAllContent`,
  expiredIcon: `${PREFIX}-expiredIcon`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.centerAllContent}`]: {
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  [`& .${classes.expiredIcon}`]: {
    width: '100px',
    height: '100px',
  },
}));

function SessionExpiredDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const open = useAppSelector(selectShowSessionExpiredModal);

  const handleClose = () => {
    dispatch(setShowSessionExpiredModal(false));
    dispatch(setUser(null));
    navigate('/page/login');
  };

  return (
    <BaseDialog
      open={open}
      maxWidth="sm"
      handleClose={handleClose}
      withOkButton
      disableCloseOnClickBackdrop
      fullWidth
    >
      <StyledBox>
        <Typography variant="h2" color="error" className={classes.centerAllContent}>
          <img src={SessionExpiredIcon} alt="HIE Logo" className={classes.expiredIcon} />
        </Typography>
        <Typography variant="h2" sx={{ marginTop: '10px' }} className={classes.centerAllContent}>
          หมดเวลาการใช้งานระบบฯ
        </Typography>
        <Typography
          variant="h2"
          sx={{ fontSize: '24px', marginTop: '10px' }}
          className={classes.centerAllContent}
          component="p"
        >
          {`ท่านไม่ได้ใช้งานระบบฯ เป็นเวลา 30 นาที \nกรุณาล็อกอินใหม่อีกครั้ง`}
        </Typography>
      </StyledBox>
    </BaseDialog>
  );

  // return (
  //   <StyledDialog open={open} maxWidth="sm" PaperProps={{ className: `${classes.dialog}` }}>
  //     <Container className={classes.dialogContent}>
  //       <Typography variant="h2" color="error" className={classes.centerAllContent}>
  //         <FaRegClock />
  //       </Typography>
  //       <Typography variant="h2" color="error" className={classes.centerAllContent}>
  //         กรุณาล็อกอินใหม่อีกครั้ง
  //       </Typography>
  //       <Typography variant="overline" className={classes.centerAllContent} component="p">
  //         เราไม่พบการใช้งานของคุณเป็นเวลา 30 นาที
  //       </Typography>
  //       <Typography variant="overline" className={classes.centerAllContent} component="p">
  //         เพื่อความปลอดภัย เราขอให้คุณล็อกอินใหม่อีกครั้ง
  //       </Typography>
  //       <Container className={classes.dialogButton}>
  //         <GenericButton onClick={handleClose} fullWidth size="small">
  //           ตกลง
  //         </GenericButton>
  //       </Container>
  //     </Container>
  //   </StyledDialog>
  // );
}

export default SessionExpiredDialog;
