import { AxiosError } from 'axios';
import { commonErrors } from 'config/errors';
import { setErrorMessage, setShowErrorDialog } from 'global_store/features/message/messageSlice';
import { store } from 'global_store/store';
import { LookupError } from 'interface/errors';
import { IErrorResponseData } from 'interface/service';

export const handleGlobalError = (
  error: AxiosError<IErrorResponseData>,
  markErrorIsHandled = true,
) => {
  const { response } = error;
  const resErrDetail = response.data.detail ?? response.data.message;
  const commonErr = commonErrors.find((er) => {
    return (
      er.code === response.status &&
      (!er.detail || resErrDetail?.toLowerCase().includes(er.detail.toLowerCase()))
    );
  });
  if (commonErr && commonErr.display) {
    store.dispatch(setErrorMessage(commonErr.display));
    store.dispatch(setShowErrorDialog(true));
    if (markErrorIsHandled) error.response.data.errorIsHandled = true;
  }
  return error;
};

export function formatError(
  lookup: LookupError[],
  errorCode: number,
  errorDetail: string,
  useRawErrorIfNotMatched: boolean,
): LookupError['display'] | string | null {
  const fErr = lookup.find(
    (er) =>
      er.code === errorCode &&
      (!er.detail || errorDetail.toLowerCase().includes(er.detail.toLowerCase())),
  );
  if (!fErr) return useRawErrorIfNotMatched ? errorDetail : null;
  return fErr.display;
}
