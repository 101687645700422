import type { RootState } from 'global_store/store';
import { IDiagnosticReport, IObservation } from 'interface/fhir.resource';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LabState {
  observationList: IObservation[];
  labOffset: number;
  diagnosticReportList: IDiagnosticReport[];
}

const initialState: LabState = {
  observationList: [],
  labOffset: 0,
  diagnosticReportList: [],
};

export const labSlice = createSlice({
  name: 'lab',
  initialState,
  reducers: {
    clearLabSlice: (state) => {
      // Reset state to initial state or any other desired state
      Object.assign(state, initialState);
    },
    setObservationList: (state, action: PayloadAction<LabState['observationList']>) => {
      state.observationList = action.payload;
    },
    setObservationOffset: (state, action: PayloadAction<any | null>) => {
      state.labOffset = action.payload;
    },
    setDiagnosticReportList: (state, action: PayloadAction<LabState['diagnosticReportList']>) => {
      state.diagnosticReportList = action.payload;
    },
  },
});

export const { clearLabSlice, setObservationList, setObservationOffset, setDiagnosticReportList } =
  labSlice.actions;

export const selectObservationList = (state: RootState) => state.lab.observationList;
export const selectDiagnosticReportList = (state: RootState) => state.lab.diagnosticReportList;
export const selectLabOffset = (state: RootState) => state.lab.labOffset;

export default labSlice.reducer;
