import axios, { AxiosError } from 'axios';
import {
  useChronicDiseaseApi,
  useChronicDiseaseSampleData,
  useNewLabSampleData,
} from 'config/patient-info';
import { addYears, differenceInYears } from 'date-fns';
import {
  ICategoryParam,
  IDemographic,
  IFHIRDataInfo,
  IHospital,
  IHospitalConsent,
  IPeriodParam,
  IResourceCount,
} from 'interface';
import {
  IAllergyIntoleranceSearchSet,
  ICompositionSearchSet,
  IConditionSearchSet,
  IDiagnosticReportSearchSet,
  IFHIRSearchSet,
  IImmunizationSearchSet,
  IMedicationStatementSearchSet,
  IObservationSearchSet,
  IProcedureSearchSet,
  IRequestEntry,
} from 'interface/fhir.resource';
import { ISearchResult, ISearchResultRecord } from 'interface/logging';
import {
  FhirDataPullingJobStatus,
  RunFhirDataFromClientResponse,
  RunFhirDataFromServerResponse,
} from 'interface/schema';
import { IFormattedError } from 'interface/service';
import { getDefaultRequestHeaders } from 'utils/authen';
import { mockFlag } from 'utils/mock';
import {
  createAllergyIntoleranceRequestEntry,
  createCompositionRequestEntry,
  createConditionRequestEntry,
  createDiagnosticReportRequestEntry,
  createImmunizationRequestEntry,
  createLabRequestEntry,
  createMedicationStatementRequestEntry,
  createObservationRequestEntry,
  createProcedureRequestEntry,
  decryptData,
  generateKeyPair,
  getSymmetricKey,
} from 'utils/patient';
import { sampleChronicDisease } from 'variables/chronic.sample.data';
import { sampleLab } from 'variables/fhir.sample.data';
import { samplePatientDemographic } from 'variables/patient.sample.data';

import api from './api';

const getIdentifierFromFilteredMdp9Ids = (
  consentedWithDataMdp9Ids: IHospitalConsent['mdp9_id'][],
  hospitalParam: IHospital[] | null,
): string[] => {
  const mdp9idList = hospitalParam?.map((record) => record.mdp9_id);
  return consentedWithDataMdp9Ids
    ?.filter((mdp9Id) => !mdp9idList || mdp9idList.length === 0 || mdp9idList?.includes(mdp9Id))
    .map((mdp9Id) => `${mdp9Id}`);
  // .join(',');
};

export const getPatientListByKeyword = async ({
  keyword,
  page,
  pageSize,
}: {
  keyword: string;
  page: number;
  pageSize: number;
}): Promise<{ success: true; data: ISearchResult; error: null } | IFormattedError> => {
  const currentDate = new Date();
  try {
    const res = await api.get('/api/patient/doctor-search', {
      headers: await getDefaultRequestHeaders(true),
      params: {
        keyword,
      },
    });
    return {
      success: true,
      data: {
        total: res.data.length,
        currentPage: page,
        pageSize,
        records: res.data.map((record: any) => {
          if (!record.dob) {
            return { ...record, birthdate: null, age: 0 };
          }
          const birthdate = `${record.dob}`;
          const parsedDoB = new Date(birthdate);
          const age = differenceInYears(
            currentDate,
            parsedDoB.getFullYear() > 2100 ? addYears(parsedDoB, -543) : parsedDoB,
          );
          return { ...record, birthdate, age };
        }),
      },
      error: null,
    };
  } catch (err: any) {
    console.log(err.response.data);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const getDemographic = async (
  cid?: string,
  patient_id?: number,
): Promise<{ success: true; data: IDemographic | null; error: null } | IFormattedError> => {
  try {
    let res;
    if (mockFlag) {
      res = {};
      res.data = samplePatientDemographic;
    } else {
      res = await api.post(
        '/api/patient/demographic',
        { cid, patient_id },
        {
          headers: await getDefaultRequestHeaders(true),
        },
      );
    }

    // vvv use this to test
    // res.data.app_list = 'DOPA'; // KTB,DOPA
    // vvvv use this to test
    // res.data.email = null;
    // res.data.is_email_verified = false;
    if (!res.data.dob) {
      return {
        success: true,
        data: {
          ...res.data,
          citizenId: res.data.cid,
          age: 0,
          birthdate: null,
        },
        error: null,
      };
    }
    const dob = new Date(res.data.dob);
    const age = differenceInYears(new Date(), dob.getFullYear() > 2100 ? addYears(dob, -543) : dob);
    return {
      success: true,
      data: {
        ...res.data,
        citizenId: res.data.cid,
        age,
        birthdate: res.data.dob,
      },
      error: null,
    };
  } catch (err: any) {
    console.log(err.response.data);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const requestFHIRData = async (
  requestEntry: IRequestEntry[],
): Promise<{ success: true; data: any; error: null } | IFormattedError> => {
  const keypair = generateKeyPair();
  try {
    const res = await api.post(
      '/api/fhir',
      {
        resourceType: 'Bundle',
        type: 'batch',
        entry: requestEntry,
      },
      {
        headers: {
          ...(await getDefaultRequestHeaders(true)),
          'X-PublicKey': keypair.publicKey,
        },
      },
    );
    const iv = Buffer.from(res.headers.iv, 'base64');
    const key = Buffer.from(getSymmetricKey(keypair.privateKey, res.headers.pbkey), 'base64');
    const encryptedData = Buffer.from(res.data.encrypted, 'base64');
    const decryptedData = JSON.parse(decryptData(encryptedData, key, iv));
    return { success: true, data: decryptedData, error: null };
  } catch (err: any) {
    console.error('🚀 | requestFHIRData err:', err, 'isAxiosError', axios.isAxiosError(err));
    if (axios.isAxiosError(err)) {
      return {
        success: false,
        data: err.response.data,
        error: (err as AxiosError).response?.status,
      };
    }
    return {
      success: false,
      data: err.response?.data ?? { detail: err.message, errorIsHandled: false },
      error: err.message || err, // not sure, might just want to return err?
    };
  }
};

export const getAllergyIntolerance = async (
  periodParam: IPeriodParam | null,
  hospitalParam: IHospital[] | null,
  consentedWithDataMdp9Ids: IHospitalConsent['mdp9_id'][] | null,
  patientCid: string,
  count: number,
  offset: number,
): Promise<
  | {
      success: true;
      data: IAllergyIntoleranceSearchSet;
      error: null;
    }
  | IFormattedError
> => {
  const identifer = getIdentifierFromFilteredMdp9Ids(consentedWithDataMdp9Ids, hospitalParam);
  if (!identifer.length) {
    return {
      success: true,
      data: {
        resourceType: 'Bundle',
        type: 'searchset',
        entry: [],
      },
      error: null,
    };
  }
  const requestEntryList: IRequestEntry[] = [
    createAllergyIntoleranceRequestEntry(identifer, periodParam, patientCid, count, offset),
  ];
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const allergyData: IAllergyIntoleranceSearchSet =
      data.entry && data.entry.length > 0 ? data.entry[0].resource : null;
    // console.debug('🚀 | allergyData:', allergyData, 'requestEntryList', requestEntryList);
    return { success: true, data: allergyData, error: null };
  }
  return { success: false, data, error };
};

export const getImmunization = async (
  periodParam: IPeriodParam | null,
  hospitalParam: IHospital[] | null,
  consentedWithDataMdp9Ids: IHospitalConsent['mdp9_id'][] | null,
  patientCid: string,
  count: number,
  offset: number,
): Promise<
  | {
      success: true;
      data: IImmunizationSearchSet;
      error: null;
    }
  | IFormattedError
> => {
  const identifer = getIdentifierFromFilteredMdp9Ids(consentedWithDataMdp9Ids, hospitalParam);
  if (!identifer.length) {
    return {
      success: true,
      data: {
        resourceType: 'Bundle',
        type: 'searchset',
        entry: [],
      },
      error: null,
    };
  }
  const requestEntryList: IRequestEntry[] = [
    createImmunizationRequestEntry(identifer, periodParam, patientCid, count, offset),
  ];
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const immunizationData: IImmunizationSearchSet =
      data.entry && data.entry.length > 0 ? data.entry[0].resource : null;
    return { success: true, data: immunizationData, error: null };
  }
  return { success: false, data, error };
};

export const getProcedure = async (
  periodParam: IPeriodParam | null,
  hospitalParam: IHospital[] | null,
  consentedWithDataMdp9Ids: IHospitalConsent['mdp9_id'][] | null,
  patientCid: string,
  count: number,
  offset: number,
): Promise<
  | {
      success: true;
      data: IProcedureSearchSet;
      error: null;
    }
  | IFormattedError
> => {
  const identifer = getIdentifierFromFilteredMdp9Ids(consentedWithDataMdp9Ids, hospitalParam);
  if (!identifer.length) {
    return {
      success: true,
      data: {
        resourceType: 'Bundle',
        type: 'searchset',
        entry: [],
      },
      error: null,
    };
  }
  const requestEntryList: IRequestEntry[] = [
    createProcedureRequestEntry(identifer, periodParam, patientCid, count, offset),
  ];
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const procedureData: IProcedureSearchSet =
      data.entry && data.entry.length > 0 ? data.entry[0].resource : null;
    return { success: true, data: procedureData, error: null };
  }
  return { success: false, data, error };
};

export const getMedicationStatement = async (
  periodParam: IPeriodParam | null,
  hospitalParam: IHospital[] | null,
  consentedWithDataMdp9Ids: IHospitalConsent['mdp9_id'][] | null,
  patientCid: string,
  count: number,
  offset: number,
): Promise<
  | {
      success: true;
      data: IMedicationStatementSearchSet;
      error: null;
    }
  | IFormattedError
> => {
  const identifer = getIdentifierFromFilteredMdp9Ids(consentedWithDataMdp9Ids, hospitalParam);
  if (!identifer.length) {
    return {
      success: true,
      data: {
        resourceType: 'Bundle',
        type: 'searchset',
        entry: [],
      },
      error: null,
    };
  }
  const requestEntryList: IRequestEntry[] = [
    createMedicationStatementRequestEntry(identifer, periodParam, patientCid, count, offset),
  ];
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const medicationData: IMedicationStatementSearchSet =
      data.entry && data.entry.length > 0 ? data.entry[0].resource : null;
    return { success: true, data: medicationData, error: null };
  }
  return { success: false, data, error };
};

export const getDiagnosticReport = async (
  periodParam: IPeriodParam | null,
  hospitalParam: IHospital[] | null,
  consentedWithDataMdp9Ids: IHospitalConsent['mdp9_id'][] | null,
  patientCid: string,
  count: number,
  offset: number,
): Promise<
  | {
      success: true;
      data: IDiagnosticReportSearchSet;
      error: null;
    }
  | IFormattedError
> => {
  const identifer = getIdentifierFromFilteredMdp9Ids(consentedWithDataMdp9Ids, hospitalParam);
  if (!identifer.length) {
    return {
      success: true,
      data: {
        resourceType: 'Bundle',
        type: 'searchset',
        entry: [],
      },
      error: null,
    };
  }
  const requestEntryList: IRequestEntry[] = [
    createDiagnosticReportRequestEntry(identifer, periodParam, patientCid, count, offset),
  ];
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const diagnosticReportData: IDiagnosticReportSearchSet =
      data.entry && data.entry.length > 0 ? data.entry[0].resource : null;
    return { success: true, data: diagnosticReportData, error: null };
  }
  return { success: false, data, error };
};

export const getObservation = async (
  periodParam: IPeriodParam | null,
  hospitalParam: IHospital[] | null,
  consentedWithDataMdp9Ids: IHospitalConsent['mdp9_id'][] | null,
  patientCid: string,
  count: number,
  offset: number,
): Promise<
  | {
      success: true;
      data: IObservationSearchSet;
      error: null;
    }
  | IFormattedError
> => {
  const identifer = getIdentifierFromFilteredMdp9Ids(consentedWithDataMdp9Ids, hospitalParam);
  if (!identifer.length) {
    return {
      success: true,
      data: {
        resourceType: 'Bundle',
        type: 'searchset',
        entry: [],
      },
      error: null,
    };
  }
  const requestEntryList: IRequestEntry[] = [
    createObservationRequestEntry(identifer, periodParam, patientCid, count, offset),
  ];
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const observationData: IObservationSearchSet =
      data.entry && data.entry.length > 0 ? data.entry[0].resource : null;
    return { success: true, data: observationData, error: null };
  }
  return { success: false, data, error };
};

// ===================================================
// ==================== LabApi ======================
// ===================================================
export const getDiagnodticReportAndObservation = async (
  periodParam: IPeriodParam | null,
  hospitalParam: IHospital[] | null,
  consentedWithDataMdp9Ids: IHospitalConsent['mdp9_id'][] | null,
  patientCid: string,
  count: number,
  offset: number,
): Promise<
  | {
      success: true;
      // data: ILabSearchSet | null;
      data: any;
      error: null;
    }
  | IFormattedError
> => {
  const identifer = getIdentifierFromFilteredMdp9Ids(consentedWithDataMdp9Ids, hospitalParam);
  if (!identifer.length) {
    return {
      success: true,
      data: {
        resourceType: 'Bundle',
        type: 'searchset',
        entry: [],
      },
      error: null,
    };
  }
  let success;
  let data;
  let error;
  if (!useNewLabSampleData) {
    const requestEntryList: IRequestEntry[] = [
      createLabRequestEntry(identifer, periodParam, patientCid, count, offset),
    ];
    const ret = await requestFHIRData(requestEntryList);
    success = ret.success;
    data = ret.data;
    error = ret.error;
  } else {
    // ----- mock
    success = true;
    data = sampleLab;
    error = null;
    // ----- //mock
  }

  if (success) {
    const labData = data;
    return { success: true, data: labData, error: null };
  }
  return { success: false, data: error.response.data, error };
};
// ===================================================
// ===================================================

export const getCondition = async (
  periodParam: IPeriodParam | null,
  hospitalParam: IHospital[] | null,
  consentedWithDataMdp9Ids: IHospitalConsent['mdp9_id'][] | null,
  patientCid: string,
  count: number,
  offset: number,
): Promise<
  | {
      success: true;
      data: IConditionSearchSet;
      error: null;
    }
  | IFormattedError
> => {
  const identifer = getIdentifierFromFilteredMdp9Ids(consentedWithDataMdp9Ids, hospitalParam);
  if (!identifer.length) {
    return {
      success: true,
      data: {
        resourceType: 'Bundle',
        type: 'searchset',
        entry: [],
      },
      error: null,
    };
  }
  const requestEntryList: IRequestEntry[] = [
    createConditionRequestEntry(identifer, periodParam, patientCid, count, offset),
  ];
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const conditionData: IConditionSearchSet =
      data.entry && data.entry.length > 0 ? data.entry[0].resource : null;
    return { success: true, data: conditionData, error: null };
  }
  return { success: false, data, error };
};

export const getCompositionList = async (
  periodParam: IPeriodParam | null,
  hospitalParam: IHospital[] | null,
  visitTypeParam: string[] | null,
  categoryParam: ICategoryParam | null,
  consentedWithDataMdp9Ids: IHospitalConsent['mdp9_id'][] | null,
  patientCid: string,
  count: number,
  offset: number,
  encounter_missing: boolean,
): Promise<
  | {
      success: true;
      data: ICompositionSearchSet;
      error: null;
    }
  | IFormattedError
> => {
  const identifer = getIdentifierFromFilteredMdp9Ids(consentedWithDataMdp9Ids, hospitalParam);
  if (!identifer.length) {
    return {
      success: true,
      data: {
        resourceType: 'Bundle',
        type: 'searchset',
        entry: [],
      },
      error: null,
    };
  }
  const requestEntryList: IRequestEntry[] = [
    createCompositionRequestEntry(
      identifer,
      periodParam,
      visitTypeParam,
      categoryParam,
      patientCid,
      count,
      offset,
      encounter_missing,
    ),
  ];
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const compositionData: ICompositionSearchSet =
      data.entry && data.entry.length > 0 ? data.entry[0].resource : null;
    return { success: true, data: compositionData, error: null };
  }
  return { success: false, data, error };
};

export const getCompositionDocument = async (
  compositionIdList: string[],
): Promise<{ success: true; data: any; error: null } | IFormattedError> => {
  if (compositionIdList.length < 1) {
    return { success: true, data: {}, error: null };
  }
  const requestEntryList: IRequestEntry[] = compositionIdList.map((record) => ({
    request: {
      method: 'GET',
      url: `Composition/${record}/$document`,
    },
  }));
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const documentEntry: any[] = data.entry || [];
    const documentDict: any = {};
    for (const topLevelRecord of documentEntry) {
      const composition = topLevelRecord.resource.entry.filter(
        (record: any) => record.resource.resourceType === 'Composition',
      );
      documentDict[composition[0].resource.id] = topLevelRecord.resource;
    }
    return { success: true, data: documentDict, error: null };
  }
  return { success: false, data, error };
};

export const getResourceCountList = async (
  hospitalParam: IHospital[] | null,
  consentedWithDataMdp9Ids: IHospitalConsent['mdp9_id'][] | null, // TODO: double check if business want to show total or total with data
  patientCid: string,
  {
    compositionParam,
    allergyParam,
    immunizationParam,
    conditionParam,
    procedureParam,
    medicationStatementParam,
    diagnosticReportParam,
    observationParam,
  }: {
    compositionParam?: {
      periodParam: IPeriodParam | null;
      visitTypeParam: string[] | null;
      categoryParam: ICategoryParam | null;
    } | null;
    allergyParam?: {
      periodParam: IPeriodParam | null;
    } | null;
    immunizationParam?: {
      periodParam: IPeriodParam | null;
    } | null;
    conditionParam?: {
      periodParam: IPeriodParam | null;
    } | null;
    procedureParam?: {
      periodParam: IPeriodParam | null;
    } | null;
    medicationStatementParam?: {
      periodParam: IPeriodParam | null;
    } | null;
    diagnosticReportParam?: {
      periodParam: IPeriodParam | null;
    } | null;
    observationParam?: {
      periodParam: IPeriodParam | null;
    } | null;
  },
): Promise<{ success: true; data: IResourceCount; error: null } | IFormattedError> => {
  const identifer = getIdentifierFromFilteredMdp9Ids(consentedWithDataMdp9Ids, hospitalParam);
  if (!identifer.length) {
    return {
      success: true,
      data: {
        composition: 0,
        allergyIntolerance: 0,
        immunization: 0,
        condition: 0,
        procedure: 0,
        medicationStatement: 0,
        diagnosticReport: 0,
        observation: 0,
      },
      error: null,
    };
  }
  const requestEntryList: IRequestEntry[] = [];
  if (compositionParam) {
    requestEntryList.push(
      createCompositionRequestEntry(
        identifer,
        compositionParam?.periodParam || null,
        compositionParam?.visitTypeParam || null,
        compositionParam?.categoryParam || null,
        patientCid,
        0,
        0,
        false,
      ),
    );
  }
  if (allergyParam) {
    requestEntryList.push(
      createAllergyIntoleranceRequestEntry(
        identifer,
        allergyParam?.periodParam || null,
        patientCid,
        0,
        0,
      ),
    );
  }
  if (immunizationParam) {
    requestEntryList.push(
      createImmunizationRequestEntry(
        identifer,
        immunizationParam?.periodParam || null,
        patientCid,
        0,
        0,
      ),
    );
  }
  if (conditionParam) {
    requestEntryList.push(
      createConditionRequestEntry(identifer, conditionParam?.periodParam || null, patientCid, 0, 0),
    );
  }
  if (procedureParam) {
    requestEntryList.push(
      createProcedureRequestEntry(identifer, procedureParam?.periodParam || null, patientCid, 0, 0),
    );
  }
  if (medicationStatementParam) {
    requestEntryList.push(
      createMedicationStatementRequestEntry(
        identifer,
        medicationStatementParam?.periodParam || null,
        patientCid,
        0,
        0,
      ),
    );
  }
  if (diagnosticReportParam) {
    requestEntryList.push(
      createDiagnosticReportRequestEntry(
        identifer,
        diagnosticReportParam?.periodParam || null,
        patientCid,
        0,
        0,
      ),
    );
  }
  if (observationParam) {
    requestEntryList.push(
      createObservationRequestEntry(
        identifer,
        observationParam?.periodParam || null,
        patientCid,
        0,
        0,
      ),
    );
  }
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const totalResult: IResourceCount = {
      composition: 0,
      allergyIntolerance: 0,
      immunization: 0,
      condition: 0,
      procedure: 0,
      medicationStatement: 0,
      diagnosticReport: 0,
      observation: 0,
    };
    let count = 0;
    if (compositionParam) {
      totalResult.composition = data.entry[count++].resource.total;
    }
    if (allergyParam) {
      totalResult.allergyIntolerance = data.entry[count++].resource.total;
    }
    if (immunizationParam) {
      totalResult.immunization = data.entry[count++].resource.total;
    }
    if (conditionParam) {
      totalResult.condition = data.entry[count++].resource.total;
    }
    if (procedureParam) {
      totalResult.procedure = data.entry[count++].resource.total;
    }
    if (medicationStatementParam) {
      totalResult.medicationStatement = data.entry[count++].resource.total;
    }
    if (diagnosticReportParam) {
      totalResult.diagnosticReport = data.entry[count++].resource.total;
    }
    if (observationParam) {
      totalResult.observation = data.entry[count++].resource.total;
    }
    return {
      success: true,
      data: totalResult,
      error: null,
    };
  }
  console.debug('getResourceCountList', data);
  return { success: false, data: data?.response?.data ?? data, error };
};

export const getHospitalWithData = async (
  consentSummary: IHospitalConsent[] | null,
  patientCid: string,
): Promise<
  | {
      success: true;
      data: any[];
      error: null;
    }
  | IFormattedError
> => {
  if (!consentSummary) {
    return {
      success: true,
      data: [],
      error: null,
    };
  }
  const mdp9idList = consentSummary.map((record) => record.mdp9_id);
  const requestEntryList: IRequestEntry[] = mdp9idList.map((mdp9id) => {
    return createCompositionRequestEntry([mdp9id], null, null, null, patientCid, 0, 0, false);
  });
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const { entry } = data as IFHIRSearchSet;
    const hospitalList = entry
      ? consentSummary.filter((_hospital, index) => {
          const resource = entry[index].resource as IFHIRSearchSet;
          if (resource.total) {
            return true;
          }
          return false;
        })
      : [];
    return { success: true, data: hospitalList, error: null };
  }
  return { success: false, data, error };
};

export const runFhirDataPulling = async (
  cid: string,
): Promise<{ success: true; data: string; error: null } | IFormattedError> => {
  try {
    const res = await api.post<RunFhirDataFromServerResponse>(
      `/api/patient/fhir-data-pulling-from-server/run`,
      {
        cid,
      },
      {
        headers: await getDefaultRequestHeaders(true),
      },
    );
    return {
      success: true,
      data: res.data.job_id,
      error: null,
    };
  } catch (err: any) {
    console.log(err.response.data);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const runFhirDataPullingFromClient = async (
  cid: string,
): Promise<
  { success: true; data: RunFhirDataFromClientResponse; error: null } | IFormattedError
> => {
  try {
    const res = await api.post<RunFhirDataFromClientResponse>(
      `/api/patient/fhir-data-pulling-from-client/run`,
      {
        cid,
      },
      {
        headers: await getDefaultRequestHeaders(true),
      },
    );

    // // mock case not create a job because the data is still fresh
    // res.data = {
    //   ws_token: null,
    //   job_details: null,
    //   job_id: null,
    //   created_at: null,
    //   n_resources: null,
    // };
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err: any) {
    console.log(err.response.data);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

export const viewFhirDataPullingStatus = async (
  job_id: string,
): Promise<{ success: true; data: FhirDataPullingJobStatus; error: null } | IFormattedError> => {
  try {
    const res = await api.get<FhirDataPullingJobStatus>(`/api/patient/fhir-data-pulling/check`, {
      headers: await getDefaultRequestHeaders(true),
      params: {
        job_id,
      },
    });
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err: any) {
    console.log(err.response.data);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};

/**
 * as of Octr 25, 2023 the resource count from getHospitalListWithData is not correct. can only use the response for which hospital the patient has data in
 */
export const getHospitalListWithData = async (
  patientCid: string,
  mdp9IdsToRequest: string[],
  filterOnlyMdp9IdsToRequest: boolean,
): Promise<
  | {
      success: true;
      data: IFHIRDataInfo;
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.post<IFHIRDataInfo>(
      `/api/patient/fhir-data-info/by-hospital`,
      {
        cid: patientCid,
      },
      {
        headers: await getDefaultRequestHeaders(true),
      },
    );

    const list_data = filterOnlyMdp9IdsToRequest
      ? res.data?.list_data?.filter((mp) => mdp9IdsToRequest.includes(mp.mdp9_id))
      : res.data?.list_data;
    const data = { ...res.data, list_data };
    const { list_data: nData } = data;
    data.list_data = nData.map((e) => ({ ...e, cid: patientCid }));

    return {
      success: true,
      data,
      error: null,
    };
  } catch (err: any) {
    console.log(err.response.data);
    return {
      success: false,
      data: err?.response?.data,
      error: err?.response?.status,
    };
  }
};

export const getPatientDataByKeyword = async (
  keyword: string,
): Promise<
  | {
      success: true;
      data: ISearchResultRecord[];
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.get('/api/patient/doctor-search', {
      headers: await getDefaultRequestHeaders(true),
      params: {
        keyword,
      },
    });

    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err: any) {
    console.log(err.response.data);
    return {
      success: false,
      data: err?.response?.data,
      error: err?.response?.status,
    };
  }
};

// TODO: in the future, don't store the whole array of hospital with data. just store the ids and look the info up from an object version of consentSummary.
export const getHospitalWithDataOfMdp9Ids = async (
  mdp9IdsToRequest: string[],
  hospitalLookup: { [mdp9Id: string]: IHospitalConsent },
  patientCid: string,
): Promise<
  | {
      success: true;
      data: IHospitalConsent[];
      error: null;
    }
  | IFormattedError
> => {
  if (!mdp9IdsToRequest) {
    return {
      success: true,
      data: [],
      error: null,
    };
  }
  // make sure that all mdp9IdsToRequest are in the consentSummaryObj
  if (!mdp9IdsToRequest.every((mdp9Id) => mdp9Id in hospitalLookup)) {
    throw Error('all mdp9IdsToRequest must be in the consentSummaryObj');
  }
  const mdp9idList = mdp9IdsToRequest;
  // TODO: use the new api
  const requestEntryList: IRequestEntry[] = mdp9idList.map((mdp9id) => {
    return createCompositionRequestEntry([mdp9id], null, null, null, patientCid, 0, 0, false);
  });
  const { success, data, error } = await requestFHIRData(requestEntryList);
  if (success) {
    const { entry } = data as IFHIRSearchSet;
    const hospitalListIds = entry
      ? mdp9idList.filter((_hospital, index) => {
          const resource = entry[index].resource as IFHIRSearchSet;
          if (resource.total) {
            return true;
          }
          return false;
        })
      : [];
    // convert back to hospitalList by looking up in consentSummary
    const hospitalList = hospitalListIds.map((id) => hospitalLookup[id]);
    return { success: true, data: hospitalList, error: null };
  }
  return { success: false, data: null, error };
};

export const getChronicDiseaseByCid = async (
  cid: string,
): Promise<{ success: true; data: any; error: null } | IFormattedError> => {
  try {
    if (!cid) {
      return { success: true, data: [], error: null };
    }
    let entry = [];
    if (useChronicDiseaseApi) {
      const requestEntryList: IRequestEntry[] = [
        {
          request: {
            method: 'GET',
            url: `Condition?category=problem-list-item&patient.identifier=${cid}`,
          },
        },
      ];
      const { success, data, error } = await requestFHIRData(requestEntryList);
      entry = data?.entry;
    } else if (useChronicDiseaseSampleData) {
      entry = sampleChronicDisease?.entry;
    }
    const chronicDiseaseList = entry.map((item) => {
      return item.resource.code?.text
        ? item.resource.code.text
        : item.resource.code.coding[0].display;
    });
    const chronicDisease = [...new Set(chronicDiseaseList)].join(', ');
    return {
      success: true,
      data: chronicDisease,
      error: null,
    };
  } catch (err: any) {
    console.log(err.response.data);
    return {
      success: false,
      data: err.response.data,
      error: err.response.status,
    };
  }
};
