import React from 'react';
import { setUser } from 'global_store/features/authen/authenSlice';
import { useAppDispatch } from 'global_store/hooks';
// import Dialog from '@mui/material/Dialog';
// import Container from '@mui/material/Container';
// import GenericButton from 'components/Button/GenericButton';
import { useNavigate } from 'react-router-dom';
import { requestLogout } from 'services/authen';

// import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import BaseDialog from './Base/BaseDialog';

// const PREFIX = 'ErrorLogoutDialog';

// const classes = {
//   headerButton: `${PREFIX}-headerButton`,
//   buttonArrow: `${PREFIX}-buttonArrow`,
//   leftContent: `${PREFIX}-leftContent`,
//   rightContent: `${PREFIX}-rightContent`,
//   menuItem: `${PREFIX}-menuItem`,
//   dialog: `${PREFIX}-dialog`,
//   dialogContent: `${PREFIX}-dialogContent`,
//   dialogButton: `${PREFIX}-dialogButton`,
//   centerAllContent: `${PREFIX}-centerAllContent`,
// };

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   [`& .${classes.headerButton}`]: {
//     border: '2px solid #CBCBCB',
//     width: '100%',
//     margin: 'auto',
//   },

//   [`& .${classes.buttonArrow}`]: {
//     color: '#59DCAF',
//   },

//   [`& .${classes.leftContent}`]: {
//     justifyContent: 'left',
//     alignContent: 'center',
//     display: 'flex',
//   },

//   [`& .${classes.rightContent}`]: {
//     justifyContent: 'right',
//     alignContent: 'center',
//     display: 'flex',
//   },

//   [`& .${classes.menuItem}`]: {
//     '&:hover': {
//       backgroundColor: theme.palette.secondary.main,
//     },
//   },

//   [`& .${classes.dialog}`]: {
//     borderRadius: '16px',
//   },

//   [`& .${classes.dialogContent}`]: {
//     padding: theme.spacing(4),
//   },

//   [`& .${classes.dialogButton}`]: {
//     justifyContent: 'center',
//     maxWidth: '50%',
//     marginTop: '16px',
//   },

//   [`& .${classes.centerAllContent}`]: {
//     justifyContent: 'center',
//     alignContent: 'center',
//     textAlign: 'center',
//   },
// }));

interface ErrorLogoutDialogProps {
  open: boolean;
  closeDialog: () => void;
}

function ErrorLogoutDialog({ open, closeDialog }: ErrorLogoutDialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClose = async () => {
    await requestLogout();
    closeDialog();
    dispatch(setUser(null));
    navigate('/page/login');
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      maxWidth="sm"
      withOkButton
      disableCloseOnClickBackdrop
    >
      <Typography
        variant="overline"
        color="error"
        sx={{
          justifyContent: 'center',
          alignContent: 'center',
          textAlign: 'center',
        }}
        component="p"
      >
        ไม่พบสังกัดโรงพยาบาล กรุณาติดต่อแอดมินสถานพยาบาลเพื่อเพิ่มสังกัด
      </Typography>
    </BaseDialog>
  );

  // return (
  //   <StyledDialog open={open} maxWidth="sm" PaperProps={{ className: `${classes.dialog}` }}>
  //     <Container className={classes.dialogContent}>
  //       <Typography
  //         variant="overline"
  //         color="error"
  //         className={classes.centerAllContent}
  //         component="p"
  //       >
  //         ไม่พบสังกัดโรงพยาบาล กรุณาติดต่อแอดมินสถานพยาบาลเพื่อเพิ่มสังกัด
  //       </Typography>
  //       <Container className={classes.dialogButton}>
  //         <GenericButton onClick={handleClose} fullWidth size="small">
  //           ตกลง
  //         </GenericButton>
  //       </Container>
  //     </Container>
  //   </StyledDialog>
  // );
}

export default ErrorLogoutDialog;
