import React from 'react';

import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export interface AvatarIconProps {
  Icon: any;
  iconSize?: string;
  avatarWidth?: string;
  avatarHeight?: string;
  iconColor?: string;
  avatarBgColor?: string;
}

function AvatarIcon({
  Icon,
  iconSize = '1.75rem',
  avatarWidth = '2.5rem',
  avatarHeight = '2.5rem',
  iconColor,
  avatarBgColor,
}: AvatarIconProps): JSX.Element {
  const theme = useTheme();

  const defaultIconColor = theme.palette.primary.main;
  const defaultAvatarBgColor = theme.palette.primary.contrastText;

  return (
    <Avatar
      alt="User Avatar"
      sx={{
        background: avatarBgColor ?? defaultAvatarBgColor,
        width: avatarWidth,
        height: avatarHeight,
        '& svg': {
          position: 'absolute',
          top: '63%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: iconColor ?? defaultIconColor,
        },
      }}
    >
      <Icon size="1.75rem" />
    </Avatar>
  );
}

export default AvatarIcon;
