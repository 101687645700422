import { ROUTE_PREFIX, TAB_LIST } from 'config/system-admin';

import BaseAdminManagementPage from '../BaseAdmin/BaseAdminManagementPage';

function SystemAdminManagement(): JSX.Element {
  return (
    <BaseAdminManagementPage
      defaultActiveTab="dashboard"
      routePrefix={ROUTE_PREFIX}
      tabList={TAB_LIST}
    />
  );
}

export default SystemAdminManagement;
