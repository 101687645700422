import { addMinutes } from 'date-fns';
import { IConsentStatus } from 'interface';
import { IUser, IWorkingPeriod } from 'interface/authen';
import { INoticeRecord } from 'interface/doctor.management';

export const sampleGrantDocDecodedAccessToken = {
  user_id: 1,
  username: 'sample_doc',
  mdp_active: '000000000',
  roles: ['DOCTOR_SINGLE_PATIENT'],
  // accept_latest_tc: true,
  emergency: {
    is_active: false,
    expire_date: null,
  },
  sdlt: 'xxx-xxx-xxx-xxx',
  ip: '0.0.0.1',
  exp: Math.floor(addMinutes(new Date(), 30).valueOf() / 1000), // 1674732241,
  sub: 'access',
  iss: 'GBDi',
  patient_id: 1,
};

export const sampleUser: IUser = {
  user_id: 1,
  title: 'นายแพทย์',
  firstname: 'สมมติ',
  lastname: 'นามสกุล',
  email: 'sample@gmail.com',
  mobile: '099-199-1999',
  work_telephone: null,
  created_date: '2021-10-11T00:00:00.010010',
  username: 'sample_doc',
  accept_tc: true,
  tc_version: '0.0.0',
  is_active: true,
  list_mdp: [
    {
      mdp_name: 'โรงพยาบาลกรุงเทพ',
    },
    {
      mdp_name: 'โรงพยาบาลกรุงเทพอินเตอร์เนชั่นแนล',
    },
    {
      mdp_name: 'โรงพยาบาลจุฬาภรณ์',
    },
    {
      mdp_name: 'โรงพยาบาลสมเด็จพระบรมราชินีนาถ ณ อำเภอนาทวี จังหวัดสงขลา',
    },
    {
      mdp_name: 'โรงพยาบาลศิริราช',
    },
  ],
  is_part_time_doctor: false,
  working_hour: [],
  mdp_id: 0,
  is_working_hour: false,
};

export const sampleNoticeList: INoticeRecord[] = [
  {
    id: 'notice-mock-01',
    date: '2021-12-20',
    title: 'Update! รายชื่อและสถานะโรงพยาบาลที่เข้าร่วมโครงการ',
    data: `ท่านสามารถดูรายชื่อและสถานะโรงพยาบาลที่เข้าร่วมโครงการได้ที่ <a href="https://www.healthlink.go.th/hospitalpartner/" target="_blank">https://www.healthlink.go.th/hospitalpartner/</a>`,
    status: 'unread',
  },
  {
    id: 'notice-mock-02',
    date: '2021-12-13',
    title: 'Update! รายชื่อโรงพยาบาลที่พร้อมนำส่งข้อมูลเข้าระบบ Health Link',
    data: `ท่านสามารถดูรายชื่อโรงพยาบาลที่พร้อมนำส่งข้อมูลเข้าระบบ Health
          Link(ซึ่งเป็นรายชื่อโรงพยาบาลที่ผู้ป่วยสามารถเลือกได้ผ่านแอปพลิเคชัน
          “เป๋าตัง”) ได้ที่ <a href="https://www.healthlink.go.th/hospitalhealthwallet/" target="_blank">https://www.healthlink.go.th/hospitalhealthwallet/</a>`,
    status: 'unread',
  },
];

export const sampleConsentStatus: IConsentStatus = {
  ref_code: 'sample_ref',
  doctor_id: 6,
  consent_status: 'pending',
  destination: {
    ktb_paotung: 'not send',
    patient_email: 'success',
  },
  expire_datetime: '06-15-2022 09:13:50',
};

export const sampleWorkingHour: IWorkingPeriod[] = [
  {
    start_day_of_week: 1,
    start_time: '07:30',
    end_day_of_week: 2,
    end_time: '12:30',
    day_of_week: 1,
  },
  {
    start_day_of_week: 2,
    start_time: '07:30',
    end_day_of_week: 3,
    end_time: '12:30',
    day_of_week: 2,
  },
];
