import React from 'react';
import GenericButton from 'components/Button/GenericButton';
import GenericInput from 'components/Input/GenericInput';
import { selectUser } from 'global_store/features/authen/authenSlice';
import { useAppSelector } from 'global_store/hooks';
import { IContactForm } from 'interface/doctor.management';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

const PREFIX = 'Contact';

const classes = {
  root: `${PREFIX}-root`,
  inputStyle: `${PREFIX}-inputStyle`,
  noVerticalPadding: `${PREFIX}-noVerticalPadding`,
  noPaddingTop: `${PREFIX}-noPaddingTop`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    margin: theme.spacing(1),
    marginTop: 0,
  },

  [`& .${classes.inputStyle}`]: {
    width: '75%',
  },

  [`& .${classes.noVerticalPadding}`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`& .${classes.noPaddingTop}`]: {
    paddingTop: 0,
  },
}));

function Contact() {
  const user = useAppSelector(selectUser);
  const schema = yup
    .object()
    .shape({
      topic: yup.string().required('กรุณากรอกหัวข้อการติดต่อ'),
      contact_number: yup
        .string()
        .matches(
          /^[0-9]{8,10}$/,
          'กรุณากรอกเบอร์โทรศัพท์เป็นตัวเลข 8-10 หลัก ห้ามมีตัวอักษร เครื่องหมาย หรือเว้นวรรค',
        )
        .required('กรุณากรอกเบอร์โทรศัพท์'),
      email: yup.string().email('อีเมล์ไม่ถูกต้อง').required('กรุณากรอกอีเมล์'),
      message: yup.string().required('กรุณากรอกรายละเอียด'),
    })
    .required();
  const { control, handleSubmit, setValue } = useForm<IContactForm>({
    resolver: yupResolver(schema),
  });

  const initFormValues = React.useCallback(() => {
    setValue('contact_number', user?.mobile || '');
    setValue('email', user?.email || '');
  }, [user, setValue]);

  React.useEffect(() => {
    initFormValues();
  }, [initFormValues]);

  const onSubmit: SubmitHandler<IContactForm> = async (data) => {
    console.log(data);
  };

  const onInvalid = (e: any) => {
    console.log('Invalid Form');
  };

  return (
    <Root>
      <div className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <List>
            <ListItem className={classes.noVerticalPadding}>
              <Typography variant="h4">ติดต่อระบบฯ</Typography>
            </ListItem>
            <ListItem className={classes.noPaddingTop}>
              <GenericInput
                control={control}
                name="topic"
                label="หัวข้อ*"
                defaultValue=""
                type="text"
                variant="generic"
                muiVariant="standard"
                rules={{}}
                className={classes.inputStyle}
              />
            </ListItem>
            <ListItem className={classes.noPaddingTop}>
              <GenericInput
                control={control}
                name="contact_number"
                label="เบอร์ติดต่อ*"
                defaultValue=""
                type="text"
                variant="generic"
                muiVariant="standard"
                rules={{}}
                className={classes.inputStyle}
              />
            </ListItem>
            <ListItem className={classes.noPaddingTop}>
              <GenericInput
                control={control}
                name="email"
                label="อีเมล*"
                defaultValue=""
                type="text"
                variant="generic"
                muiVariant="standard"
                rules={{}}
                className={classes.inputStyle}
              />
            </ListItem>
            <ListItem className={classes.noPaddingTop}>
              <GenericInput
                control={control}
                name="message"
                label="ข้อความ*"
                defaultValue=""
                type="text"
                variant="textarea"
                muiVariant="standard"
                rules={{}}
                className={classes.inputStyle}
              />
            </ListItem>
            <ListItem>
              <GenericButton type="submit" size="small" style={{ width: '160px' }}>
                ส่ง
              </GenericButton>
            </ListItem>
          </List>
        </form>
      </div>
    </Root>
  );
}

export default Contact;

/**
 * หัวข้อ*
 * ค้นหาข้อมูลคนไข้ไม่เจอ
 *
 * เบอร์ติดต่อ*
 * 092-555-5555
 *
 * อีเมล*
 * sample@gmail.com
 *
 * ข้อความ*
 *
 *
 * Button
 */
