import {
  doneProgresses,
  EachPatientProgressInfoList,
  JobProgressBarCase,
  JobProgressIconCase,
  JobProgressStatus,
  PatientProgressInfo,
  ProgressInfo,
  ProgressInfoList,
  ProgressInfoResData,
} from 'interface/forceUpdate';

import { LinearProgressProps, LinearProgressPropsColorOverrides } from '@mui/material';

const JOB_STATUS_TO_DECIMAL = {
  [JobProgressStatus.INITIALIZED]: 0.1,
  [JobProgressStatus.CHECKING_CONSENT]: 0.2,
  [JobProgressStatus.RETRIEVING_RECORD]: 0.5,
  [JobProgressStatus.PREPARING_DATA]: 0.6,
  [JobProgressStatus.TRANSMIT_DATA]: 0.8,
  [JobProgressStatus.FINISHED]: 1.0,
  [JobProgressStatus.NO_CONSENT]: 0.8, // TODO: revisit. migth not count the progress just change to red.
  [JobProgressStatus.UNKNOWN_ERROR]: 1.0, // TODO: revisit. migth not count the progress just change to red.
  [JobProgressStatus.ERROR]: 1.0, // TODO: revisit. migth not count the progress just change to red.
  [JobProgressStatus.NO_DATA]: 1.0, // TODO: revisit. migth not count the progress just change to red.
};

const PROGRESS_CASE_TO_TEXT = {
  [JobProgressBarCase.SOME_SUCCESS]: 'ระบบประมวลผลสำเร็จ',
  [JobProgressBarCase.IN_PROGRESS]: 'ระบบกำลังประมวลผล กรุณารอสักครู่',
  [JobProgressBarCase.ALL_FAILED]: 'ขออภัยไม่สามารถเรียกดูข้อมูลได้',
};

const PROGRESS_CASE_TO_COLOR: { [key: string]: LinearProgressProps['color'] } = {
  [JobProgressBarCase.SOME_SUCCESS]: 'secondary', // 'success',
  [JobProgressBarCase.IN_PROGRESS]: 'primary', // 'info',
  [JobProgressBarCase.ALL_FAILED]: 'error',
};

const DONE_STATUS_TO_TOOLTIP_TEXT = {
  [JobProgressStatus.FINISHED]: 'สำเร็จ',
  [JobProgressStatus.UNKNOWN_ERROR]: 'มีปัญหาเกิดขึ้นจากการดำเนินการ',
  [JobProgressStatus.ERROR]: 'มีปัญหาเกิดขึ้นจากการดำเนินการ',
  [JobProgressStatus.NO_DATA]: 'ไม่พบข้อมูลใหม่ของผู้ป่วยในระบบ',
  [JobProgressStatus.NO_CONSENT]: 'ไม่สามารถเรียกดูข้อมูลได้เนื่องจากผู้ป่วยไม่ให้ความยินยอมไว้',
};

export const isSuccess = (status: JobProgressStatus): boolean => {
  return [JobProgressStatus.FINISHED, JobProgressStatus.NO_DATA].includes(status);
};

/**
 * @returns decimal: 0-1
 */
export const getStatusPercent = (status: JobProgressStatus): number => {
  if (!status) return 0;
  return JOB_STATUS_TO_DECIMAL[status] ?? 0;
};

export const checkDuplicateProgressInfo = (progressInfoList: ProgressInfoList): void => {
  const patientIds = progressInfoList.map((item) => item.patient_id);
  const patientIdsSet = new Set(progressInfoList);
  if (patientIdsSet.size !== patientIds.length) throw Error('has duplicated patient id');
};

export const formatListToPatientProgressInfo = (
  progressInfoList: ProgressInfoList,
): PatientProgressInfo => {
  checkDuplicateProgressInfo(progressInfoList);
  return progressInfoList.reduce((acc, info) => {
    const { patient_id, ...rest } = info;
    if (!(patient_id in acc)) acc[patient_id] = [];
    acc[patient_id] = acc[patient_id].concat(rest);
    return acc;
  }, {});
};

export const getUpdatedPatientProgressInfo = (
  newProgressInfos: ProgressInfo[], // only works with 1 patient id at a time for now
  prevPatientProgressInfo: PatientProgressInfo,
): undefined | PatientProgressInfo => {
  if (!newProgressInfos.length) return undefined;
  newProgressInfos.forEach((newProgressInfo) => {
    const { patient_id, ...rest } = newProgressInfo;
    const prev = prevPatientProgressInfo[patient_id] ?? [];
    const existedBefore = prev.findIndex((item) => item.mdp9_id === newProgressInfo.mdp9_id) > -1;
    // // mock all error
    // rest.status = JobProgressStatus.UNKNOWN_ERROR;
    // // case 2 only for	001153700  โรงพยาบาลกลาง UNKNOWN_ERROR
    // if (newProgressInfo.mdp9_id === '001153700') rest.status = JobProgressStatus.UNKNOWN_ERROR;
    prevPatientProgressInfo[patient_id] = existedBefore
      ? prev.map((item) =>
          item.mdp9_id === newProgressInfo.mdp9_id &&
          !(rest.status === JobProgressStatus.FINISHED && item.status === JobProgressStatus.NO_DATA) // if no data don't replace with finished
            ? rest
            : item,
        )
      : prev.concat(rest);
  });
  return prevPatientProgressInfo;
};

/**
 *
 * @param eachPatientProgressInfoList
 * @returns decimal: 0-1
 */
export const getStatusPercentFromEachPatientProgressInfo = (
  eachPatientProgressInfoList: EachPatientProgressInfoList,
): number => {
  const sum = eachPatientProgressInfoList.reduce((acc, progress) => {
    const percent = getStatusPercent(progress.status);
    if (typeof percent !== 'number') return 0;
    acc += percent;
    return acc;
  }, 0);
  return sum / eachPatientProgressInfoList.length;
};

export const isStatusDone = (status: JobProgressStatus): boolean => {
  return doneProgresses.includes(status);
};

export const getProgressBarCaseFromEachPatientProgressInfo = (
  eachPatientProgressInfoList: EachPatientProgressInfoList,
): JobProgressBarCase => {
  const anyInProgress = eachPatientProgressInfoList.some((info) => !isStatusDone(info.status));
  if (anyInProgress) return JobProgressBarCase.IN_PROGRESS;
  const anySuccess = eachPatientProgressInfoList.some((info) => isSuccess(info.status));
  if (anySuccess) return JobProgressBarCase.SOME_SUCCESS;
  return JobProgressBarCase.ALL_FAILED;
};

export const getProgressTextFromProgressBarCase = (progressBarCase: JobProgressBarCase): string => {
  return PROGRESS_CASE_TO_TEXT[progressBarCase] ?? '';
};

export const getProgressBarColorFromProgressBarCase = (
  progressBarCase: JobProgressBarCase,
): LinearProgressProps['color'] => {
  return PROGRESS_CASE_TO_COLOR[progressBarCase];
};

export const getTooltipDetailFromStatus = (status: JobProgressStatus): string | null => {
  return DONE_STATUS_TO_TOOLTIP_TEXT[status] ?? null;
};

export const getIconCaseFromEachPatientProgressInfo = (
  eachPatientProgressInfoList: EachPatientProgressInfoList,
): JobProgressIconCase => {
  const anyInProgress = eachPatientProgressInfoList.some((info) => !isStatusDone(info.status));
  if (anyInProgress) return JobProgressIconCase.IN_PROGRESS;
  const allSuccess = eachPatientProgressInfoList.every((info) => isSuccess(info.status));
  if (allSuccess) return JobProgressIconCase.ALL_SUCCESS;
  return JobProgressIconCase.SOME_FAILED;
};

export const getTooltipDoneDetailFromEachPatientProgressInfo = (
  eachPatientProgressInfoList: EachPatientProgressInfoList,
  mdpIdToNameMap: { [mdp9_id: string]: string },
): { mdp9_id: string; mdp_name: string; detail: string | null }[] => {
  return eachPatientProgressInfoList.map((info) => ({
    mdp9_id: info.mdp9_id,
    mdp_name: mdpIdToNameMap[info.mdp9_id] ?? info.mdp9_id,
    detail: getTooltipDetailFromStatus(info.status),
  }));
};

// TODO: ask the business team if want the tooltip while in progress
export const getTooltipDetailFromEachPatientProgressInfo = (
  eachPatientProgressInfoList: EachPatientProgressInfoList,
  mdpIdToNameMap: { [mdp9_id: string]: string }, // TODO
): { mdp9_id: string; mdp_name: string; detail: string | null }[] => {
  return eachPatientProgressInfoList.map((info) => ({
    mdp9_id: info.mdp9_id,
    mdp_name: mdpIdToNameMap[info.mdp9_id] ?? `รพ.${info.mdp9_id}`,
    detail: isStatusDone(info.status)
      ? getTooltipDetailFromStatus(info.status)
      : decimalToPercentString(getStatusPercent(info.status)),
  }));
};

export const getStatusPercentForPatientId = (
  patientId: number,
  patientProgressInfo: PatientProgressInfo,
): undefined | number => {
  const progresses = patientProgressInfo[patientId];
  if (!progresses) return undefined;
  return getStatusPercentFromEachPatientProgressInfo(progresses);
};

export const decimalToPercentString = (decimal: number) => {
  return `${Math.round(decimal * 100)}%`;
};

export const formatRawWsProgress = (raw: ProgressInfoResData): ProgressInfo => {
  return {
    status: raw.status as JobProgressStatus,
    patient_id: Number(raw.reqInfo.pid),
    mdp9_id: raw.reqInfo.mdp9id,
    type: raw.type,
    // TODO: check if want the ts or not (timestamp)
  };
};

// TODO: write unit test for this
export const getIsDoneForPendingMdp9Ids = (
  eachPatientProgressInfoList: EachPatientProgressInfoList,
  pendingMdps9Ids: string[],
) => {
  const doneIds = eachPatientProgressInfoList
    .filter((info) => isStatusDone(info.status))
    .map((info) => info.mdp9_id);
  return pendingMdps9Ids.every((id) => doneIds.includes(id));
};
