import { hosAdminConfig } from 'config';
import { enableHAExchangeConfigForAllEnvs, ROUTE_PREFIX, TAB_LIST } from 'config/hospital-admin';

import BaseAdminManagementPage from '../BaseAdmin/BaseAdminManagementPage';

function HospitalAdminManagement(): JSX.Element {
  return (
    <BaseAdminManagementPage
      defaultActiveTab="manage-doctor"
      routePrefix={ROUTE_PREFIX}
      tabList={TAB_LIST}
      filterTabRecord={(tabRecord) => {
        switch (tabRecord.tabKey) {
          case 'dashboard':
            return hosAdminConfig.showDashboard;
          case 'exchange-zone-config':
            return enableHAExchangeConfigForAllEnvs
              ? true
              : process.env.REACT_APP_HOST_ENV === 'staging';
          default:
            return true;
        }
      }}
    />
  );
}

export default HospitalAdminManagement;
