import React from 'react';
import { selectRoles } from 'global_store/features/authen/authenSlice';
import { useAppSelector } from 'global_store/hooks';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

function NotAuthorized(): JSX.Element {
  const userRoles = useAppSelector(selectRoles);
  return (
    <div>
      {userRoles ? (
        <Typography color="error">ไม่มีสิทธิ์เข้าถึงหน้านี้</Typography>
      ) : (
        <Skeleton variant="rectangular" />
      )}
    </div>
  );
}

export default NotAuthorized;
