import React from 'react';
import { format } from 'date-fns';
import { FaUserMd } from 'react-icons/fa';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PREFIX = 'DoctorCard';

const classes = {
  root: `${PREFIX}-root`,
  padding: `${PREFIX}-padding`,
  doctorLogoDiv: `${PREFIX}-doctorLogoDiv`,
  doctorLogo: `${PREFIX}-doctorLogo`,
  leftHeader: `${PREFIX}-leftHeader`,
  rightHeader: `${PREFIX}-rightHeader`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: '10px',
    marginBottom: '10px',
    margin: theme.spacing(2),
    overflowX: 'auto',
  },

  [`& .${classes.padding}`]: {
    padding: '32px 0px 32px 32px',
    alignItems: 'center',
  },

  [`& .${classes.doctorLogoDiv}`]: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },

  [`& .${classes.doctorLogo}`]: {
    fontSize: '2em',
  },

  [`& .${classes.leftHeader}`]: {
    display: 'flex',
    justifyContent: 'left',
    alignContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.rightHeader}`]: {
    display: 'flex',
    justifyContent: 'right',
    alignContent: 'center',
    alignItems: 'center',
  },
}));

interface DoctorCardProps {
  data: any;
}

function DoctorCard({ data }: DoctorCardProps): JSX.Element {
  const latestLoginDate = new Date();

  return (
    <StyledCard elevation={0} className={classes.root}>
      <Grid container className={classes.padding}>
        <Grid item xs={8} className={classes.leftHeader}>
          <div className={classes.doctorLogoDiv}>
            <FaUserMd className={classes.doctorLogo} />
          </div>
          <Typography variant="h2">Doctor Management</Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <Typography variant="body1">Login ล่าสุด:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <Typography variant="body2">{format(latestLoginDate, 'dd/MM/yyyy')}</Typography>
              <Typography variant="body2">{format(latestLoginDate, 'HH:mm น.')}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </StyledCard>
  );
}

export default DoctorCard;
