export enum JobProgressStatus {
  INITIALIZED = 'INITIALIZED',
  CHECKING_CONSENT = 'CHECKING_CONSENT',
  RETRIEVING_RECORD = 'RETRIEVING_RECORD',
  PREPARING_DATA = 'PREPARING_DATA',
  TRANSMIT_DATA = 'TRANSMIT_DATA',
  FINISHED = 'FINISHED',
  NO_CONSENT = 'NO_CONSENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  ERROR = 'ERROR',
  NO_DATA = 'NO_DATA',
}

export const doneProgresses = [
  JobProgressStatus.FINISHED,
  JobProgressStatus.UNKNOWN_ERROR,
  JobProgressStatus.ERROR,
  JobProgressStatus.NO_DATA,
  JobProgressStatus.NO_CONSENT,
];

// TODO: revisit when is finalized
export interface ProgressInfoResData {
  jobId: string;
  reqInfo: {
    cid: string;
    hcid: string;
    mdp9id: string;
    date: string;
    userId: string;
    sender: string;
    pid: number;
    jobId: string;
  };
  status: string;
  ts: string;
  type: string; // progress
}
export interface ProgressInfo {
  status: JobProgressStatus;
  patient_id: number;
  mdp9_id: string;
  type?: string;
  // desc?: string;
}
export type ProgressInfoList = ProgressInfo[];
export type EachPatientProgressInfoList = Omit<ProgressInfo, 'patient_id' | 'type'>[];
export interface PatientProgressInfo {
  [patientId: string]: EachPatientProgressInfoList;
}

export const sortedProgresses = [
  JobProgressStatus.INITIALIZED,
  JobProgressStatus.CHECKING_CONSENT,
  JobProgressStatus.RETRIEVING_RECORD,
  JobProgressStatus.PREPARING_DATA,
  JobProgressStatus.TRANSMIT_DATA,
  JobProgressStatus.FINISHED,
];

export enum JobProgressBarCase {
  IN_PROGRESS = 'IN_PROGRESS',
  SOME_SUCCESS = 'SOME_SUCCESS',
  ALL_FAILED = 'ALL_FAILED',
}
export enum JobProgressIconCase {
  IN_PROGRESS = 'IN_PROGRESS',
  ALL_SUCCESS = 'ALL_SUCCESS',
  SOME_FAILED = 'SOME_FAILED',
}
