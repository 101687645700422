import React from 'react';

import { styled, Typography } from '@mui/material';

const PREFIX = 'TextWithLabel';

const classes = {
  inputStyle: `${PREFIX}-inputStyle`,
  inputLabelStyle: `${PREFIX}-inputLabelStyle`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.inputStyle}`]: {
    width: '90%',
    color: theme.typography.h6.color,
    fontWeight: theme.typography.h6.fontWeight,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    letterSpacing: theme.typography.h6.letterSpacing,
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.inputLabelStyle}`]: {
    color: theme.typography.caption.color,
    fontWeight: theme.typography.caption.fontWeight,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    letterSpacing: theme.typography.caption.letterSpacing,
  },
}));

interface TextWithLabelProps {
  label: string;
  text: string;
}

function TextWithLabel({ label, text }: TextWithLabelProps): JSX.Element {
  return (
    <Root>
      <Typography className={classes.inputLabelStyle}>{label}</Typography>
      <Typography className={classes.inputStyle}>{text}</Typography>
    </Root>
  );
}

export default TextWithLabel;
