import type { RootState } from 'global_store/store';
import { IDemographic } from 'interface';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IPatientState {
  patientCID: string | null;
  patientID: number | null;
  demographic: IDemographic | null;
  chronicDisease: string | null;
}

const initialState: IPatientState = {
  patientCID: null,
  patientID: null,
  demographic: null,
  chronicDisease: null,
};

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    clearPatientSlice: (state) => {
      // Reset state to initial state or any other desired state
      Object.assign(state, initialState);
    },
    setPatientCID: (state, action: PayloadAction<string | null>) => {
      state.patientCID = action.payload;
    },
    setPatientID: (state, action: PayloadAction<number | null>) => {
      state.patientID = action.payload;
    },
    setDemographic: (state, action: PayloadAction<IDemographic | null>) => {
      state.demographic = action.payload;
    },
    setChronicDisease: (state, action: PayloadAction<string | null>) => {
      state.chronicDisease = action.payload;
    },
  },
});

export const { clearPatientSlice, setPatientCID, setDemographic, setPatientID, setChronicDisease } =
  patientSlice.actions;

export const selectPatientCID = (state: RootState) => state.patient.patientCID;
export const selectPatientID = (state: RootState) => state.patient.patientID;
export const selectDemographic = (state: RootState) => state.patient.demographic;
export const selectChronicDisease = (state: RootState) => state.patient.chronicDisease;
export const selectDemographicEmail = createSelector(selectDemographic, (demo) => demo?.email);

export default patientSlice.reducer;
