import React from 'react';
import errorIcon from 'assets/img/error.svg';
import warningIcon from 'assets/img/warning.svg';
import GenericButton from 'components/Button/GenericButton';
import { setOpenModal } from 'global_store/features/global-modal/globalModalSlice';
import { useAppDispatch } from 'global_store/hooks';

import { Box, Button, Dialog, DialogProps, SxProps, Typography } from '@mui/material';

export interface BaseDialogProps {
  open: boolean;
  handleClose?: (...args: any[]) => void;
  handleConfirm?: (...args: any[]) => void;
  children?: React.ReactNode;
  dialogContentSx?: SxProps;
  withOkButton?: boolean;
  withCancelButton?: boolean;
  title?: string;
  titleColor?: string;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: DialogProps['fullWidth'];
  okButtonText?: string;
  cancelButtonText?: string;
  dialogPaperSx?: DialogProps['PaperProps']['sx'];
  disableCloseOnClickBackdrop?: boolean;
  disableOkButton?: boolean;
}

/**
 * NOTE: in the future will refactor other Dialog to use BaseDialog to reduce copy-pasted code
 */
function BaseDialog({
  open,
  handleClose: handleCloseFromProps,
  handleConfirm = undefined,
  children,
  dialogContentSx = {},
  withOkButton = false,
  withCancelButton = false,
  title,
  titleColor = 'success.main',
  maxWidth = undefined,
  fullWidth = false,
  okButtonText = 'ตกลง',
  cancelButtonText = 'ยกเลิก',
  dialogPaperSx = {},
  disableCloseOnClickBackdrop = false,
  disableOkButton = false,
}: BaseDialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const handleCancel = () => {
    dispatch(setOpenModal(null));
    if (handleCloseFromProps) handleCloseFromProps();
  };
  const confirmHandler = () => {
    dispatch(setOpenModal(null));
    if (handleConfirm) handleConfirm();
    else if (handleCloseFromProps) handleCloseFromProps();
  };
  return (
    <Dialog
      open={open}
      onClose={disableCloseOnClickBackdrop ? undefined : handleCancel}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperProps={{ sx: { ...dialogPaperSx, minWidth: 500 } }}
    >
      <Box
        sx={{
          padding: 4,
          ...dialogContentSx,
        }}
      >
        {(() => {
          if (titleColor === 'error') {
            return (
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src={errorIcon} width={80} height={80} alt="error..." />
              </div>
            );
          }
          if (titleColor === 'warning') {
            return (
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src={warningIcon} width={80} height={80} alt="warning..." />
              </div>
            );
          }
          return null;
        })()}

        {title && (
          <Typography
            sx={{
              justifyContent: 'center',
              alignContent: 'center',
              textAlign: 'center',
              color: 'text.primary',
              whiteSpace: 'pre-line',
              fontSize: '1.5rem',
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        )}

        {children}

        {(withOkButton || withCancelButton) && (
          <Box
            sx={{
              display: 'flex',
              marginTop: '16px',
              width: '50%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {withCancelButton && (
              <Button
                onClick={handleCancel}
                fullWidth
                size="small"
                color="secondary"
                sx={{
                  border: '1px solid #47BDEB',
                  borderRadius: '0.5rem',
                  fontSize: '1.125em',
                  height: 'fit-content',
                  marginX: '1em',
                  padding: '0em 0.25em',
                  color: 'primary.main',
                }}
              >
                {cancelButtonText}
              </Button>
            )}

            {withOkButton && (
              <GenericButton
                onClick={confirmHandler}
                fullWidth
                size="small"
                disabled={disableOkButton}
              >
                {okButtonText}
              </GenericButton>
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

export default BaseDialog;
