import React, { createContext, useCallback, useContext, useMemo } from 'react';
import SelectHospitalDialog from 'components/Dialog/SelectHospitalDialog';
import TermsDialog from 'components/Dialog/TermsDialog';
import { selectPatientID } from 'global_store/features/patient/patientSlice';
import { useAppSelector } from 'global_store/hooks';
import useInitModalsDialog from 'global_store/hooks/useInitModalsDialog';
import { useNavigate } from 'react-router-dom';

export interface InitModalsLayoutProps {
  children?: React.ReactNode;
}

const InitModalsLayoutContext = createContext({
  onLoad: async () => {},
});

function InitModalsLayoutContextProvider({ children }: InitModalsLayoutProps) {
  const {
    openTermsDialog,
    user,
    requiredTermVersion,
    isTermValid,
    termElements,
    handleDeclineTerms,
    handleAcceptTerms,
    roles,
    requestTerms,
    openSelectHospitalDialog,
    setOpenTermsDialog,
    setOpenSelectHospitalDialog,
    handleHospitalDialogConfirm,
    handleHospitalDialogCancel,
    // isDoctor,
    handleLogout,
  } = useInitModalsDialog();
  const navigate = useNavigate();
  const patientID = useAppSelector(selectPatientID);

  const onLoad = useCallback(async () => {
    if (user && roles) {
      if (requiredTermVersion === undefined || requiredTermVersion === null) await requestTerms();
      else
        console.debug(`already have the termElements so didn't request terms`, {
          termElements,
          requiredTermVersion,
        });
      const isValid = isTermValid(user);
      if (isValid === null) {
        console.debug(`isValid is null so didn't proceed`, { user });
        return;
      }
      console.debug('🚀 | onLoad | isValid', isValid, 'roles', roles);
      if (roles.includes('MDP_ADMIN') || roles.includes('ADMIN')) {
        // MDP_ADMIN
        if (isValid) {
          navigate('/admin/home');
        } else {
          setOpenTermsDialog(true);
        }
      } else if (roles.includes('DOCTOR')) {
        // DOCTOR
        if (isValid) {
          setOpenSelectHospitalDialog(true);
        } else {
          setOpenTermsDialog(true);
        }
      } else if (roles.includes('DOCTOR_SINGLE_PATIENT')) {
        if (isValid) {
          setOpenSelectHospitalDialog(false);
          console.debug('🚀 | onLoad | patientID:', patientID);
          navigate('/console/patient/overview', { state: { patient_id: patientID } });
        } else {
          setOpenTermsDialog(true);
        }
      }
    }
  }, [user, roles, requiredTermVersion, isTermValid, requestTerms, patientID]);

  const contextValue = useMemo(
    () => ({
      onLoad,
    }),
    [onLoad],
  );

  return (
    <InitModalsLayoutContext.Provider value={contextValue}>
      {children}
      <SelectHospitalDialog
        // open={openSelectHospitalDialog && !!user && isDoctor() && isTermValid(user)}
        open={openSelectHospitalDialog}
        handleCancel={handleHospitalDialogCancel}
        handleConfirm={handleHospitalDialogConfirm}
        handleLogout={handleLogout}
      />
      <TermsDialog
        // open={openTermsDialog && !!user && !isTermValid(user)}
        open={openTermsDialog}
        termElements={termElements || []}
        handleCancel={handleDeclineTerms}
        handleConfirm={handleAcceptTerms}
      />
    </InitModalsLayoutContext.Provider>
  );
}

export const useInitModalsLayoutContext = () => useContext(InitModalsLayoutContext);

export default InitModalsLayoutContextProvider;
