import React from 'react';
import GenericButton from 'components/Button/GenericButton';
import SecondaryButton from 'components/Button/SecondaryButton';
import { selectUser } from 'global_store/features/authen/authenSlice';
import { setSelectedHospital } from 'global_store/features/hospital/hospitalSlice';
import { setErrorMessage, setShowErrorDialog } from 'global_store/features/message/messageSlice';
import { useAppDispatch, useAppSelector } from 'global_store/hooks';
import { IHospital } from 'interface';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { setActiveMdp } from 'services/authen';
import { renewAccessToken } from 'utils/authen';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import BaseDialog from './Base/BaseDialog';

const PREFIX = 'SelectHospitalDialog';

const classes = {
  headerButton: `${PREFIX}-headerButton`,
  buttonArrow: `${PREFIX}-buttonArrow`,
  leftContent: `${PREFIX}-leftContent`,
  rightContent: `${PREFIX}-rightContent`,
  menuItem: `${PREFIX}-menuItem`,
  // dialog: `${PREFIX}-dialog`,
  // dialogContent: `${PREFIX}-dialogContent`,
  dialogButton: `${PREFIX}-dialogButton`,
  centerAllContent: `${PREFIX}-centerAllContent`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.headerButton}`]: {
    border: '2px solid #CBCBCB',
    width: '450px',
    maxWidth: '100%',
    margin: 'auto',
  },

  [`& .${classes.buttonArrow}`]: {
    color: '#59DCAF',
  },

  [`& .${classes.leftContent}`]: {
    justifyContent: 'left',
    alignContent: 'center',
    display: 'flex',
  },

  [`& .${classes.rightContent}`]: {
    justifyContent: 'right',
    alignContent: 'center',
    display: 'flex',
  },

  [`& .${classes.menuItem}`]: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  // [`& .${classes.dialog}`]: {
  //   borderRadius: '16px',
  // },

  // [`& .${classes.dialogContent}`]: {
  //   padding: theme.spacing(4),
  //   display: 'flex',
  //   flexDirection: 'column',
  // },

  [`& .${classes.dialogButton}`]: {
    justifyContent: 'center',
    maxWidth: '50%',
    marginTop: '16px',
  },

  [`& .${classes.centerAllContent}`]: {
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },
}));

interface SelectHospitalProps {
  open: boolean;
  handleLogout: any;
  handleCancel: any;
  handleConfirm: any;
}

function SelectHospitalDialog({
  open,
  handleLogout,
  handleCancel,
  handleConfirm: handleConfirmFromProps,
}: SelectHospitalProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const user = useAppSelector(selectUser);
  const [hospital, setHospital] = React.useState<IHospital | null>(null);
  React.useEffect(() => {
    if (user?.list_mdp.length === 1) {
      setHospital(user.list_mdp[0]);
    }
  }, [user]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    handleCloseMenu();
    dispatch(setSelectedHospital(hospital));
    const { success, data, error } = await setActiveMdp(hospital?.mdp_id || 0);
    if (success) {
      await renewAccessToken();
      handleConfirmFromProps();
    } else if (!data.errorIsHandled) {
      dispatch(setErrorMessage(error));
      dispatch(setShowErrorDialog(true));
    }
  };

  const handleHospitalChange = (hospital: IHospital) => {
    setHospital(hospital);
    setAnchorEl(null);
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleCancel}
      maxWidth="sm"
      title={`สวัสดี ${user?.title} ${user?.firstname}`}
      titleColor="text.primary"
      dialogContentSx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StyledBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {user?.list_mdp.length ? (
          <>
            <Typography variant="overline" className={classes.centerAllContent}>
              โปรดระบุโรงพยาบาลที่ท่านใช้ระบบ Health Link
            </Typography>
            <Button className={`${classes.headerButton}`} onClick={handleClick}>
              <Grid container>
                <Grid item xs={10} className={classes.leftContent}>
                  <Typography variant="body1">{hospital ? hospital.mdp_name : 'เลือก'}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.rightContent}>
                  {anchorEl ? (
                    <AiOutlineArrowUp className={classes.buttonArrow} />
                  ) : (
                    <AiOutlineArrowDown className={classes.buttonArrow} />
                  )}
                </Grid>
              </Grid>
            </Button>
            <Menu
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              {user?.list_mdp.map((hospital, key) => {
                return (
                  <MenuItem
                    className={classes.menuItem}
                    sx={{ minWidth: '446px' }}
                    key={key}
                    onClick={() => {
                      handleHospitalChange(hospital);
                    }}
                  >
                    <Typography variant="body1">{hospital.mdp_name}</Typography>
                  </MenuItem>
                );
              })}
            </Menu>
            <Container
              className={classes.dialogButton}
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <SecondaryButton fullWidth onClick={handleCancel} size="small">
                ยกเลิก
              </SecondaryButton>
              <GenericButton onClick={handleConfirm} fullWidth size="small" disabled={!hospital}>
                ตกลง
              </GenericButton>
            </Container>
          </>
        ) : (
          <>
            <Typography variant="body1" color="error">
              ท่านยังไม่มีโรงพยาบาลที่ท่านสังกัดในระบบ Health Link กรุณาติดต่อ Hospital Admin
              ของโรงพยาบาลของท่าน ให้เพิ่มท่านเข้าระบบก่อนเข้าใช้งาน
            </Typography>
            <Container className={classes.dialogButton}>
              <GenericButton onClick={handleLogout} fullWidth size="small">
                Logout
              </GenericButton>
            </Container>
          </>
        )}
      </StyledBox>
    </BaseDialog>
  );
}

export default SelectHospitalDialog;
