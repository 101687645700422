import { useSaveBrowserFingerprintApi } from 'config';
import { IPasswordUpdateForm } from 'interface/authen';
import { IFormattedError } from 'interface/service';
import { getDefaultRequestHeaders } from 'utils/authen';
import { mockFlag } from 'utils/mock';
import { sampleUser } from 'variables/user.sample.data';

import api from './api';

export const requestLogin = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}): Promise<{ success: boolean; data: any; error: null | IFormattedError['error'] }> => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  try {
    const res = await api.post('/api/auth/login/with-form-data', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return { success: true, data: res.data, error: null };
  } catch (e: any) {
    console.log(e.response);
    const errorData = {
      ...e.response.data,
      loginAttempts: e.response.headers['login-attempt-counter'] || 0,
    };
    return { success: false, data: errorData, error: e.response.status };
  }
};

export const requestLogout = async (): Promise<
  { success: true; data: any; error: null } | IFormattedError
> => {
  try {
    const res = await api.post('/api/auth/logout');
    return { success: true, data: res.data, error: null };
  } catch (e: any) {
    console.log(e);
    return { success: false, data: e.response.data, error: e.response.status };
  }
};

export const getAccessToken = async (
  grantToken?: string,
): Promise<{ success: true; data: any; error: null } | IFormattedError> => {
  try {
    const postArgs = grantToken
      ? [
          {
            grant_token: grantToken,
          },
        ]
      : [];
    let resData;
    if (mockFlag) {
      resData = {
        access_token: 'MOCKED_ACCESS_TOKEN',
      };
    } else {
      const res = await api.post('/api/auth/refresh-token', ...postArgs);
      resData = res.data;
    }
    return { success: true, data: resData, error: null };
  } catch (e: any) {
    console.error('getAccessToken error', e.response);
    return { success: false, data: e.response.data, error: e.response.status };
  }
};

export const getFingerPrint = async (
  fingerPrintToken?: string,
): Promise<{ success: true; data: any; error: null } | IFormattedError> => {
  try {
    const postArgs = fingerPrintToken
      ? [
          {
            fingerPrint: fingerPrintToken,
          },
        ]
      : [];
    let resData;
    if (useSaveBrowserFingerprintApi) {
      const res = await api.post('/api/auth/fingerPrint', ...postArgs);
      resData = res.data;
    } else {
      resData = {
        fingerPrint: 'MOCKED_FINGER_PRINT',
      };
    }
    return { success: true, data: resData, error: null };
  } catch (e: any) {
    console.log(e.response);
    return { success: false, data: null, error: e.response.data };
  }
};

export const getCurrentUser = async () => {
  try {
    let resData;
    if (mockFlag) {
      resData = sampleUser;
    } else {
      const res = await api.get('/api/user/user-info', {
        headers: await getDefaultRequestHeaders(true),
      });
      resData = res.data;
    }
    return { success: true, data: resData, error: null };
  } catch (e: any) {
    console.log(e.response);
    return { success: false, data: e.response.data, error: e.response.status };
  }
};

export const setActiveMdp = async (
  mdpId: number,
): Promise<{ success: true; data: any; error: null } | IFormattedError> => {
  try {
    const res = await api.post(
      '/api/auth/activate/mdp',
      {},
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          mdp_id: mdpId,
        },
      },
    );
    return { success: true, data: res.data, error: null };
  } catch (e: any) {
    console.log(e.response);
    return { success: false, data: e.response.data, error: e.response.status };
  }
};

export const requestPasswordChange = async (
  payload: IPasswordUpdateForm,
): Promise<
  | {
      success: boolean;
      data: any;
      error: null;
    }
  | IFormattedError
> => {
  try {
    const res = await api.put('/api/user/password', payload, {
      headers: await getDefaultRequestHeaders(true),
    });
    return { success: true, data: res.data, error: null };
  } catch (e: any) {
    console.log(e.response);
    return { success: false, data: e.response.data, error: e.response.status };
  }
};
